import { Percent, Token, BaseCurrency, Price, ZERO, InsufficientReservesError, _9975, _10000, CurrencyAmount, InsufficientInputAmountError, ONE, sqrt, MINIMUM_LIQUIDITY, TradeType, computePriceImpact, Fraction, sortedInsert, NativeCurrency, FIVE } from '@donaswap/swap-sdk-core';
export * from '@donaswap/swap-sdk-core';
import invariant5 from 'tiny-invariant';
import { createPublicClient, http, getAddress, keccak256, encodePacked, getContract, toBytes, pad, isBytes, slice, concat } from 'viem';
import warning from 'tiny-warning';
import { mainnet, bsc, bscTestnet, goerli } from 'viem/chains';

// src/constants.ts
function validateAndParseAddress(address) {
  try {
    const checksummedAddress = getAddress(address);
    warning(address === checksummedAddress, `${address} is not checksummed.`);
    return checksummedAddress;
  } catch (error) {
    invariant5(false, `${address} is not a valid address.`);
  }
}

// src/entities/token.ts
var ERC20Token = class extends Token {
  constructor(chainId, address, decimals, symbol, name, projectLink) {
    super(chainId, validateAndParseAddress(address), decimals, symbol, name, projectLink);
  }
};
var OnRampCurrency = class extends BaseCurrency {
  constructor(chainId, address, decimals, symbol, name, projectLink) {
    super(chainId, decimals, symbol, name);
    this.address = address;
    this.projectLink = projectLink;
    this.isNative = address === "0x" && true;
    this.isToken = address !== "0x" && true;
  }
  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  equals(other) {
    return other.isToken && this.chainId === other.chainId && this.address === other.address;
  }
  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  sortsBefore(other) {
    if (!other.isToken)
      return false;
    invariant5(this.chainId === other.chainId, "CHAIN_IDS");
    invariant5(this.address !== other.address, "ADDRESSES");
    return this.address.toLowerCase() < other.address.toLowerCase();
  }
  /**
   * Return this token, which does not need to be wrapped
   */
  get wrapped() {
    return this;
  }
  get serialize() {
    return {
      address: this.address,
      chainId: this.chainId,
      decimals: this.decimals,
      symbol: this.symbol,
      name: this.name,
      projectLink: this.projectLink
    };
  }
};

// src/chainIds.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["FIRECHAIN"] = 529] = "FIRECHAIN";
  ChainId2[ChainId2["RINIA"] = 917] = "RINIA";
  ChainId2[ChainId2["FIRECHAIN_ZKEVM"] = 814] = "FIRECHAIN_ZKEVM";
  ChainId2[ChainId2["FIRECHAIN_ZKEVM_GHOSTRIDER"] = 3885] = "FIRECHAIN_ZKEVM_GHOSTRIDER";
  ChainId2[ChainId2["ARBITRUM_ONE"] = 42161] = "ARBITRUM_ONE";
  ChainId2[ChainId2["ARBITRUM_GOERLI"] = 421613] = "ARBITRUM_GOERLI";
  ChainId2[ChainId2["ASTAR"] = 592] = "ASTAR";
  ChainId2[ChainId2["AURORA"] = 1313161554] = "AURORA";
  ChainId2[ChainId2["AURORA_TESTNET"] = 1313161555] = "AURORA_TESTNET";
  ChainId2[ChainId2["AVALANCHE"] = 43114] = "AVALANCHE";
  ChainId2[ChainId2["AVALANCHE_FUJI"] = 43113] = "AVALANCHE_FUJI";
  ChainId2[ChainId2["BASE"] = 8453] = "BASE";
  ChainId2[ChainId2["BASE_GOERLI"] = 84531] = "BASE_GOERLI";
  ChainId2[ChainId2["BTTCHAIN"] = 199] = "BTTCHAIN";
  ChainId2[ChainId2["BTTCHAIN_TESTNET"] = 1029] = "BTTCHAIN_TESTNET";
  ChainId2[ChainId2["BOBA"] = 288] = "BOBA";
  ChainId2[ChainId2["BOBA_AVAX"] = 43288] = "BOBA_AVAX";
  ChainId2[ChainId2["BOBA_BNB"] = 56288] = "BOBA_BNB";
  ChainId2[ChainId2["BOBA_GOERLI"] = 2888] = "BOBA_GOERLI";
  ChainId2[ChainId2["BRISECHAIN"] = 32520] = "BRISECHAIN";
  ChainId2[ChainId2["BRISECHAIN_TESTNET"] = 64668] = "BRISECHAIN_TESTNET";
  ChainId2[ChainId2["BRONOS"] = 1039] = "BRONOS";
  ChainId2[ChainId2["BRONOS_TESTNET"] = 1038] = "BRONOS_TESTNET";
  ChainId2[ChainId2["BSC"] = 56] = "BSC";
  ChainId2[ChainId2["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId2[ChainId2["CALLISTO"] = 820] = "CALLISTO";
  ChainId2[ChainId2["CALLISTO_TESTNET"] = 20729] = "CALLISTO_TESTNET";
  ChainId2[ChainId2["CANTO"] = 7700] = "CANTO";
  ChainId2[ChainId2["CANTO_TESTNET"] = 7701] = "CANTO_TESTNET";
  ChainId2[ChainId2["CELO"] = 42220] = "CELO";
  ChainId2[ChainId2["CELO_ALFAJORES"] = 44787] = "CELO_ALFAJORES";
  ChainId2[ChainId2["CLOVER"] = 1024] = "CLOVER";
  ChainId2[ChainId2["CLOVER_TESTNET"] = 1023] = "CLOVER_TESTNET";
  ChainId2[ChainId2["CMP"] = 256256] = "CMP";
  ChainId2[ChainId2["CMP_TESTNET"] = 512512] = "CMP_TESTNET";
  ChainId2[ChainId2["COINEX"] = 52] = "COINEX";
  ChainId2[ChainId2["COINEX_TESTNET"] = 53] = "COINEX_TESTNET";
  ChainId2[ChainId2["CONFLUX"] = 1030] = "CONFLUX";
  ChainId2[ChainId2["CONFLUX_TESTNET"] = 71] = "CONFLUX_TESTNET";
  ChainId2[ChainId2["CORE"] = 1116] = "CORE";
  ChainId2[ChainId2["CORE_TESTNET"] = 1115] = "CORE_TESTNET";
  ChainId2[ChainId2["CRO"] = 25] = "CRO";
  ChainId2[ChainId2["CRO_TESTNET"] = 338] = "CRO_TESTNET";
  ChainId2[ChainId2["CUBECHAIN"] = 1818] = "CUBECHAIN";
  ChainId2[ChainId2["CUBECHAIN_TESTNET"] = 1819] = "CUBECHAIN_TESTNET";
  ChainId2[ChainId2["DARWINIA_CRAB"] = 44] = "DARWINIA_CRAB";
  ChainId2[ChainId2["DARWINIA_PANGOLIN"] = 43] = "DARWINIA_PANGOLIN";
  ChainId2[ChainId2["DEFICHAIN"] = 1131] = "DEFICHAIN";
  ChainId2[ChainId2["DEFICHAIN_TESTNET"] = 1132] = "DEFICHAIN_TESTNET";
  ChainId2[ChainId2["DIODE_PRETNET"] = 15] = "DIODE_PRETNET";
  ChainId2[ChainId2["DIODE_TESTNET"] = 13] = "DIODE_TESTNET";
  ChainId2[ChainId2["DOGECHAIN"] = 2e3] = "DOGECHAIN";
  ChainId2[ChainId2["DOGECHAIN_TESTNET"] = 568] = "DOGECHAIN_TESTNET";
  ChainId2[ChainId2["ENGRAM_TESTNET"] = 131] = "ENGRAM_TESTNET";
  ChainId2[ChainId2["ELASTOS"] = 20] = "ELASTOS";
  ChainId2[ChainId2["ELASTOS_TESTNET"] = 21] = "ELASTOS_TESTNET";
  ChainId2[ChainId2["ETHEREUM"] = 1] = "ETHEREUM";
  ChainId2[ChainId2["ETHEREUM_CLASSIC"] = 61] = "ETHEREUM_CLASSIC";
  ChainId2[ChainId2["ETHEREUM_CLASSIC_TESTNET"] = 63] = "ETHEREUM_CLASSIC_TESTNET";
  ChainId2[ChainId2["EVMOS"] = 9001] = "EVMOS";
  ChainId2[ChainId2["EVMOS_TESTNET"] = 9e3] = "EVMOS_TESTNET";
  ChainId2[ChainId2["ENERGY_WEB_CHAIN"] = 246] = "ENERGY_WEB_CHAIN";
  ChainId2[ChainId2["ENERGY_VOLTA"] = 73799] = "ENERGY_VOLTA";
  ChainId2[ChainId2["FANTOM_OPERA"] = 250] = "FANTOM_OPERA";
  ChainId2[ChainId2["FANTOM_TESTNET"] = 4002] = "FANTOM_TESTNET";
  ChainId2[ChainId2["FILECOIN"] = 314] = "FILECOIN";
  ChainId2[ChainId2["FILECOIN_CALIBRATION"] = 314159] = "FILECOIN_CALIBRATION";
  ChainId2[ChainId2["FLARE"] = 14] = "FLARE";
  ChainId2[ChainId2["FLARE_TESTNET"] = 114] = "FLARE_TESTNET";
  ChainId2[ChainId2["FUSE"] = 122] = "FUSE";
  ChainId2[ChainId2["FUSE_SPARKNET"] = 123] = "FUSE_SPARKNET";
  ChainId2[ChainId2["FUSION"] = 32659] = "FUSION";
  ChainId2[ChainId2["FUSION_TESTNET"] = 46688] = "FUSION_TESTNET";
  ChainId2[ChainId2["GNOSIS"] = 100] = "GNOSIS";
  ChainId2[ChainId2["GNOSIS_CHIADO"] = 10200] = "GNOSIS_CHIADO";
  ChainId2[ChainId2["GOCHAIN"] = 60] = "GOCHAIN";
  ChainId2[ChainId2["GOCHAIN_TESTNET"] = 31337] = "GOCHAIN_TESTNET";
  ChainId2[ChainId2["GODWOKEN"] = 71402] = "GODWOKEN";
  ChainId2[ChainId2["GODWOKEN_TESTNET"] = 71401] = "GODWOKEN_TESTNET";
  ChainId2[ChainId2["GOERLI"] = 5] = "GOERLI";
  ChainId2[ChainId2["HAQQ"] = 11235] = "HAQQ";
  ChainId2[ChainId2["HAQQ_TESTEDGE2"] = 54211] = "HAQQ_TESTEDGE2";
  ChainId2[ChainId2["HARMONY"] = 16666e5] = "HARMONY";
  ChainId2[ChainId2["HARMONY_TESTNET"] = 16667e5] = "HARMONY_TESTNET";
  ChainId2[ChainId2["HECO"] = 128] = "HECO";
  ChainId2[ChainId2["HECO_TESTNET"] = 256] = "HECO_TESTNET";
  ChainId2[ChainId2["HOLESKY"] = 17e3] = "HOLESKY";
  ChainId2[ChainId2["IOTEX"] = 4689] = "IOTEX";
  ChainId2[ChainId2["IOTEX_TESTNET"] = 4690] = "IOTEX_TESTNET";
  ChainId2[ChainId2["KARDIACHAIN"] = 24] = "KARDIACHAIN";
  ChainId2[ChainId2["KARDIACHAIN_TESTNET"] = 242] = "KARDIACHAIN_TESTNET";
  ChainId2[ChainId2["KAVA"] = 2222] = "KAVA";
  ChainId2[ChainId2["KAVA_TESTNET"] = 2221] = "KAVA_TESTNET";
  ChainId2[ChainId2["KCC"] = 321] = "KCC";
  ChainId2[ChainId2["KCC_TESTNET"] = 322] = "KCC_TESTNET";
  ChainId2[ChainId2["KEKCHAIN"] = 420420] = "KEKCHAIN";
  ChainId2[ChainId2["KEKCHAIN_TESTNET"] = 420666] = "KEKCHAIN_TESTNET";
  ChainId2[ChainId2["KLAYTN"] = 8217] = "KLAYTN";
  ChainId2[ChainId2["KLAYTN_BAOBAB"] = 1001] = "KLAYTN_BAOBAB";
  ChainId2[ChainId2["LINEA"] = 59144] = "LINEA";
  ChainId2[ChainId2["LINEA_TESTNET"] = 59140] = "LINEA_TESTNET";
  ChainId2[ChainId2["METADIUM"] = 11] = "METADIUM";
  ChainId2[ChainId2["METADIUM_TESTNET"] = 12] = "METADIUM_TESTNET";
  ChainId2[ChainId2["METER"] = 82] = "METER";
  ChainId2[ChainId2["METER_TESTNET"] = 83] = "METER_TESTNET";
  ChainId2[ChainId2["METIS"] = 1088] = "METIS";
  ChainId2[ChainId2["METIS_GOERLI"] = 599] = "METIS_GOERLI";
  ChainId2[ChainId2["MOONBEAM"] = 1284] = "MOONBEAM";
  ChainId2[ChainId2["MOONRIVER"] = 1285] = "MOONRIVER";
  ChainId2[ChainId2["MOONBASE_ALPHA"] = 1287] = "MOONBASE_ALPHA";
  ChainId2[ChainId2["MUUCHAIN"] = 20402] = "MUUCHAIN";
  ChainId2[ChainId2["MUUCHAIN_TESTNET"] = 42110] = "MUUCHAIN_TESTNET";
  ChainId2[ChainId2["OASIS_EMERALD"] = 42262] = "OASIS_EMERALD";
  ChainId2[ChainId2["OASIS_TESTNET"] = 42261] = "OASIS_TESTNET";
  ChainId2[ChainId2["OKXCHAIN"] = 66] = "OKXCHAIN";
  ChainId2[ChainId2["OKXCHAIN_TESTNET"] = 65] = "OKXCHAIN_TESTNET";
  ChainId2[ChainId2["OP_BNB"] = 204] = "OP_BNB";
  ChainId2[ChainId2["OP_BNB_TESTNET"] = 5611] = "OP_BNB_TESTNET";
  ChainId2[ChainId2["OPTIMISM"] = 10] = "OPTIMISM";
  ChainId2[ChainId2["OPTIMISM_GOERLI"] = 420] = "OPTIMISM_GOERLI";
  ChainId2[ChainId2["PALM"] = 11297108109] = "PALM";
  ChainId2[ChainId2["PALM_TESTNET"] = 11297108099] = "PALM_TESTNET";
  ChainId2[ChainId2["POLIS"] = 333999] = "POLIS";
  ChainId2[ChainId2["POLIS_TESTNET"] = 333888] = "POLIS_TESTNET";
  ChainId2[ChainId2["POLYGON"] = 137] = "POLYGON";
  ChainId2[ChainId2["POLYGON_AMOY"] = 80002] = "POLYGON_AMOY";
  ChainId2[ChainId2["POLYGON_ZKEVM"] = 1101] = "POLYGON_ZKEVM";
  ChainId2[ChainId2["POLYGON_ZKEVM_TESTNET"] = 1442] = "POLYGON_ZKEVM_TESTNET";
  ChainId2[ChainId2["PULSECHAIN"] = 369] = "PULSECHAIN";
  ChainId2[ChainId2["PULSECHAIN_TESTNET"] = 943] = "PULSECHAIN_TESTNET";
  ChainId2[ChainId2["REI"] = 55555] = "REI";
  ChainId2[ChainId2["REI_TESTNET"] = 55556] = "REI_TESTNET";
  ChainId2[ChainId2["RSK"] = 30] = "RSK";
  ChainId2[ChainId2["RSK_TESTNET"] = 31] = "RSK_TESTNET";
  ChainId2[ChainId2["SCROLL"] = 534352] = "SCROLL";
  ChainId2[ChainId2["SCROLL_SEPOLIA"] = 534351] = "SCROLL_SEPOLIA";
  ChainId2[ChainId2["SEPOLIA"] = 11155111] = "SEPOLIA";
  ChainId2[ChainId2["SHIBACHAIN"] = 27] = "SHIBACHAIN";
  ChainId2[ChainId2["SHIBARIUM"] = 109] = "SHIBARIUM";
  ChainId2[ChainId2["SHIBARIUM_TESTNET"] = 719] = "SHIBARIUM_TESTNET";
  ChainId2[ChainId2["SHIBUNYA"] = 81] = "SHIBUNYA";
  ChainId2[ChainId2["SHIDEN"] = 336] = "SHIDEN";
  ChainId2[ChainId2["SMART_BCH"] = 1e4] = "SMART_BCH";
  ChainId2[ChainId2["SMART_BCH_TESTNET"] = 10001] = "SMART_BCH_TESTNET";
  ChainId2[ChainId2["SONGBIRD_CANARY"] = 19] = "SONGBIRD_CANARY";
  ChainId2[ChainId2["STEP"] = 1234] = "STEP";
  ChainId2[ChainId2["STEP_TESTNET"] = 12345] = "STEP_TESTNET";
  ChainId2[ChainId2["SYSCOIN"] = 57] = "SYSCOIN";
  ChainId2[ChainId2["SYSCOIN_TANENBAUM"] = 5700] = "SYSCOIN_TANENBAUM";
  ChainId2[ChainId2["TARAXA"] = 841] = "TARAXA";
  ChainId2[ChainId2["TARAXA_TESTNET"] = 842] = "TARAXA_TESTNET";
  ChainId2[ChainId2["TELOS"] = 40] = "TELOS";
  ChainId2[ChainId2["TELOS_TESTNET"] = 41] = "TELOS_TESTNET";
  ChainId2[ChainId2["THAICHAIN"] = 7] = "THAICHAIN";
  ChainId2[ChainId2["THETHA"] = 361] = "THETHA";
  ChainId2[ChainId2["THETHA_TESTNET"] = 365] = "THETHA_TESTNET";
  ChainId2[ChainId2["THUNDERCORE"] = 108] = "THUNDERCORE";
  ChainId2[ChainId2["THUNDERCORE_TESTNET"] = 18] = "THUNDERCORE_TESTNET";
  ChainId2[ChainId2["TOMOCHAIN"] = 88] = "TOMOCHAIN";
  ChainId2[ChainId2["TOMOCHAIN_TESTNET"] = 89] = "TOMOCHAIN_TESTNET";
  ChainId2[ChainId2["UBIQ"] = 8] = "UBIQ";
  ChainId2[ChainId2["UBIQ_TESTNET"] = 9] = "UBIQ_TESTNET";
  ChainId2[ChainId2["ULTRON"] = 1231] = "ULTRON";
  ChainId2[ChainId2["ULTRON_TESTNET"] = 1230] = "ULTRON_TESTNET";
  ChainId2[ChainId2["VELAS"] = 106] = "VELAS";
  ChainId2[ChainId2["VELAS_TESTNET"] = 111] = "VELAS_TESTNET";
  ChainId2[ChainId2["VISION"] = 888888] = "VISION";
  ChainId2[ChainId2["VISION_TESTNET"] = 666666] = "VISION_TESTNET";
  ChainId2[ChainId2["VECHAIN"] = 100009] = "VECHAIN";
  ChainId2[ChainId2["VECHAIN_TESTNET"] = 100010] = "VECHAIN_TESTNET";
  ChainId2[ChainId2["WANCHAIN"] = 888] = "WANCHAIN";
  ChainId2[ChainId2["WANCHAIN_TESTNET"] = 999] = "WANCHAIN_TESTNET";
  ChainId2[ChainId2["XINFIN"] = 50] = "XINFIN";
  ChainId2[ChainId2["XINFIN_APOTHEM"] = 51] = "XINFIN_APOTHEM";
  ChainId2[ChainId2["XEN"] = 202212e5] = "XEN";
  ChainId2[ChainId2["XEN_DEVNET"] = 202212] = "XEN_DEVNET";
  ChainId2[ChainId2["XRPL_DEVNET"] = 1440002] = "XRPL_DEVNET";
  ChainId2[ChainId2["ZETACHAIN"] = 7e3] = "ZETACHAIN";
  ChainId2[ChainId2["ZETACHAIN_ATHENS"] = 7001] = "ZETACHAIN_ATHENS";
  ChainId2[ChainId2["ZKSYNC"] = 324] = "ZKSYNC";
  ChainId2[ChainId2["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
  ChainId2[ChainId2["ZYX"] = 55] = "ZYX";
  return ChainId2;
})(ChainId || {});

// src/constants.ts
var ZERO_PERCENT = new Percent("0");
var ONE_HUNDRED_PERCENT = new Percent("1");
var WFIRE = {
  [529 /* FIRECHAIN */]: new ERC20Token(
    529 /* FIRECHAIN */,
    "0x0A094361031Ff2515bF98767Ce228e28bDd2A112",
    18,
    "WFIRE",
    "Wrapped Fire",
    "https://wfire.thefirechain.com"
  ),
  [917 /* RINIA */]: new ERC20Token(
    917 /* RINIA */,
    "0x73D5d8E6dbC94a28200A89a77C4D27090BF4E237",
    18,
    "WFIRE",
    "Wrapped Fire",
    "https://docs.thefirechain.com"
  )
};
var WETH9 = {
  [1 /* ETHEREUM */]: new ERC20Token(
    1 /* ETHEREUM */,
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [5 /* GOERLI */]: new ERC20Token(
    5 /* GOERLI */,
    "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [11155111 /* SEPOLIA */]: new ERC20Token(
    11155111 /* SEPOLIA */,
    "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [17e3 /* HOLESKY */]: new ERC20Token(
    17e3 /* HOLESKY */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [56 /* BSC */]: new ERC20Token(
    56 /* BSC */,
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    18,
    "ETH",
    "Binance-Peg Ethereum Token",
    "https://ethereum.org"
  ),
  [97 /* BSC_TESTNET */]: new ERC20Token(
    56 /* BSC */,
    "0xE7bCB9e341D546b66a46298f4893f5650a56e99E",
    18,
    "ETH",
    "ETH",
    "https://ethereum.org"
  ),
  [204 /* OP_BNB */]: new ERC20Token(
    204 /* OP_BNB */,
    "0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea",
    18,
    "ETH",
    "Binance-Peg Ethereum Token",
    "https://ethereum.org"
  ),
  [5611 /* OP_BNB_TESTNET */]: new ERC20Token(
    5611 /* OP_BNB_TESTNET */,
    "0x584f7b986d9942B0859a1E6921efA5342A673d04",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [10 /* OPTIMISM */]: new ERC20Token(
    10 /* OPTIMISM */,
    "0x4200000000000000000000000000000000000006",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [420 /* OPTIMISM_GOERLI */]: new ERC20Token(
    420 /* OPTIMISM_GOERLI */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [42161 /* ARBITRUM_ONE */]: new ERC20Token(
    42161 /* ARBITRUM_ONE */,
    "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [421613 /* ARBITRUM_GOERLI */]: new ERC20Token(
    421613 /* ARBITRUM_GOERLI */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [1313161554 /* AURORA */]: new ERC20Token(
    1313161554 /* AURORA */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [1313161555 /* AURORA_TESTNET */]: new ERC20Token(
    1313161555 /* AURORA_TESTNET */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [288 /* BOBA */]: new ERC20Token(
    288 /* BOBA */,
    "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [2888 /* BOBA_GOERLI */]: new ERC20Token(
    2888 /* BOBA_GOERLI */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [8453 /* BASE */]: new ERC20Token(
    8453 /* BASE */,
    "0x4200000000000000000000000000000000000006",
    18,
    "WETH",
    "Wrapped Eth",
    "https://weth.io"
  ),
  [84531 /* BASE_GOERLI */]: new ERC20Token(
    84531 /* BASE_GOERLI */,
    "0x4200000000000000000000000000000000000006",
    18,
    "WETH",
    "Wrapped Eth",
    "https://weth.io"
  ),
  [324 /* ZKSYNC */]: new ERC20Token(
    324 /* ZKSYNC */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [280 /* ZKSYNC_TESTNET */]: new ERC20Token(
    280 /* ZKSYNC_TESTNET */,
    "0xf06B9De44a7C399fbDA8e8F6491191079402a39C",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [1101 /* POLYGON_ZKEVM */]: new ERC20Token(
    1101 /* POLYGON_ZKEVM */,
    "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [1442 /* POLYGON_ZKEVM_TESTNET */]: new ERC20Token(
    1442 /* POLYGON_ZKEVM_TESTNET */,
    "0x30ec47F7DFae72eA79646e6cf64a8A7db538915b",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [59144 /* LINEA */]: new ERC20Token(
    59144 /* LINEA */,
    "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [59140 /* LINEA_TESTNET */]: new ERC20Token(
    59140 /* LINEA_TESTNET */,
    "0x2C1b868d6596a18e32E61B901E4060C872647b6C",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [534352 /* SCROLL */]: new ERC20Token(
    534352 /* SCROLL */,
    "0x2C1b868d6596a18e32E61B901E4060C872647b6C",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [534351 /* SCROLL_SEPOLIA */]: new ERC20Token(
    534351 /* SCROLL_SEPOLIA */,
    "0x2C1b868d6596a18e32E61B901E4060C872647b6C",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [814 /* FIRECHAIN_ZKEVM */]: new ERC20Token(
    814 /* FIRECHAIN_ZKEVM */,
    "0x0A094361031Ff2515bF98767Ce228e28bDd2A112",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */]: new ERC20Token(
    3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */,
    "0x0A094361031Ff2515bF98767Ce228e28bDd2A112",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  )
};
var WBNB = {
  [1 /* ETHEREUM */]: new ERC20Token(
    1 /* ETHEREUM */,
    "0x418D75f65a02b3D53B2418FB8E1fe493759c7605",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [56 /* BSC */]: new ERC20Token(
    56 /* BSC */,
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [97 /* BSC_TESTNET */]: new ERC20Token(
    97 /* BSC_TESTNET */,
    "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [204 /* OP_BNB */]: new ERC20Token(
    204 /* OP_BNB */,
    "0x4200000000000000000000000000000000000006",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [5611 /* OP_BNB_TESTNET */]: new ERC20Token(
    5611 /* OP_BNB_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  )
};
var WMATIC = {
  [137 /* POLYGON */]: new ERC20Token(
    137 /* POLYGON */,
    "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://matic.network/"
  ),
  [80002 /* POLYGON_AMOY */]: new ERC20Token(
    80002 /* POLYGON_AMOY */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://matic.network/"
  )
};
var WCRO = {
  [25 /* CRO */]: new ERC20Token(
    25 /* CRO */,
    "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23",
    18,
    "WCRO",
    "Wrapped CRO",
    "https://chain.crypto.com/"
  ),
  [338 /* CRO_TESTNET */]: new ERC20Token(
    338 /* CRO_TESTNET */,
    "0x730253eee681A77435a68C7f0C3f23AbEd458858",
    18,
    "WCRO",
    "Wrapped CRO",
    "https://chain.crypto.com/"
  )
};
var WFTM = {
  [250 /* FANTOM_OPERA */]: new ERC20Token(
    250 /* FANTOM_OPERA */,
    "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    18,
    "WFTM",
    "Wrapped FTM",
    "https://fantom.foundation/defi/"
  ),
  [4002 /* FANTOM_TESTNET */]: new ERC20Token(
    4002 /* FANTOM_TESTNET */,
    "0x6162e4bD45239416d2Ef198F5D03A968182A30E4",
    18,
    "WFTM",
    "Wrapped FTM",
    "https://fantom.foundation/defi/"
  )
};
var WAVAX = {
  [43114 /* AVALANCHE */]: new ERC20Token(
    43114 /* AVALANCHE */,
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    18,
    "WAVAX",
    "Wrapped AVAX",
    "https://www.binance.org"
  ),
  [43113 /* AVALANCHE_FUJI */]: new ERC20Token(
    43113 /* AVALANCHE_FUJI */,
    "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
    18,
    "WAVAX",
    "Wrapped AVAX",
    "https://www.binance.org"
  )
};
var WCELO = {
  [42220 /* CELO */]: new ERC20Token(
    42220 /* CELO */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCELO",
    "Wrapped CELO",
    "https://www.binance.org"
  ),
  [44787 /* CELO_ALFAJORES */]: new ERC20Token(
    44787 /* CELO_ALFAJORES */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCELO",
    "Wrapped CELO",
    "https://www.binance.org"
  )
};
var WETH = {
  [10 /* OPTIMISM */]: new ERC20Token(
    10 /* OPTIMISM */,
    "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [420 /* OPTIMISM_GOERLI */]: new ERC20Token(
    420 /* OPTIMISM_GOERLI */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  )
};
var WBONE = {
  [109 /* SHIBARIUM */]: new ERC20Token(
    109 /* SHIBARIUM */,
    "0xC76F4c819D820369Fb2d7C1531aB3Bb18e6fE8d8",
    18,
    "WBONE",
    "Wrapped Bone",
    "https://shibaswap.com"
  ),
  [719 /* SHIBARIUM_TESTNET */]: new ERC20Token(
    719 /* SHIBARIUM_TESTNET */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WBONE",
    "Wrapped Bone",
    "https://shibaswap.com"
  )
};
var WPLS = {
  [369 /* PULSECHAIN */]: new ERC20Token(
    369 /* PULSECHAIN */,
    "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    18,
    "WPLS",
    "Wrapped Pulse",
    "https://pulsechain.com"
  ),
  [943 /* PULSECHAIN_TESTNET */]: new ERC20Token(
    943 /* PULSECHAIN_TESTNET */,
    "0x70499adEBB11Efd915E3b69E700c331778628707",
    18,
    "WPLS",
    "Wrapped Pulse",
    "https://pulsechain.com"
  )
};
var WXN = {
  [202212e5 /* XEN */]: new ERC20Token(
    202212e5 /* XEN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WXN",
    "Wrapped Xen",
    "https://xen.network/.com"
  ),
  [202212 /* XEN_DEVNET */]: new ERC20Token(
    202212 /* XEN_DEVNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WXN",
    "Wrapped Xen",
    "https://xen.network/.com"
  )
};
var WONE = {
  [16666e5 /* HARMONY */]: new ERC20Token(
    16666e5 /* HARMONY */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WONE",
    "Wrapped One",
    "https://xen.network/.com"
  ),
  [16667e5 /* HARMONY_TESTNET */]: new ERC20Token(
    16667e5 /* HARMONY_TESTNET */,
    "0xC751d479E574647f0F20B1A3A6f5D220b6Fd7De3",
    18,
    "WONE",
    "Wrapped One",
    "https://xen.network/.com"
  )
};
var WCET = {
  [52 /* COINEX */]: new ERC20Token(
    52 /* COINEX */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCET",
    "Wrapped Cet",
    "https://xen.network/.com"
  ),
  [53 /* COINEX_TESTNET */]: new ERC20Token(
    53 /* COINEX_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCET",
    "Wrapped Cet",
    "https://xen.network/.com"
  )
};
var WDOGE = {
  [2e3 /* DOGECHAIN */]: new ERC20Token(
    2e3 /* DOGECHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WDOGE",
    "Wrapped Doge",
    "https://xen.network/.com"
  ),
  [568 /* DOGECHAIN_TESTNET */]: new ERC20Token(
    568 /* DOGECHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WDOGE",
    "Wrapped Doge",
    "https://xen.network/.com"
  )
};
var WGRAM = {
  // [ChainId.ENGRAM]: new ERC20Token(
  //   ChainId.ENGRAM,
  //   '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
  //   18,
  //   'WGRAM',
  //   'Wrapped Gram',
  //   'https://engramnet.io/'
  // ),
  [131 /* ENGRAM_TESTNET */]: new ERC20Token(
    131 /* ENGRAM_TESTNET */,
    "0x8B8208f94Ef1AA6891463b43D30C351c9466b0f2",
    18,
    "WGRAM",
    "Wrapped Gram",
    "https://engramnet.io/"
  )
};
var WETC = {
  [61 /* ETHEREUM_CLASSIC */]: new ERC20Token(
    61 /* ETHEREUM_CLASSIC */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WETC",
    "Wrapped Etc",
    "https://xen.network/.com"
  ),
  [63 /* ETHEREUM_CLASSIC_TESTNET */]: new ERC20Token(
    63 /* ETHEREUM_CLASSIC_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WETC",
    "Wrapped Etc",
    "https://xen.network/.com"
  )
};
var WFSN = {
  [32659 /* FUSION */]: new ERC20Token(
    32659 /* FUSION */,
    "0x8FDC02dc969a22C02DDFFd3d8b547FAB3D7702FE",
    18,
    "WFSN",
    "Wrapped Fusion",
    "https://xen.network/.com"
  ),
  [46688 /* FUSION_TESTNET */]: new ERC20Token(
    46688 /* FUSION_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WFSN",
    "Wrapped Fusion",
    "https://xen.network/.com"
  )
};
var WHT = {
  [128 /* HECO */]: new ERC20Token(
    128 /* HECO */,
    "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
    18,
    "WHT",
    "Wrapped HT",
    "https://xen.network/.com"
  ),
  [256 /* HECO_TESTNET */]: new ERC20Token(
    256 /* HECO_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WHT",
    "Wrapped HT",
    "https://xen.network/.com"
  )
};
var WKCS = {
  [321 /* KCC */]: new ERC20Token(
    321 /* KCC */,
    "0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521",
    18,
    "KCC",
    "Wrapped KCS",
    "https://xen.network/.com"
  ),
  [322 /* KCC_TESTNET */]: new ERC20Token(
    322 /* KCC_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "KCC",
    "Wrapped KCS",
    "https://xen.network/.com"
  )
};
var WKLAY = {
  [8217 /* KLAYTN */]: new ERC20Token(
    8217 /* KLAYTN */,
    "0xe4f05A66Ec68B54A58B17c22107b02e0232cC817",
    18,
    "WKLAY",
    "Wrapped Klay",
    "https://xen.network/.com"
  ),
  [1001 /* KLAYTN_BAOBAB */]: new ERC20Token(
    1001 /* KLAYTN_BAOBAB */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WKLAY",
    "Wrapped Klay",
    "https://xen.network/.com"
  )
};
var WOKT = {
  [66 /* OKXCHAIN */]: new ERC20Token(
    66 /* OKXCHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WOKT",
    "Wrapped Okt",
    "https://xen.network/.com"
  ),
  [65 /* OKXCHAIN_TESTNET */]: new ERC20Token(
    65 /* OKXCHAIN_TESTNET */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WOKT",
    "Wrapped Okt",
    "https://xen.network/.com"
  )
};
var WTFUEL = {
  [361 /* THETHA */]: new ERC20Token(
    361 /* THETHA */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WTFUEL",
    "Wrapped Tfuel",
    "https://xen.network/.com"
  ),
  [365 /* THETHA_TESTNET */]: new ERC20Token(
    365 /* THETHA_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WTFUEL",
    "Wrapped Tfuel",
    "https://xen.network/.com"
  )
};
var WULX = {
  [1231 /* ULTRON */]: new ERC20Token(
    1231 /* ULTRON */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WULX",
    "Wrapped Ulx",
    "https://xen.network/.com"
  ),
  [1230 /* ULTRON_TESTNET */]: new ERC20Token(
    1230 /* ULTRON_TESTNET */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WULX",
    "Wrapped Ulx",
    "https://xen.network/.com"
  )
};
var WGLMR = {
  [1284 /* MOONBEAM */]: new ERC20Token(
    1284 /* MOONBEAM */,
    "0xAcc15dC74880C9944775448304B263D191c6077F",
    18,
    "WGLMR",
    "Wrapped Glmr",
    "https://xen.network/.com"
  )
};
var WMOVR = {
  [1285 /* MOONRIVER */]: new ERC20Token(
    1285 /* MOONRIVER */,
    "0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d",
    18,
    "WMOVR",
    "Wrapped Movr",
    "https://xen.network/.com"
  )
};
var WDEV = {
  [1287 /* MOONBASE_ALPHA */]: new ERC20Token(
    1287 /* MOONBASE_ALPHA */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WDEV",
    "Wrapped Dev",
    "https://xen.network/.com"
  )
};
var WXDAI = {
  [100 /* GNOSIS */]: new ERC20Token(
    100 /* GNOSIS */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WXDAI",
    "Wrapped xDai",
    "https://xen.network/.com"
  ),
  [10200 /* GNOSIS_CHIADO */]: new ERC20Token(
    10200 /* GNOSIS_CHIADO */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WXDAI",
    "Wrapped xDai",
    "https://xen.network/.com"
  )
};
var WMETIS = {
  [1088 /* METIS */]: new ERC20Token(
    1088 /* METIS */,
    "0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481",
    18,
    "WMETIS",
    "Wrapped Metis",
    "https://xen.network/.com"
  ),
  [599 /* METIS_GOERLI */]: new ERC20Token(
    599 /* METIS_GOERLI */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WMETIS",
    "Wrapped Metis",
    "https://xen.network/.com"
  )
};
var WTLOS = {
  [40 /* TELOS */]: new ERC20Token(
    40 /* TELOS */,
    "0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
    18,
    "WTLOS",
    "Wrapped Tlos",
    "https://xen.network/.com"
  ),
  [41 /* TELOS_TESTNET */]: new ERC20Token(
    41 /* TELOS_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WTLOS",
    "Wrapped Tlos",
    "https://xen.network/.com"
  )
};
var WSYS = {
  [57 /* SYSCOIN */]: new ERC20Token(
    57 /* SYSCOIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WSYS",
    "Wrapped Sys",
    "https://xen.network/.com"
  ),
  [5700 /* SYSCOIN_TANENBAUM */]: new ERC20Token(
    5700 /* SYSCOIN_TANENBAUM */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WSYS",
    "Wrapped Sys",
    "https://xen.network/.com"
  )
};
var WIOTX = {
  [4689 /* IOTEX */]: new ERC20Token(
    4689 /* IOTEX */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WIOTX",
    "Wrapped Iotex",
    "https://xen.network/.com"
  ),
  [4690 /* IOTEX_TESTNET */]: new ERC20Token(
    4690 /* IOTEX_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WIOTX",
    "Wrapped Iotex",
    "https://xen.network/.com"
  )
};
var WSDN = {
  [336 /* SHIDEN */]: new ERC20Token(
    336 /* SHIDEN */,
    "0x0f933Dc137D21cA519ae4C7E93f87a4C8EF365Ef",
    18,
    "WSDN",
    "Wrapped Sdn",
    "https://xen.network/.com"
  )
};
var WASTR = {
  [592 /* ASTAR */]: new ERC20Token(
    592 /* ASTAR */,
    "0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720",
    18,
    "WASTR",
    "Wrapped Astr",
    "https://xen.network/.com"
  )
};
var WSBY = {
  [81 /* SHIBUNYA */]: new ERC20Token(
    81 /* SHIBUNYA */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WSBY",
    "Wrapped Sby",
    "https://xen.network/.com"
  )
};
var WFUSE = {
  [122 /* FUSE */]: new ERC20Token(
    122 /* FUSE */,
    "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629",
    18,
    "WFUSE",
    "Wrapped Fuse",
    "https://xen.network/.com"
  ),
  [123 /* FUSE_SPARKNET */]: new ERC20Token(
    123 /* FUSE_SPARKNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WFUSE",
    "Wrapped Fuse",
    "https://xen.network/.com"
  )
};
var WVLX = {
  [106 /* VELAS */]: new ERC20Token(
    106 /* VELAS */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WVLX",
    "Wrapped Vlx",
    "https://xen.network/.com"
  ),
  [111 /* VELAS_TESTNET */]: new ERC20Token(
    111 /* VELAS_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WVLX",
    "Wrapped Vlx",
    "https://xen.network/.com"
  )
};
var WREI = {
  [55555 /* REI */]: new ERC20Token(
    55555 /* REI */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WREI",
    "Wrapped Rei",
    "https://xen.network/.com"
  ),
  [55556 /* REI_TESTNET */]: new ERC20Token(
    55556 /* REI_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WREI",
    "Wrapped Fuse",
    "https://xen.network/.com"
  )
};
var WKEK = {
  [420420 /* KEKCHAIN */]: new ERC20Token(
    420420 /* KEKCHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WKEK",
    "Wrapped Kek",
    "https://xen.network/.com"
  ),
  [420666 /* KEKCHAIN_TESTNET */]: new ERC20Token(
    420666 /* KEKCHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WKEK",
    "Wrapped Kek",
    "https://xen.network/.com"
  )
};
var WTOMO = {
  [88 /* TOMOCHAIN */]: new ERC20Token(
    88 /* TOMOCHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WTOMO",
    "Wrapped Tomo",
    "https://xen.network/.com"
  ),
  [89 /* TOMOCHAIN_TESTNET */]: new ERC20Token(
    89 /* TOMOCHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WTOMO",
    "Wrapped Tomo",
    "https://xen.network/.com"
  )
};
var WTT = {
  [108 /* THUNDERCORE */]: new ERC20Token(
    108 /* THUNDERCORE */,
    "0x413cEFeA29F2d07B8F2acFA69d92466B9535f717",
    18,
    "WTT",
    "Wrapped Tt",
    "https://xen.network/.com"
  ),
  [18 /* THUNDERCORE_TESTNET */]: new ERC20Token(
    18 /* THUNDERCORE_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WTT",
    "Wrapped Tt",
    "https://xen.network/.com"
  )
};
var WWAN = {
  [888 /* WANCHAIN */]: new ERC20Token(
    888 /* WANCHAIN */,
    "0xdabD997aE5E4799BE47d6E69D9431615CBa28f48",
    18,
    "WWAN",
    "Wrapped Wan",
    "https://xen.network/.com"
  ),
  [999 /* WANCHAIN_TESTNET */]: new ERC20Token(
    999 /* WANCHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WWAN",
    "Wrapped Wan",
    "https://xen.network/.com"
  )
};
var WRBTC = {
  [30 /* RSK */]: new ERC20Token(
    30 /* RSK */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WRBTC",
    "Wrapped Rbtc",
    "https://xen.network/.com"
  ),
  [31 /* RSK_TESTNET */]: new ERC20Token(
    31 /* RSK_TESTNET */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WRBTC",
    "Wrapped Rbtc",
    "https://xen.network/.com"
  )
};
var WELA = {
  [20 /* ELASTOS */]: new ERC20Token(
    20 /* ELASTOS */,
    "0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4",
    18,
    "WELA",
    "Wrapped Ela",
    "https://xen.network/.com"
  ),
  [21 /* ELASTOS_TESTNET */]: new ERC20Token(
    21 /* ELASTOS_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WELA",
    "Wrapped Ela",
    "https://xen.network/.com"
  )
};
var WCFX = {
  [1030 /* CONFLUX */]: new ERC20Token(
    1030 /* CONFLUX */,
    "0x14b2D3bC65e74DAE1030EAFd8ac30c533c976A9b",
    18,
    "WCFX",
    "Wrapped Cfx",
    "https://xen.network/.com"
  ),
  [71 /* CONFLUX_TESTNET */]: new ERC20Token(
    71 /* CONFLUX_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCFX",
    "Wrapped Cfx",
    "https://xen.network/.com"
  )
};
var WBRISE = {
  [32520 /* BRISECHAIN */]: new ERC20Token(
    32520 /* BRISECHAIN */,
    "0x0eb9036cbE0f052386f36170c6b07eF0a0E3f710",
    18,
    "WBRISE",
    "Wrapped Brise",
    "https://xen.network/.com"
  ),
  [64668 /* BRISECHAIN_TESTNET */]: new ERC20Token(
    64668 /* BRISECHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WBRISE",
    "Wrapped Brise",
    "https://xen.network/.com"
  )
};
var WMUU = {
  [20402 /* MUUCHAIN */]: new ERC20Token(
    20402 /* MUUCHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WMUU",
    "Wrapped Muu",
    "https://xen.network/.com"
  ),
  [42110 /* MUUCHAIN_TESTNET */]: new ERC20Token(
    42110 /* MUUCHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WMUU",
    "Wrapped Muu",
    "https://xen.network/.com"
  )
};
var WCANTO = {
  [7700 /* CANTO */]: new ERC20Token(
    7700 /* CANTO */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCANTO",
    "Wrapped Muu",
    "https://xen.network/.com"
  ),
  [7701 /* CANTO_TESTNET */]: new ERC20Token(
    7701 /* CANTO_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCANTO",
    "Wrapped Muu",
    "https://xen.network/.com"
  )
};
var WROSE = {
  [42262 /* OASIS_EMERALD */]: new ERC20Token(
    42262 /* OASIS_EMERALD */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WROSE",
    "Wrapped Rose",
    "https://xen.network/.com"
  ),
  [42261 /* OASIS_TESTNET */]: new ERC20Token(
    42261 /* OASIS_TESTNET */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WROSE",
    "Wrapped Rose",
    "https://xen.network/.com"
  )
};
var WVS = {
  [888888 /* VISION */]: new ERC20Token(
    888888 /* VISION */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WVS",
    "Wrapped Vs",
    "https://xen.network/.com"
  ),
  [666666 /* VISION_TESTNET */]: new ERC20Token(
    666666 /* VISION_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WVS",
    "Wrapped Vs",
    "https://xen.network/.com"
  )
};
var WFITFI = {
  [1234 /* STEP */]: new ERC20Token(
    1234 /* STEP */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WFITFI",
    "Wrapped Fitfi",
    "https://xen.network/.com"
  ),
  [12345 /* STEP_TESTNET */]: new ERC20Token(
    12345 /* STEP_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WFITFI",
    "Wrapped Fitfi",
    "https://xen.network/.com"
  )
};
var WMTR = {
  [82 /* METER */]: new ERC20Token(
    82 /* METER */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WMTR",
    "Wrapped Mtr",
    "https://xen.network/.com"
  ),
  [83 /* METER_TESTNET */]: new ERC20Token(
    83 /* METER_TESTNET */,
    "0x8648260526570F425CAF419FD2A0cF1962322a53",
    18,
    "WMTR",
    "Wrapped Mtr",
    "https://xen.network/.com"
  )
};
var WPCKB = {
  [71402 /* GODWOKEN */]: new ERC20Token(
    71402 /* GODWOKEN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WPCKB",
    "Wrapped Pckb",
    "https://xen.network/.com"
  ),
  [71401 /* GODWOKEN_TESTNET */]: new ERC20Token(
    71401 /* GODWOKEN_TESTNET */,
    "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    18,
    "WPCKB",
    "Wrapped Pckb",
    "https://xen.network/.com"
  )
};
var WCLO = {
  [820 /* CALLISTO */]: new ERC20Token(
    820 /* CALLISTO */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCLO",
    "Wrapped Clo",
    "https://xen.network/.com"
  ),
  [20729 /* CALLISTO_TESTNET */]: new ERC20Token(
    20729 /* CALLISTO_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCLO",
    "Wrapped Clo",
    "https://xen.network/.com"
  )
};
var WEVMOS = {
  [9001 /* EVMOS */]: new ERC20Token(
    9001 /* EVMOS */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WEVMOS",
    "Wrapped Evmos",
    "https://xen.network/.com"
  ),
  [9e3 /* EVMOS_TESTNET */]: new ERC20Token(
    9e3 /* EVMOS_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WEVMOS",
    "Wrapped Evmos",
    "https://xen.network/.com"
  )
};
var WEWT = {
  [246 /* ENERGY_WEB_CHAIN */]: new ERC20Token(
    246 /* ENERGY_WEB_CHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WEWT",
    "Wrapped Ewt",
    "https://xen.network/.com"
  ),
  [73799 /* ENERGY_VOLTA */]: new ERC20Token(
    73799 /* ENERGY_VOLTA */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WEWT",
    "Wrapped Ewt",
    "https://xen.network/.com"
  )
};
var WKAVA = {
  [2222 /* KAVA */]: new ERC20Token(
    2222 /* KAVA */,
    "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
    18,
    "WKAVA",
    "Wrapped Kava",
    "https://xen.network/.com"
  ),
  [2221 /* KAVA_TESTNET */]: new ERC20Token(
    2221 /* KAVA_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WKAVA",
    "Wrapped Kava",
    "https://xen.network/.com"
  )
};
var WCLV = {
  [1024 /* CLOVER */]: new ERC20Token(
    1024 /* CLOVER */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCLV",
    "Wrapped Clover",
    "https://xen.network/.com"
  ),
  [1023 /* CLOVER_TESTNET */]: new ERC20Token(
    1023 /* CLOVER_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCLV",
    "Wrapped Clover",
    "https://xen.network/.com"
  )
};
var WDFI = {
  [1131 /* DEFICHAIN */]: new ERC20Token(
    1131 /* DEFICHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WDFI",
    "Wrapped Dfi",
    "https://xen.network/.com"
  ),
  [1132 /* DEFICHAIN_TESTNET */]: new ERC20Token(
    1132 /* DEFICHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WDFI",
    "Wrapped Dfi",
    "https://xen.network/.com"
  )
};
var WBRO = {
  [1039 /* BRONOS */]: new ERC20Token(
    1039 /* BRONOS */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WBRO",
    "Wrapped Bro",
    "https://xen.network/.com"
  ),
  [1038 /* BRONOS_TESTNET */]: new ERC20Token(
    1038 /* BRONOS_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WBRO",
    "Wrapped Bro",
    "https://xen.network/.com"
  )
};
var WFIL = {
  [314 /* FILECOIN */]: new ERC20Token(
    314 /* FILECOIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WFIL",
    "Wrapped Fil",
    "https://xen.network/.com"
  ),
  [314159 /* FILECOIN_CALIBRATION */]: new ERC20Token(
    314159 /* FILECOIN_CALIBRATION */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WFIL",
    "Wrapped Fil",
    "https://xen.network/.com"
  )
};
var WFLR = {
  [14 /* FLARE */]: new ERC20Token(
    14 /* FLARE */,
    "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d",
    18,
    "WFLR",
    "Wrapped Flr",
    "https://xen.network/.com"
  ),
  [114 /* FLARE_TESTNET */]: new ERC20Token(
    114 /* FLARE_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WFLR",
    "Wrapped Flr",
    "https://xen.network/.com"
  )
};
var WTARA = {
  [841 /* TARAXA */]: new ERC20Token(
    841 /* TARAXA */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WTARA",
    "Wrapped Tara",
    "https://xen.network/.com"
  ),
  [842 /* TARAXA_TESTNET */]: new ERC20Token(
    842 /* TARAXA_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WTARA",
    "Wrapped Tara",
    "https://xen.network/.com"
  )
};
var WBTT = {
  [199 /* BTTCHAIN */]: new ERC20Token(
    199 /* BTTCHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WBTT",
    "Wrapped Btt",
    "https://xen.network/.com"
  ),
  [1029 /* BTTCHAIN_TESTNET */]: new ERC20Token(
    1029 /* BTTCHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WBTT",
    "Wrapped Btt",
    "https://xen.network/.com"
  )
};
var WBOBA = {
  [43288 /* BOBA_AVAX */]: new ERC20Token(
    43288 /* BOBA_AVAX */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WBOBA",
    "Wrapped Boba",
    "https://xen.network/.com"
  ),
  [56288 /* BOBA_BNB */]: new ERC20Token(
    56288 /* BOBA_BNB */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WBOBA",
    "Wrapped Boba",
    "https://xen.network/.com"
  )
};
var WCMP = {
  [256256 /* CMP */]: new ERC20Token(
    256256 /* CMP */,
    "0x1fcbA3Cb797465F38839F48CA7c9cDA9d9aac28b",
    18,
    "WCMP",
    "Wrapped Cmp",
    "https://xen.network/.com"
  ),
  [512512 /* CMP_TESTNET */]: new ERC20Token(
    512512 /* CMP_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCMP",
    "Wrapped cmp",
    "https://xen.network/.com"
  )
};
var WCORE = {
  [1116 /* CORE */]: new ERC20Token(
    1116 /* CORE */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCORE",
    "Wrapped Core",
    "https://xen.network/.com"
  ),
  [1115 /* CORE_TESTNET */]: new ERC20Token(
    1115 /* CORE_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCORE",
    "Wrapped Core",
    "https://xen.network/.com"
  )
};
var WCUBE = {
  [1818 /* CUBECHAIN */]: new ERC20Token(
    1818 /* CUBECHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCUBE",
    "Wrapped Cube",
    "https://xen.network/.com"
  ),
  [1819 /* CUBECHAIN_TESTNET */]: new ERC20Token(
    1819 /* CUBECHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WCUBE",
    "Wrapped Cube",
    "https://xen.network/.com"
  )
};
var WCRAB = {
  [44 /* DARWINIA_CRAB */]: new ERC20Token(
    44 /* DARWINIA_CRAB */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WCRAB",
    "Wrapped Crab",
    "https://xen.network/.com"
  )
};
var WPRING = {
  [43 /* DARWINIA_PANGOLIN */]: new ERC20Token(
    43 /* DARWINIA_PANGOLIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WPRING",
    "Wrapped Pring",
    "https://xen.network/.com"
  )
};
var WDIODE = {
  [15 /* DIODE_PRETNET */]: new ERC20Token(
    15 /* DIODE_PRETNET */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WDIODE",
    "Wrapped Diode",
    "https://xen.network/.com"
  ),
  [13 /* DIODE_TESTNET */]: new ERC20Token(
    13 /* DIODE_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WDIODE",
    "Wrapped Diode",
    "https://xen.network/.com"
  )
};
var WGO = {
  [60 /* GOCHAIN */]: new ERC20Token(
    60 /* GOCHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WGO",
    "Wrapped Go",
    "https://xen.network/.com"
  ),
  [31337 /* GOCHAIN_TESTNET */]: new ERC20Token(
    31337 /* GOCHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WGO",
    "Wrapped Go",
    "https://xen.network/.com"
  )
};
var WISLM = {
  [11235 /* HAQQ */]: new ERC20Token(
    11235 /* HAQQ */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WISLM",
    "Wrapped Islm",
    "https://xen.network/.com"
  ),
  [54211 /* HAQQ_TESTEDGE2 */]: new ERC20Token(
    54211 /* HAQQ_TESTEDGE2 */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WISLM",
    "Wrapped Islm",
    "https://xen.network/.com"
  )
};
var WKAI = {
  [24 /* KARDIACHAIN */]: new ERC20Token(
    24 /* KARDIACHAIN */,
    "0xAF984E23EAA3E7967F3C5E007fbe397D8566D23d",
    18,
    "WKAI",
    "Wrapped Kai",
    "https://xen.network/.com"
  ),
  [242 /* KARDIACHAIN_TESTNET */]: new ERC20Token(
    242 /* KARDIACHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WKAI",
    "Wrapped Kai",
    "https://xen.network/.com"
  )
};
var WMETA = {
  [11 /* METADIUM */]: new ERC20Token(
    11 /* METADIUM */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WMETA",
    "Wrapped Meta",
    "https://xen.network/.com"
  )
};
var WKAL = {
  [12 /* METADIUM_TESTNET */]: new ERC20Token(
    12 /* METADIUM_TESTNET */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WKAL",
    "Wrapped Kal",
    "https://xen.network/.com"
  )
};
var WPALM = {
  [11297108109 /* PALM */]: new ERC20Token(
    11297108109 /* PALM */,
    "0xF98cABF0a963452C5536330408B2590567611a71",
    18,
    "WPALM",
    "Wrapped Palm",
    "https://xen.network/.com"
  ),
  [11297108099 /* PALM_TESTNET */]: new ERC20Token(
    11297108099 /* PALM_TESTNET */,
    "0xF98cABF0a963452C5536330408B2590567611a71",
    18,
    "WPALM",
    "Wrapped Palm",
    "https://xen.network/.com"
  )
};
var WPOLIS = {
  [333999 /* POLIS */]: new ERC20Token(
    333999 /* POLIS */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WPOLIS",
    "Wrapped Polis",
    "https://xen.network/.com"
  ),
  [333888 /* POLIS_TESTNET */]: new ERC20Token(
    333888 /* POLIS_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WPOLIS",
    "Wrapped Polis",
    "https://xen.network/.com"
  )
};
var WSHIB = {
  [27 /* SHIBACHAIN */]: new ERC20Token(
    27 /* SHIBACHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WSHIB",
    "Wrapped Shib",
    "https://xen.network/.com"
  )
};
var WBCH = {
  [1e4 /* SMART_BCH */]: new ERC20Token(
    1e4 /* SMART_BCH */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WBCH",
    "Wrapped Bch",
    "https://xen.network/.com"
  ),
  [10001 /* SMART_BCH_TESTNET */]: new ERC20Token(
    10001 /* SMART_BCH_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WBCH",
    "Wrapped Bch",
    "https://xen.network/.com"
  )
};
var WSGB = {
  [19 /* SONGBIRD_CANARY */]: new ERC20Token(
    19 /* SONGBIRD_CANARY */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WSGB",
    "Wrapped Sgb",
    "https://xen.network/.com"
  )
};
var WTCH = {
  [7 /* THAICHAIN */]: new ERC20Token(
    7 /* THAICHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WTCH",
    "Wrapped Tch",
    "https://xen.network/.com"
  )
};
var WUBQ = {
  [8 /* UBIQ */]: new ERC20Token(
    8 /* UBIQ */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WUBQ",
    "Wrapped Ubq",
    "https://xen.network/.com"
  ),
  [9 /* UBIQ_TESTNET */]: new ERC20Token(
    9 /* UBIQ_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WUBQ",
    "Wrapped Ubq",
    "https://xen.network/.com"
  )
};
var WVET = {
  [100009 /* VECHAIN */]: new ERC20Token(
    100009 /* VECHAIN */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WVET",
    "Wrapped Vet",
    "https://xen.network/.com"
  ),
  [100010 /* VECHAIN_TESTNET */]: new ERC20Token(
    100010 /* VECHAIN_TESTNET */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WPOLIS",
    "Wrapped Vet",
    "https://xen.network/.com"
  )
};
var WXDC = {
  [50 /* XINFIN */]: new ERC20Token(
    50 /* XINFIN */,
    "0x951857744785E80e2De051c32EE7b25f9c458C42",
    18,
    "WXDC",
    "Wrapped Xdc",
    "https://xen.network/.com"
  ),
  [51 /* XINFIN_APOTHEM */]: new ERC20Token(
    51 /* XINFIN_APOTHEM */,
    "0x0216a640C4d53F2a6603042d4E14A2B890efcD45",
    18,
    "WXDC",
    "Wrapped Xdc",
    "https://xen.network/.com"
  )
};
var WXRP = {
  // [ChainId.ZETACHAIN]: new ERC20Token(
  //   ChainId.ZETACHAIN,
  //   '0x951857744785E80e2De051c32EE7b25f9c458C42',
  //   18,
  //   'WZETA',
  //   'Wrapped Zeta',
  //   'https://www.zetachain.com/'
  // ),
  [1440002 /* XRPL_DEVNET */]: new ERC20Token(
    1440002 /* XRPL_DEVNET */,
    "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
    18,
    "WXRP",
    "Wrapped Xrp",
    "https://www.zetachain.com/"
  )
};
var WZETA = {
  [7e3 /* ZETACHAIN */]: new ERC20Token(
    7e3 /* ZETACHAIN */,
    "0x951857744785E80e2De051c32EE7b25f9c458C42",
    18,
    "WZETA",
    "Wrapped Zeta",
    "https://www.zetachain.com/"
  ),
  [7001 /* ZETACHAIN_ATHENS */]: new ERC20Token(
    7001 /* ZETACHAIN_ATHENS */,
    "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
    18,
    "WZETA",
    "Wrapped Zeta",
    "https://www.zetachain.com/"
  )
};
var WZYX = {
  [55 /* ZYX */]: new ERC20Token(
    55 /* ZYX */,
    "0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6",
    18,
    "WZYX",
    "Wrapped Zyx",
    "https://xen.network/.com"
  )
};
var WNATIVE = {
  [529 /* FIRECHAIN */]: WFIRE[529 /* FIRECHAIN */],
  [917 /* RINIA */]: WFIRE[917 /* RINIA */],
  [814 /* FIRECHAIN_ZKEVM */]: WETH9[814 /* FIRECHAIN_ZKEVM */],
  [3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */]: WETH9[3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */],
  [1 /* ETHEREUM */]: WETH9[1 /* ETHEREUM */],
  [5 /* GOERLI */]: WETH9[5 /* GOERLI */],
  [11155111 /* SEPOLIA */]: WETH9[11155111 /* SEPOLIA */],
  [17e3 /* HOLESKY */]: WETH9[17e3 /* HOLESKY */],
  [56 /* BSC */]: WBNB[56 /* BSC */],
  [97 /* BSC_TESTNET */]: WBNB[97 /* BSC_TESTNET */],
  [137 /* POLYGON */]: WMATIC[137 /* POLYGON */],
  [80002 /* POLYGON_AMOY */]: WMATIC[80002 /* POLYGON_AMOY */],
  [25 /* CRO */]: WCRO[25 /* CRO */],
  [338 /* CRO_TESTNET */]: WCRO[338 /* CRO_TESTNET */],
  [43114 /* AVALANCHE */]: WAVAX[43114 /* AVALANCHE */],
  [43113 /* AVALANCHE_FUJI */]: WAVAX[43113 /* AVALANCHE_FUJI */],
  [250 /* FANTOM_OPERA */]: WFTM[250 /* FANTOM_OPERA */],
  [4002 /* FANTOM_TESTNET */]: WFTM[4002 /* FANTOM_TESTNET */],
  [42220 /* CELO */]: WCELO[42220 /* CELO */],
  [44787 /* CELO_ALFAJORES */]: WCELO[44787 /* CELO_ALFAJORES */],
  [10 /* OPTIMISM */]: WETH9[10 /* OPTIMISM */],
  [420 /* OPTIMISM_GOERLI */]: WETH9[420 /* OPTIMISM_GOERLI */],
  [42161 /* ARBITRUM_ONE */]: WETH9[42161 /* ARBITRUM_ONE */],
  [421613 /* ARBITRUM_GOERLI */]: WETH9[421613 /* ARBITRUM_GOERLI */],
  [109 /* SHIBARIUM */]: WBONE[109 /* SHIBARIUM */],
  [719 /* SHIBARIUM_TESTNET */]: WBONE[719 /* SHIBARIUM_TESTNET */],
  [369 /* PULSECHAIN */]: WPLS[369 /* PULSECHAIN */],
  [943 /* PULSECHAIN_TESTNET */]: WPLS[943 /* PULSECHAIN_TESTNET */],
  [202212e5 /* XEN */]: WXN[202212e5 /* XEN */],
  [202212 /* XEN_DEVNET */]: WXN[202212 /* XEN_DEVNET */],
  [16666e5 /* HARMONY */]: WONE[16666e5 /* HARMONY */],
  [16667e5 /* HARMONY_TESTNET */]: WONE[16667e5 /* HARMONY_TESTNET */],
  [52 /* COINEX */]: WCET[52 /* COINEX */],
  [53 /* COINEX_TESTNET */]: WCET[53 /* COINEX_TESTNET */],
  [2e3 /* DOGECHAIN */]: WDOGE[2e3 /* DOGECHAIN */],
  [568 /* DOGECHAIN_TESTNET */]: WDOGE[568 /* DOGECHAIN_TESTNET */],
  [131 /* ENGRAM_TESTNET */]: WGRAM[131 /* ENGRAM_TESTNET */],
  [61 /* ETHEREUM_CLASSIC */]: WETC[61 /* ETHEREUM_CLASSIC */],
  [63 /* ETHEREUM_CLASSIC_TESTNET */]: WETC[63 /* ETHEREUM_CLASSIC_TESTNET */],
  [32659 /* FUSION */]: WFSN[32659 /* FUSION */],
  [46688 /* FUSION_TESTNET */]: WFSN[46688 /* FUSION_TESTNET */],
  [128 /* HECO */]: WHT[128 /* HECO */],
  [256 /* HECO_TESTNET */]: WHT[256 /* HECO_TESTNET */],
  [321 /* KCC */]: WKCS[321 /* KCC */],
  [322 /* KCC_TESTNET */]: WKCS[322 /* KCC_TESTNET */],
  [8217 /* KLAYTN */]: WKLAY[8217 /* KLAYTN */],
  [1001 /* KLAYTN_BAOBAB */]: WKLAY[1001 /* KLAYTN_BAOBAB */],
  [66 /* OKXCHAIN */]: WOKT[66 /* OKXCHAIN */],
  [65 /* OKXCHAIN_TESTNET */]: WOKT[65 /* OKXCHAIN_TESTNET */],
  [361 /* THETHA */]: WTFUEL[361 /* THETHA */],
  [365 /* THETHA_TESTNET */]: WTFUEL[365 /* THETHA_TESTNET */],
  [1231 /* ULTRON */]: WULX[1231 /* ULTRON */],
  [1230 /* ULTRON_TESTNET */]: WULX[1230 /* ULTRON_TESTNET */],
  [1284 /* MOONBEAM */]: WGLMR[1284 /* MOONBEAM */],
  [1285 /* MOONRIVER */]: WMOVR[1285 /* MOONRIVER */],
  [1287 /* MOONBASE_ALPHA */]: WDEV[1287 /* MOONBASE_ALPHA */],
  [1313161554 /* AURORA */]: WETH9[1313161554 /* AURORA */],
  [1313161555 /* AURORA_TESTNET */]: WETH9[1313161555 /* AURORA_TESTNET */],
  [288 /* BOBA */]: WETH9[288 /* BOBA */],
  [2888 /* BOBA_GOERLI */]: WETH9[2888 /* BOBA_GOERLI */],
  [100 /* GNOSIS */]: WXDAI[100 /* GNOSIS */],
  [10200 /* GNOSIS_CHIADO */]: WXDAI[10200 /* GNOSIS_CHIADO */],
  [1088 /* METIS */]: WMETIS[1088 /* METIS */],
  [599 /* METIS_GOERLI */]: WMETIS[599 /* METIS_GOERLI */],
  [40 /* TELOS */]: WTLOS[40 /* TELOS */],
  [41 /* TELOS_TESTNET */]: WTLOS[41 /* TELOS_TESTNET */],
  [57 /* SYSCOIN */]: WSYS[57 /* SYSCOIN */],
  [5700 /* SYSCOIN_TANENBAUM */]: WSYS[5700 /* SYSCOIN_TANENBAUM */],
  [4689 /* IOTEX */]: WIOTX[4689 /* IOTEX */],
  [4690 /* IOTEX_TESTNET */]: WIOTX[4690 /* IOTEX_TESTNET */],
  [336 /* SHIDEN */]: WSDN[336 /* SHIDEN */],
  [592 /* ASTAR */]: WASTR[592 /* ASTAR */],
  [81 /* SHIBUNYA */]: WSBY[81 /* SHIBUNYA */],
  [122 /* FUSE */]: WFUSE[122 /* FUSE */],
  [123 /* FUSE_SPARKNET */]: WFUSE[123 /* FUSE_SPARKNET */],
  [106 /* VELAS */]: WVLX[106 /* VELAS */],
  [111 /* VELAS_TESTNET */]: WVLX[111 /* VELAS_TESTNET */],
  [55555 /* REI */]: WREI[55555 /* REI */],
  [55556 /* REI_TESTNET */]: WREI[55556 /* REI_TESTNET */],
  [420420 /* KEKCHAIN */]: WKEK[420420 /* KEKCHAIN */],
  [420666 /* KEKCHAIN_TESTNET */]: WKEK[420666 /* KEKCHAIN_TESTNET */],
  [88 /* TOMOCHAIN */]: WTOMO[88 /* TOMOCHAIN */],
  [89 /* TOMOCHAIN_TESTNET */]: WTOMO[89 /* TOMOCHAIN_TESTNET */],
  [108 /* THUNDERCORE */]: WTT[108 /* THUNDERCORE */],
  [18 /* THUNDERCORE_TESTNET */]: WTT[18 /* THUNDERCORE_TESTNET */],
  [888 /* WANCHAIN */]: WWAN[888 /* WANCHAIN */],
  [999 /* WANCHAIN_TESTNET */]: WWAN[999 /* WANCHAIN_TESTNET */],
  [30 /* RSK */]: WRBTC[30 /* RSK */],
  [31 /* RSK_TESTNET */]: WRBTC[31 /* RSK_TESTNET */],
  [20 /* ELASTOS */]: WELA[20 /* ELASTOS */],
  [21 /* ELASTOS_TESTNET */]: WELA[21 /* ELASTOS_TESTNET */],
  [1030 /* CONFLUX */]: WCFX[1030 /* CONFLUX */],
  [71 /* CONFLUX_TESTNET */]: WCFX[71 /* CONFLUX_TESTNET */],
  [32520 /* BRISECHAIN */]: WBRISE[32520 /* BRISECHAIN */],
  [64668 /* BRISECHAIN_TESTNET */]: WBRISE[64668 /* BRISECHAIN_TESTNET */],
  [20402 /* MUUCHAIN */]: WMUU[20402 /* MUUCHAIN */],
  [42110 /* MUUCHAIN_TESTNET */]: WMUU[42110 /* MUUCHAIN_TESTNET */],
  [7700 /* CANTO */]: WCANTO[7700 /* CANTO */],
  [7701 /* CANTO_TESTNET */]: WCANTO[7701 /* CANTO_TESTNET */],
  [42262 /* OASIS_EMERALD */]: WROSE[42262 /* OASIS_EMERALD */],
  [42261 /* OASIS_TESTNET */]: WROSE[42261 /* OASIS_TESTNET */],
  [888888 /* VISION */]: WVS[888888 /* VISION */],
  [666666 /* VISION_TESTNET */]: WVS[666666 /* VISION_TESTNET */],
  [1234 /* STEP */]: WFITFI[1234 /* STEP */],
  [12345 /* STEP_TESTNET */]: WFITFI[12345 /* STEP_TESTNET */],
  [82 /* METER */]: WMTR[82 /* METER */],
  [83 /* METER_TESTNET */]: WMTR[83 /* METER_TESTNET */],
  [71402 /* GODWOKEN */]: WPCKB[71402 /* GODWOKEN */],
  [71401 /* GODWOKEN_TESTNET */]: WPCKB[71401 /* GODWOKEN_TESTNET */],
  [820 /* CALLISTO */]: WCLO[820 /* CALLISTO */],
  [20729 /* CALLISTO_TESTNET */]: WCLO[20729 /* CALLISTO_TESTNET */],
  [9001 /* EVMOS */]: WEVMOS[9001 /* EVMOS */],
  [9e3 /* EVMOS_TESTNET */]: WEVMOS[9e3 /* EVMOS_TESTNET */],
  [246 /* ENERGY_WEB_CHAIN */]: WEWT[246 /* ENERGY_WEB_CHAIN */],
  [73799 /* ENERGY_VOLTA */]: WEWT[73799 /* ENERGY_VOLTA */],
  [8453 /* BASE */]: WETH9[8453 /* BASE */],
  [84531 /* BASE_GOERLI */]: WETH9[84531 /* BASE_GOERLI */],
  [2222 /* KAVA */]: WKAVA[2222 /* KAVA */],
  [2221 /* KAVA_TESTNET */]: WKAVA[2221 /* KAVA_TESTNET */],
  [1024 /* CLOVER */]: WCLV[1024 /* CLOVER */],
  [1023 /* CLOVER_TESTNET */]: WCLV[1023 /* CLOVER_TESTNET */],
  [1131 /* DEFICHAIN */]: WDFI[1131 /* DEFICHAIN */],
  [1132 /* DEFICHAIN_TESTNET */]: WDFI[1132 /* DEFICHAIN_TESTNET */],
  [1039 /* BRONOS */]: WBRO[1039 /* BRONOS */],
  [1038 /* BRONOS_TESTNET */]: WBRO[1038 /* BRONOS_TESTNET */],
  [314 /* FILECOIN */]: WFIL[314 /* FILECOIN */],
  [314159 /* FILECOIN_CALIBRATION */]: WFIL[314159 /* FILECOIN_CALIBRATION */],
  [14 /* FLARE */]: WFLR[14 /* FLARE */],
  [114 /* FLARE_TESTNET */]: WFLR[114 /* FLARE_TESTNET */],
  [841 /* TARAXA */]: WTARA[841 /* TARAXA */],
  [842 /* TARAXA_TESTNET */]: WTARA[842 /* TARAXA_TESTNET */],
  [1101 /* POLYGON_ZKEVM */]: WETH9[1101 /* POLYGON_ZKEVM */],
  [1442 /* POLYGON_ZKEVM_TESTNET */]: WETH9[1442 /* POLYGON_ZKEVM_TESTNET */],
  [324 /* ZKSYNC */]: WETH9[324 /* ZKSYNC */],
  [280 /* ZKSYNC_TESTNET */]: WETH9[280 /* ZKSYNC_TESTNET */],
  [59144 /* LINEA */]: WETH9[59144 /* LINEA */],
  [59140 /* LINEA_TESTNET */]: WETH9[59140 /* LINEA_TESTNET */],
  [199 /* BTTCHAIN */]: WBTT[199 /* BTTCHAIN */],
  [1029 /* BTTCHAIN_TESTNET */]: WBTT[1029 /* BTTCHAIN_TESTNET */],
  [43288 /* BOBA_AVAX */]: WBOBA[43288 /* BOBA_AVAX */],
  [56288 /* BOBA_BNB */]: WBOBA[56288 /* BOBA_BNB */],
  [256256 /* CMP */]: WCMP[256256 /* CMP */],
  [512512 /* CMP_TESTNET */]: WCMP[512512 /* CMP_TESTNET */],
  [1116 /* CORE */]: WCORE[1116 /* CORE */],
  [1115 /* CORE_TESTNET */]: WCORE[1115 /* CORE_TESTNET */],
  [1818 /* CUBECHAIN */]: WCUBE[1818 /* CUBECHAIN */],
  [1819 /* CUBECHAIN_TESTNET */]: WCUBE[1819 /* CUBECHAIN_TESTNET */],
  [44 /* DARWINIA_CRAB */]: WCRAB[44 /* DARWINIA_CRAB */],
  [43 /* DARWINIA_PANGOLIN */]: WPRING[43 /* DARWINIA_PANGOLIN */],
  [15 /* DIODE_PRETNET */]: WDIODE[15 /* DIODE_PRETNET */],
  [13 /* DIODE_TESTNET */]: WDIODE[13 /* DIODE_TESTNET */],
  [60 /* GOCHAIN */]: WGO[60 /* GOCHAIN */],
  [31337 /* GOCHAIN_TESTNET */]: WGO[31337 /* GOCHAIN_TESTNET */],
  [11235 /* HAQQ */]: WISLM[11235 /* HAQQ */],
  [54211 /* HAQQ_TESTEDGE2 */]: WISLM[54211 /* HAQQ_TESTEDGE2 */],
  [24 /* KARDIACHAIN */]: WKAI[24 /* KARDIACHAIN */],
  [242 /* KARDIACHAIN_TESTNET */]: WKAI[242 /* KARDIACHAIN_TESTNET */],
  [11 /* METADIUM */]: WMETA[11 /* METADIUM */],
  [12 /* METADIUM_TESTNET */]: WKAL[12 /* METADIUM_TESTNET */],
  [204 /* OP_BNB */]: WBNB[204 /* OP_BNB */],
  [5611 /* OP_BNB_TESTNET */]: WBNB[5611 /* OP_BNB_TESTNET */],
  [11297108109 /* PALM */]: WPALM[11297108109 /* PALM */],
  [11297108099 /* PALM_TESTNET */]: WPALM[11297108099 /* PALM_TESTNET */],
  [333999 /* POLIS */]: WPOLIS[333999 /* POLIS */],
  [333888 /* POLIS_TESTNET */]: WPOLIS[333888 /* POLIS_TESTNET */],
  [27 /* SHIBACHAIN */]: WSHIB[27 /* SHIBACHAIN */],
  [1e4 /* SMART_BCH */]: WBCH[1e4 /* SMART_BCH */],
  [10001 /* SMART_BCH_TESTNET */]: WBCH[10001 /* SMART_BCH_TESTNET */],
  [19 /* SONGBIRD_CANARY */]: WSGB[19 /* SONGBIRD_CANARY */],
  [7 /* THAICHAIN */]: WTCH[7 /* THAICHAIN */],
  [8 /* UBIQ */]: WUBQ[8 /* UBIQ */],
  [9 /* UBIQ_TESTNET */]: WUBQ[9 /* UBIQ_TESTNET */],
  [100009 /* VECHAIN */]: WVET[100009 /* VECHAIN */],
  [100010 /* VECHAIN_TESTNET */]: WVET[100010 /* VECHAIN_TESTNET */],
  [50 /* XINFIN */]: WXDC[50 /* XINFIN */],
  [51 /* XINFIN_APOTHEM */]: WXDC[51 /* XINFIN_APOTHEM */],
  [534352 /* SCROLL */]: WETH9[534352 /* SCROLL */],
  [534351 /* SCROLL_SEPOLIA */]: WETH9[534351 /* SCROLL_SEPOLIA */],
  [1440002 /* XRPL_DEVNET */]: WXRP[1440002 /* XRPL_DEVNET */],
  [7e3 /* ZETACHAIN */]: WZETA[7e3 /* ZETACHAIN */],
  [7001 /* ZETACHAIN_ATHENS */]: WZETA[7001 /* ZETACHAIN_ATHENS */],
  [55 /* ZYX */]: WZYX[55 /* ZYX */]
};

// src/trade.ts
function isTradeBetter(tradeA, tradeB, minimumDelta = ZERO_PERCENT) {
  if (tradeA && !tradeB)
    return false;
  if (tradeB && !tradeA)
    return true;
  if (!tradeA || !tradeB)
    return void 0;
  if (tradeA.tradeType !== tradeB.tradeType || !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) || !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency)) {
    throw new Error("Trades are not comparable");
  }
  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.asFraction.multiply(minimumDelta.add(ONE_HUNDRED_PERCENT)).lessThan(tradeB.executionPrice);
}

// src/v2FactoryAddressMap.ts
var V2_FACTORY_ADDRESS = "0x8e5dff1c121F661971d02950698f8c5EFc3DfA78";
var V2_FACTORY_ADDRESS_MAP = {
  [529 /* FIRECHAIN */]: V2_FACTORY_ADDRESS,
  [917 /* RINIA */]: "0x90A12A60B208773c2062DdC94bF3F9f3DC4774F8",
  [814 /* FIRECHAIN_ZKEVM */]: V2_FACTORY_ADDRESS,
  [3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */]: V2_FACTORY_ADDRESS,
  [1 /* ETHEREUM */]: V2_FACTORY_ADDRESS,
  [5 /* GOERLI */]: V2_FACTORY_ADDRESS,
  [11155111 /* SEPOLIA */]: V2_FACTORY_ADDRESS,
  [17e3 /* HOLESKY */]: V2_FACTORY_ADDRESS,
  [56 /* BSC */]: V2_FACTORY_ADDRESS,
  [97 /* BSC_TESTNET */]: V2_FACTORY_ADDRESS,
  [137 /* POLYGON */]: V2_FACTORY_ADDRESS,
  [80002 /* POLYGON_AMOY */]: V2_FACTORY_ADDRESS,
  [1101 /* POLYGON_ZKEVM */]: V2_FACTORY_ADDRESS,
  [1442 /* POLYGON_ZKEVM_TESTNET */]: V2_FACTORY_ADDRESS,
  [25 /* CRO */]: V2_FACTORY_ADDRESS,
  [338 /* CRO_TESTNET */]: V2_FACTORY_ADDRESS,
  [43114 /* AVALANCHE */]: V2_FACTORY_ADDRESS,
  [43113 /* AVALANCHE_FUJI */]: V2_FACTORY_ADDRESS,
  [250 /* FANTOM_OPERA */]: V2_FACTORY_ADDRESS,
  [4002 /* FANTOM_TESTNET */]: V2_FACTORY_ADDRESS,
  [42220 /* CELO */]: V2_FACTORY_ADDRESS,
  [44787 /* CELO_ALFAJORES */]: V2_FACTORY_ADDRESS,
  [10 /* OPTIMISM */]: V2_FACTORY_ADDRESS,
  [420 /* OPTIMISM_GOERLI */]: V2_FACTORY_ADDRESS,
  [42161 /* ARBITRUM_ONE */]: V2_FACTORY_ADDRESS,
  [421613 /* ARBITRUM_GOERLI */]: V2_FACTORY_ADDRESS,
  [109 /* SHIBARIUM */]: V2_FACTORY_ADDRESS,
  [719 /* SHIBARIUM_TESTNET */]: V2_FACTORY_ADDRESS,
  [369 /* PULSECHAIN */]: V2_FACTORY_ADDRESS,
  [943 /* PULSECHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [202212e5 /* XEN */]: V2_FACTORY_ADDRESS,
  [202212 /* XEN_DEVNET */]: V2_FACTORY_ADDRESS,
  [16666e5 /* HARMONY */]: V2_FACTORY_ADDRESS,
  [16667e5 /* HARMONY_TESTNET */]: V2_FACTORY_ADDRESS,
  [52 /* COINEX */]: V2_FACTORY_ADDRESS,
  [53 /* COINEX_TESTNET */]: V2_FACTORY_ADDRESS,
  [2e3 /* DOGECHAIN */]: V2_FACTORY_ADDRESS,
  [568 /* DOGECHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [131 /* ENGRAM_TESTNET */]: V2_FACTORY_ADDRESS,
  [61 /* ETHEREUM_CLASSIC */]: V2_FACTORY_ADDRESS,
  [63 /* ETHEREUM_CLASSIC_TESTNET */]: V2_FACTORY_ADDRESS,
  [32659 /* FUSION */]: V2_FACTORY_ADDRESS,
  [46688 /* FUSION_TESTNET */]: V2_FACTORY_ADDRESS,
  [128 /* HECO */]: V2_FACTORY_ADDRESS,
  [256 /* HECO_TESTNET */]: V2_FACTORY_ADDRESS,
  [321 /* KCC */]: V2_FACTORY_ADDRESS,
  [322 /* KCC_TESTNET */]: V2_FACTORY_ADDRESS,
  [8217 /* KLAYTN */]: V2_FACTORY_ADDRESS,
  [1001 /* KLAYTN_BAOBAB */]: V2_FACTORY_ADDRESS,
  [66 /* OKXCHAIN */]: V2_FACTORY_ADDRESS,
  [65 /* OKXCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [361 /* THETHA */]: V2_FACTORY_ADDRESS,
  [365 /* THETHA_TESTNET */]: V2_FACTORY_ADDRESS,
  [1231 /* ULTRON */]: V2_FACTORY_ADDRESS,
  [1230 /* ULTRON_TESTNET */]: V2_FACTORY_ADDRESS,
  [1284 /* MOONBEAM */]: V2_FACTORY_ADDRESS,
  [1285 /* MOONRIVER */]: V2_FACTORY_ADDRESS,
  [1287 /* MOONBASE_ALPHA */]: V2_FACTORY_ADDRESS,
  [1313161554 /* AURORA */]: V2_FACTORY_ADDRESS,
  [1313161555 /* AURORA_TESTNET */]: V2_FACTORY_ADDRESS,
  [288 /* BOBA */]: V2_FACTORY_ADDRESS,
  [2888 /* BOBA_GOERLI */]: V2_FACTORY_ADDRESS,
  [100 /* GNOSIS */]: V2_FACTORY_ADDRESS,
  [10200 /* GNOSIS_CHIADO */]: V2_FACTORY_ADDRESS,
  [1088 /* METIS */]: V2_FACTORY_ADDRESS,
  [599 /* METIS_GOERLI */]: V2_FACTORY_ADDRESS,
  [40 /* TELOS */]: V2_FACTORY_ADDRESS,
  [41 /* TELOS_TESTNET */]: V2_FACTORY_ADDRESS,
  [57 /* SYSCOIN */]: V2_FACTORY_ADDRESS,
  [5700 /* SYSCOIN_TANENBAUM */]: V2_FACTORY_ADDRESS,
  [4689 /* IOTEX */]: V2_FACTORY_ADDRESS,
  [4690 /* IOTEX_TESTNET */]: V2_FACTORY_ADDRESS,
  [336 /* SHIDEN */]: V2_FACTORY_ADDRESS,
  [592 /* ASTAR */]: V2_FACTORY_ADDRESS,
  [81 /* SHIBUNYA */]: V2_FACTORY_ADDRESS,
  [122 /* FUSE */]: V2_FACTORY_ADDRESS,
  [123 /* FUSE_SPARKNET */]: V2_FACTORY_ADDRESS,
  [106 /* VELAS */]: V2_FACTORY_ADDRESS,
  [111 /* VELAS_TESTNET */]: V2_FACTORY_ADDRESS,
  [55555 /* REI */]: V2_FACTORY_ADDRESS,
  [55556 /* REI_TESTNET */]: V2_FACTORY_ADDRESS,
  [420420 /* KEKCHAIN */]: V2_FACTORY_ADDRESS,
  [420666 /* KEKCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [88 /* TOMOCHAIN */]: V2_FACTORY_ADDRESS,
  [89 /* TOMOCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [108 /* THUNDERCORE */]: V2_FACTORY_ADDRESS,
  [18 /* THUNDERCORE_TESTNET */]: V2_FACTORY_ADDRESS,
  [888 /* WANCHAIN */]: V2_FACTORY_ADDRESS,
  [999 /* WANCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [30 /* RSK */]: V2_FACTORY_ADDRESS,
  [31 /* RSK_TESTNET */]: V2_FACTORY_ADDRESS,
  [20 /* ELASTOS */]: V2_FACTORY_ADDRESS,
  [21 /* ELASTOS_TESTNET */]: V2_FACTORY_ADDRESS,
  [1030 /* CONFLUX */]: V2_FACTORY_ADDRESS,
  [71 /* CONFLUX_TESTNET */]: V2_FACTORY_ADDRESS,
  [32520 /* BRISECHAIN */]: V2_FACTORY_ADDRESS,
  [64668 /* BRISECHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [20402 /* MUUCHAIN */]: V2_FACTORY_ADDRESS,
  [42110 /* MUUCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [7700 /* CANTO */]: V2_FACTORY_ADDRESS,
  [7701 /* CANTO_TESTNET */]: V2_FACTORY_ADDRESS,
  [42262 /* OASIS_EMERALD */]: V2_FACTORY_ADDRESS,
  [42261 /* OASIS_TESTNET */]: V2_FACTORY_ADDRESS,
  [888888 /* VISION */]: V2_FACTORY_ADDRESS,
  [666666 /* VISION_TESTNET */]: V2_FACTORY_ADDRESS,
  [1234 /* STEP */]: V2_FACTORY_ADDRESS,
  [12345 /* STEP_TESTNET */]: V2_FACTORY_ADDRESS,
  [82 /* METER */]: V2_FACTORY_ADDRESS,
  [83 /* METER_TESTNET */]: "0x039ccc90bb0386f595fb2a8763eff620e20d7873",
  [71402 /* GODWOKEN */]: V2_FACTORY_ADDRESS,
  [71401 /* GODWOKEN_TESTNET */]: V2_FACTORY_ADDRESS,
  [820 /* CALLISTO */]: V2_FACTORY_ADDRESS,
  [20729 /* CALLISTO_TESTNET */]: V2_FACTORY_ADDRESS,
  [9001 /* EVMOS */]: V2_FACTORY_ADDRESS,
  [9e3 /* EVMOS_TESTNET */]: V2_FACTORY_ADDRESS,
  [246 /* ENERGY_WEB_CHAIN */]: V2_FACTORY_ADDRESS,
  [73799 /* ENERGY_VOLTA */]: V2_FACTORY_ADDRESS,
  [8453 /* BASE */]: V2_FACTORY_ADDRESS,
  [84531 /* BASE_GOERLI */]: V2_FACTORY_ADDRESS,
  [2222 /* KAVA */]: V2_FACTORY_ADDRESS,
  [2221 /* KAVA_TESTNET */]: V2_FACTORY_ADDRESS,
  [1024 /* CLOVER */]: V2_FACTORY_ADDRESS,
  [1023 /* CLOVER_TESTNET */]: V2_FACTORY_ADDRESS,
  [1131 /* DEFICHAIN */]: V2_FACTORY_ADDRESS,
  [1132 /* DEFICHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [1039 /* BRONOS */]: V2_FACTORY_ADDRESS,
  [1038 /* BRONOS_TESTNET */]: V2_FACTORY_ADDRESS,
  [314 /* FILECOIN */]: V2_FACTORY_ADDRESS,
  [314159 /* FILECOIN_CALIBRATION */]: V2_FACTORY_ADDRESS,
  [14 /* FLARE */]: V2_FACTORY_ADDRESS,
  [114 /* FLARE_TESTNET */]: V2_FACTORY_ADDRESS,
  [841 /* TARAXA */]: V2_FACTORY_ADDRESS,
  [842 /* TARAXA_TESTNET */]: V2_FACTORY_ADDRESS,
  [324 /* ZKSYNC */]: V2_FACTORY_ADDRESS,
  [280 /* ZKSYNC_TESTNET */]: V2_FACTORY_ADDRESS,
  [59144 /* LINEA */]: V2_FACTORY_ADDRESS,
  [59140 /* LINEA_TESTNET */]: "0x6E682B51F8bb67294B522b75a1E79dDd4502cc94",
  [199 /* BTTCHAIN */]: V2_FACTORY_ADDRESS,
  [1029 /* BTTCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [43288 /* BOBA_AVAX */]: V2_FACTORY_ADDRESS,
  [56288 /* BOBA_BNB */]: V2_FACTORY_ADDRESS,
  [256256 /* CMP */]: V2_FACTORY_ADDRESS,
  [512512 /* CMP_TESTNET */]: V2_FACTORY_ADDRESS,
  [1116 /* CORE */]: V2_FACTORY_ADDRESS,
  [1115 /* CORE_TESTNET */]: V2_FACTORY_ADDRESS,
  [1818 /* CUBECHAIN */]: V2_FACTORY_ADDRESS,
  [1819 /* CUBECHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [44 /* DARWINIA_CRAB */]: V2_FACTORY_ADDRESS,
  [43 /* DARWINIA_PANGOLIN */]: V2_FACTORY_ADDRESS,
  [15 /* DIODE_PRETNET */]: V2_FACTORY_ADDRESS,
  [13 /* DIODE_TESTNET */]: V2_FACTORY_ADDRESS,
  [60 /* GOCHAIN */]: V2_FACTORY_ADDRESS,
  [31337 /* GOCHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [11235 /* HAQQ */]: V2_FACTORY_ADDRESS,
  [54211 /* HAQQ_TESTEDGE2 */]: V2_FACTORY_ADDRESS,
  [24 /* KARDIACHAIN */]: V2_FACTORY_ADDRESS,
  [242 /* KARDIACHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [11 /* METADIUM */]: V2_FACTORY_ADDRESS,
  [12 /* METADIUM_TESTNET */]: V2_FACTORY_ADDRESS,
  [204 /* OP_BNB */]: V2_FACTORY_ADDRESS,
  [5611 /* OP_BNB_TESTNET */]: V2_FACTORY_ADDRESS,
  [11297108109 /* PALM */]: V2_FACTORY_ADDRESS,
  [11297108099 /* PALM_TESTNET */]: V2_FACTORY_ADDRESS,
  [333999 /* POLIS */]: V2_FACTORY_ADDRESS,
  [333888 /* POLIS_TESTNET */]: V2_FACTORY_ADDRESS,
  [27 /* SHIBACHAIN */]: V2_FACTORY_ADDRESS,
  [1e4 /* SMART_BCH */]: V2_FACTORY_ADDRESS,
  [10001 /* SMART_BCH_TESTNET */]: V2_FACTORY_ADDRESS,
  [19 /* SONGBIRD_CANARY */]: V2_FACTORY_ADDRESS,
  [7 /* THAICHAIN */]: V2_FACTORY_ADDRESS,
  [8 /* UBIQ */]: V2_FACTORY_ADDRESS,
  [9 /* UBIQ_TESTNET */]: V2_FACTORY_ADDRESS,
  [100009 /* VECHAIN */]: V2_FACTORY_ADDRESS,
  [100010 /* VECHAIN_TESTNET */]: V2_FACTORY_ADDRESS,
  [50 /* XINFIN */]: V2_FACTORY_ADDRESS,
  [51 /* XINFIN_APOTHEM */]: V2_FACTORY_ADDRESS,
  [534352 /* SCROLL */]: V2_FACTORY_ADDRESS,
  [534351 /* SCROLL_SEPOLIA */]: V2_FACTORY_ADDRESS,
  [1440002 /* XRPL_DEVNET */]: V2_FACTORY_ADDRESS,
  [7e3 /* ZETACHAIN */]: V2_FACTORY_ADDRESS,
  [7001 /* ZETACHAIN_ATHENS */]: "0xE99D3D35D1a266997809d84C561c733DcBa2aEBb",
  [55 /* ZYX */]: V2_FACTORY_ADDRESS
};

// src/v2InitCodeHashMap.ts
var V2_INIT_CODE_HASH = "0x9cc00b00da020281dfb209f6a7ee404c6161ab32de6db140d3dbf09efa9b5656";
var V2_INIT_CODE_HASH_MAP = {
  [529 /* FIRECHAIN */]: V2_INIT_CODE_HASH,
  [917 /* RINIA */]: "0xfdbeb923a446e629e039036624e5105df5538af11b084c6cf8f33c1408157fbb",
  [814 /* FIRECHAIN_ZKEVM */]: V2_INIT_CODE_HASH,
  [3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */]: V2_INIT_CODE_HASH,
  [1 /* ETHEREUM */]: V2_INIT_CODE_HASH,
  [5 /* GOERLI */]: V2_INIT_CODE_HASH,
  [11155111 /* SEPOLIA */]: V2_INIT_CODE_HASH,
  [17e3 /* HOLESKY */]: V2_INIT_CODE_HASH,
  [56 /* BSC */]: V2_INIT_CODE_HASH,
  [97 /* BSC_TESTNET */]: V2_INIT_CODE_HASH,
  [137 /* POLYGON */]: V2_INIT_CODE_HASH,
  [80002 /* POLYGON_AMOY */]: V2_INIT_CODE_HASH,
  [1101 /* POLYGON_ZKEVM */]: V2_INIT_CODE_HASH,
  [1442 /* POLYGON_ZKEVM_TESTNET */]: V2_INIT_CODE_HASH,
  [25 /* CRO */]: V2_INIT_CODE_HASH,
  [338 /* CRO_TESTNET */]: V2_INIT_CODE_HASH,
  [43114 /* AVALANCHE */]: V2_INIT_CODE_HASH,
  [43113 /* AVALANCHE_FUJI */]: V2_INIT_CODE_HASH,
  [250 /* FANTOM_OPERA */]: V2_INIT_CODE_HASH,
  [4002 /* FANTOM_TESTNET */]: V2_INIT_CODE_HASH,
  [42220 /* CELO */]: V2_INIT_CODE_HASH,
  [44787 /* CELO_ALFAJORES */]: V2_INIT_CODE_HASH,
  [10 /* OPTIMISM */]: V2_INIT_CODE_HASH,
  [420 /* OPTIMISM_GOERLI */]: V2_INIT_CODE_HASH,
  [42161 /* ARBITRUM_ONE */]: V2_INIT_CODE_HASH,
  [421613 /* ARBITRUM_GOERLI */]: V2_INIT_CODE_HASH,
  [109 /* SHIBARIUM */]: V2_INIT_CODE_HASH,
  [719 /* SHIBARIUM_TESTNET */]: V2_INIT_CODE_HASH,
  [369 /* PULSECHAIN */]: V2_INIT_CODE_HASH,
  [943 /* PULSECHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [202212e5 /* XEN */]: V2_INIT_CODE_HASH,
  [202212 /* XEN_DEVNET */]: V2_INIT_CODE_HASH,
  [16666e5 /* HARMONY */]: V2_INIT_CODE_HASH,
  [16667e5 /* HARMONY_TESTNET */]: V2_INIT_CODE_HASH,
  [52 /* COINEX */]: V2_INIT_CODE_HASH,
  [53 /* COINEX_TESTNET */]: V2_INIT_CODE_HASH,
  [2e3 /* DOGECHAIN */]: V2_INIT_CODE_HASH,
  [568 /* DOGECHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [131 /* ENGRAM_TESTNET */]: V2_INIT_CODE_HASH,
  [61 /* ETHEREUM_CLASSIC */]: V2_INIT_CODE_HASH,
  [63 /* ETHEREUM_CLASSIC_TESTNET */]: V2_INIT_CODE_HASH,
  [32659 /* FUSION */]: V2_INIT_CODE_HASH,
  [46688 /* FUSION_TESTNET */]: V2_INIT_CODE_HASH,
  [128 /* HECO */]: V2_INIT_CODE_HASH,
  [256 /* HECO_TESTNET */]: V2_INIT_CODE_HASH,
  [321 /* KCC */]: V2_INIT_CODE_HASH,
  [322 /* KCC_TESTNET */]: V2_INIT_CODE_HASH,
  [8217 /* KLAYTN */]: V2_INIT_CODE_HASH,
  [1001 /* KLAYTN_BAOBAB */]: V2_INIT_CODE_HASH,
  [66 /* OKXCHAIN */]: V2_INIT_CODE_HASH,
  [65 /* OKXCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [361 /* THETHA */]: V2_INIT_CODE_HASH,
  [365 /* THETHA_TESTNET */]: V2_INIT_CODE_HASH,
  [1231 /* ULTRON */]: V2_INIT_CODE_HASH,
  [1230 /* ULTRON_TESTNET */]: V2_INIT_CODE_HASH,
  [1284 /* MOONBEAM */]: V2_INIT_CODE_HASH,
  [1285 /* MOONRIVER */]: V2_INIT_CODE_HASH,
  [1287 /* MOONBASE_ALPHA */]: V2_INIT_CODE_HASH,
  [1313161554 /* AURORA */]: V2_INIT_CODE_HASH,
  [1313161555 /* AURORA_TESTNET */]: V2_INIT_CODE_HASH,
  [288 /* BOBA */]: V2_INIT_CODE_HASH,
  [2888 /* BOBA_GOERLI */]: V2_INIT_CODE_HASH,
  [100 /* GNOSIS */]: V2_INIT_CODE_HASH,
  [10200 /* GNOSIS_CHIADO */]: V2_INIT_CODE_HASH,
  [1088 /* METIS */]: V2_INIT_CODE_HASH,
  [599 /* METIS_GOERLI */]: V2_INIT_CODE_HASH,
  [40 /* TELOS */]: V2_INIT_CODE_HASH,
  [41 /* TELOS_TESTNET */]: V2_INIT_CODE_HASH,
  [57 /* SYSCOIN */]: V2_INIT_CODE_HASH,
  [5700 /* SYSCOIN_TANENBAUM */]: V2_INIT_CODE_HASH,
  [4689 /* IOTEX */]: V2_INIT_CODE_HASH,
  [4690 /* IOTEX_TESTNET */]: V2_INIT_CODE_HASH,
  [336 /* SHIDEN */]: V2_INIT_CODE_HASH,
  [592 /* ASTAR */]: V2_INIT_CODE_HASH,
  [81 /* SHIBUNYA */]: V2_INIT_CODE_HASH,
  [122 /* FUSE */]: V2_INIT_CODE_HASH,
  [123 /* FUSE_SPARKNET */]: V2_INIT_CODE_HASH,
  [106 /* VELAS */]: V2_INIT_CODE_HASH,
  [111 /* VELAS_TESTNET */]: V2_INIT_CODE_HASH,
  [55555 /* REI */]: V2_INIT_CODE_HASH,
  [55556 /* REI_TESTNET */]: V2_INIT_CODE_HASH,
  [420420 /* KEKCHAIN */]: V2_INIT_CODE_HASH,
  [420666 /* KEKCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [88 /* TOMOCHAIN */]: V2_INIT_CODE_HASH,
  [89 /* TOMOCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [108 /* THUNDERCORE */]: V2_INIT_CODE_HASH,
  [18 /* THUNDERCORE_TESTNET */]: V2_INIT_CODE_HASH,
  [888 /* WANCHAIN */]: V2_INIT_CODE_HASH,
  [999 /* WANCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [30 /* RSK */]: V2_INIT_CODE_HASH,
  [31 /* RSK_TESTNET */]: V2_INIT_CODE_HASH,
  [20 /* ELASTOS */]: V2_INIT_CODE_HASH,
  [21 /* ELASTOS_TESTNET */]: V2_INIT_CODE_HASH,
  [1030 /* CONFLUX */]: V2_INIT_CODE_HASH,
  [71 /* CONFLUX_TESTNET */]: V2_INIT_CODE_HASH,
  [32520 /* BRISECHAIN */]: V2_INIT_CODE_HASH,
  [64668 /* BRISECHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [20402 /* MUUCHAIN */]: V2_INIT_CODE_HASH,
  [42110 /* MUUCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [7700 /* CANTO */]: V2_INIT_CODE_HASH,
  [7701 /* CANTO_TESTNET */]: V2_INIT_CODE_HASH,
  [42262 /* OASIS_EMERALD */]: V2_INIT_CODE_HASH,
  [42261 /* OASIS_TESTNET */]: V2_INIT_CODE_HASH,
  [888888 /* VISION */]: V2_INIT_CODE_HASH,
  [666666 /* VISION_TESTNET */]: V2_INIT_CODE_HASH,
  [1234 /* STEP */]: V2_INIT_CODE_HASH,
  [12345 /* STEP_TESTNET */]: V2_INIT_CODE_HASH,
  [82 /* METER */]: V2_INIT_CODE_HASH,
  [83 /* METER_TESTNET */]: V2_INIT_CODE_HASH,
  [71402 /* GODWOKEN */]: V2_INIT_CODE_HASH,
  [71401 /* GODWOKEN_TESTNET */]: V2_INIT_CODE_HASH,
  [820 /* CALLISTO */]: V2_INIT_CODE_HASH,
  [20729 /* CALLISTO_TESTNET */]: V2_INIT_CODE_HASH,
  [9001 /* EVMOS */]: V2_INIT_CODE_HASH,
  [9e3 /* EVMOS_TESTNET */]: V2_INIT_CODE_HASH,
  [246 /* ENERGY_WEB_CHAIN */]: V2_INIT_CODE_HASH,
  [73799 /* ENERGY_VOLTA */]: V2_INIT_CODE_HASH,
  [8453 /* BASE */]: V2_INIT_CODE_HASH,
  [84531 /* BASE_GOERLI */]: V2_INIT_CODE_HASH,
  [2222 /* KAVA */]: V2_INIT_CODE_HASH,
  [2221 /* KAVA_TESTNET */]: V2_INIT_CODE_HASH,
  [1024 /* CLOVER */]: V2_INIT_CODE_HASH,
  [1023 /* CLOVER_TESTNET */]: V2_INIT_CODE_HASH,
  [1131 /* DEFICHAIN */]: V2_INIT_CODE_HASH,
  [1132 /* DEFICHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [1039 /* BRONOS */]: V2_INIT_CODE_HASH,
  [1038 /* BRONOS_TESTNET */]: V2_INIT_CODE_HASH,
  [314 /* FILECOIN */]: V2_INIT_CODE_HASH,
  [314159 /* FILECOIN_CALIBRATION */]: V2_INIT_CODE_HASH,
  [14 /* FLARE */]: V2_INIT_CODE_HASH,
  [114 /* FLARE_TESTNET */]: V2_INIT_CODE_HASH,
  [841 /* TARAXA */]: V2_INIT_CODE_HASH,
  [842 /* TARAXA_TESTNET */]: V2_INIT_CODE_HASH,
  [324 /* ZKSYNC */]: V2_INIT_CODE_HASH,
  [280 /* ZKSYNC_TESTNET */]: V2_INIT_CODE_HASH,
  [59144 /* LINEA */]: V2_INIT_CODE_HASH,
  [59140 /* LINEA_TESTNET */]: V2_INIT_CODE_HASH,
  [199 /* BTTCHAIN */]: V2_INIT_CODE_HASH,
  [1029 /* BTTCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [43288 /* BOBA_AVAX */]: V2_INIT_CODE_HASH,
  [56288 /* BOBA_BNB */]: V2_INIT_CODE_HASH,
  [256256 /* CMP */]: V2_INIT_CODE_HASH,
  [512512 /* CMP_TESTNET */]: V2_INIT_CODE_HASH,
  [1116 /* CORE */]: V2_INIT_CODE_HASH,
  [1115 /* CORE_TESTNET */]: V2_INIT_CODE_HASH,
  [1818 /* CUBECHAIN */]: V2_INIT_CODE_HASH,
  [1819 /* CUBECHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [44 /* DARWINIA_CRAB */]: V2_INIT_CODE_HASH,
  [43 /* DARWINIA_PANGOLIN */]: V2_INIT_CODE_HASH,
  [15 /* DIODE_PRETNET */]: V2_INIT_CODE_HASH,
  [13 /* DIODE_TESTNET */]: V2_INIT_CODE_HASH,
  [60 /* GOCHAIN */]: V2_INIT_CODE_HASH,
  [31337 /* GOCHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [11235 /* HAQQ */]: V2_INIT_CODE_HASH,
  [54211 /* HAQQ_TESTEDGE2 */]: V2_INIT_CODE_HASH,
  [24 /* KARDIACHAIN */]: V2_INIT_CODE_HASH,
  [242 /* KARDIACHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [11 /* METADIUM */]: V2_INIT_CODE_HASH,
  [12 /* METADIUM_TESTNET */]: V2_INIT_CODE_HASH,
  [204 /* OP_BNB */]: V2_INIT_CODE_HASH,
  [5611 /* OP_BNB_TESTNET */]: V2_INIT_CODE_HASH,
  [11297108109 /* PALM */]: V2_INIT_CODE_HASH,
  [11297108099 /* PALM_TESTNET */]: V2_INIT_CODE_HASH,
  [333999 /* POLIS */]: V2_INIT_CODE_HASH,
  [333888 /* POLIS_TESTNET */]: V2_INIT_CODE_HASH,
  [27 /* SHIBACHAIN */]: V2_INIT_CODE_HASH,
  [1e4 /* SMART_BCH */]: V2_INIT_CODE_HASH,
  [10001 /* SMART_BCH_TESTNET */]: V2_INIT_CODE_HASH,
  [19 /* SONGBIRD_CANARY */]: V2_INIT_CODE_HASH,
  [7 /* THAICHAIN */]: V2_INIT_CODE_HASH,
  [8 /* UBIQ */]: V2_INIT_CODE_HASH,
  [9 /* UBIQ_TESTNET */]: V2_INIT_CODE_HASH,
  [100009 /* VECHAIN */]: V2_INIT_CODE_HASH,
  [100010 /* VECHAIN_TESTNET */]: V2_INIT_CODE_HASH,
  [50 /* XINFIN */]: V2_INIT_CODE_HASH,
  [51 /* XINFIN_APOTHEM */]: V2_INIT_CODE_HASH,
  [534352 /* SCROLL */]: V2_INIT_CODE_HASH,
  [534351 /* SCROLL_SEPOLIA */]: V2_INIT_CODE_HASH,
  [1440002 /* XRPL_DEVNET */]: V2_INIT_CODE_HASH,
  [7e3 /* ZETACHAIN */]: V2_INIT_CODE_HASH,
  [7001 /* ZETACHAIN_ATHENS */]: V2_INIT_CODE_HASH,
  [55 /* ZYX */]: V2_INIT_CODE_HASH
};

// src/native.ts
var ETHER = {
  bridge: "https://www.portalbridge.com/#/transfer",
  faucet: "",
  docs: "",
  label: "Ethereum",
  name: "Ether",
  symbol: "ETH",
  decimals: 18
};
var NATIVE = {
  [529 /* FIRECHAIN */]: {
    bridge: "https://www.bridge.thefirechain.com",
    faucet: "",
    docs: "",
    label: "Firechain",
    name: "Firechain",
    symbol: "FIRE",
    decimals: 18
  },
  [917 /* RINIA */]: {
    bridge: "https://www.bridge-rinia.firestation.io",
    faucet: "https://faucet.firestation.io",
    docs: "https://www.docs.thefirechain.com",
    label: "Rinia",
    name: "Firechain",
    symbol: "tFIRE",
    decimals: 18
  },
  [814 /* FIRECHAIN_ZKEVM */]: {
    bridge: "https://www.bridge-zkevm.firestation.io",
    faucet: "",
    docs: "",
    label: "Firechain Zkevm",
    name: "Ethereum Native Token",
    symbol: "ETH",
    decimals: 18
  },
  [3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */]: {
    bridge: "https://www.bridge-ghostrider-zkevm.firestation.io",
    faucet: "https://ghostrider-faucet.firestation.io",
    docs: "",
    label: "Firechain Zkevm",
    name: "Ethereum Native Token",
    symbol: "tETH",
    decimals: 18
  },
  [1 /* ETHEREUM */]: ETHER,
  [5 /* GOERLI */]: {
    bridge: "https://www.portalbridge.com/#/transfer",
    faucet: "https://goerlifaucet.com/",
    docs: "",
    label: "Goerli",
    name: "Goerli Ether",
    symbol: "tETH",
    decimals: 18
  },
  [11155111 /* SEPOLIA */]: {
    bridge: "https://www.portalbridge.com/#/transfer",
    faucet: "https://sepoliafaucet.com/",
    docs: "",
    label: "Sepolia",
    name: "Sepolia Ether",
    symbol: "tETH",
    decimals: 18
  },
  [17e3 /* HOLESKY */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Holesky",
    name: "Holesky Ether",
    symbol: "tETH",
    decimals: 18
  },
  [56 /* BSC */]: {
    bridge: "https://www.bnbchain.org/en/bridge",
    faucet: "",
    docs: "",
    label: "Binance",
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
  },
  [97 /* BSC_TESTNET */]: {
    bridge: "https://www.bnbchain.org/en/bridge",
    faucet: "https://testnet.bnbchain.org/faucet-smart",
    docs: "",
    label: "Binance Testnet",
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
  },
  [137 /* POLYGON */]: {
    bridge: "https://wallet.polygon.technology/bridge",
    faucet: "",
    docs: "",
    label: "Polygon",
    name: "Polygon Native Token",
    symbol: "MATIC",
    decimals: 18
  },
  [80002 /* POLYGON_AMOY */]: {
    bridge: "https://wallet.polygon.technology/login",
    faucet: "https://faucet.polygon.technology/",
    docs: "https://wiki.polygon.technology/",
    label: "Polygon Amoy",
    name: "Polygon Native Token",
    symbol: "tMATIC",
    decimals: 18
  },
  [25 /* CRO */]: {
    bridge: "https://cronos.org/bridge/",
    faucet: "",
    docs: "",
    label: "Cronos",
    name: "Cronos Native Token",
    symbol: "CRO",
    decimals: 18
  },
  [338 /* CRO_TESTNET */]: {
    bridge: "https://cronos.org/bridge/",
    faucet: "https://cronos.org/faucet",
    docs: "",
    label: "Cronos Testnet",
    name: "Cronos Native Token",
    symbol: "tCRO",
    decimals: 18
  },
  [43114 /* AVALANCHE */]: {
    bridge: "https://core.app/bridge/",
    faucet: "",
    docs: "",
    label: "Avalanche",
    name: "Avalanche Native Token",
    symbol: "AVAX",
    decimals: 18
  },
  [43113 /* AVALANCHE_FUJI */]: {
    bridge: "https://core.app/bridge/",
    faucet: "https://faucet.quicknode.com/avalanche/fuji",
    docs: "https://docs.avax.network/",
    label: "Avalanche Fuji",
    name: "Avalanche Native Token",
    symbol: "tAVAX",
    decimals: 18
  },
  [250 /* FANTOM_OPERA */]: {
    bridge: "https://app.multichain.org/#/router",
    faucet: "",
    docs: "",
    label: "Fantom Opera",
    name: "Fantom Native Token",
    symbol: "FTM",
    decimals: 18
  },
  [4002 /* FANTOM_TESTNET */]: {
    bridge: "https://app.multichain.org/#/router",
    faucet: "https://faucet.fantom.network/",
    docs: "https://docs.fantom.foundation/",
    label: "Fantom Testnet",
    name: "Fantom Native Token",
    symbol: "tFTM",
    decimals: 18
  },
  [42220 /* CELO */]: {
    bridge: "https://www.portalbridge.com/#/transfer",
    faucet: "",
    docs: "",
    label: "Celo",
    name: "Celo Native Token",
    symbol: "CELO",
    decimals: 18
  },
  [44787 /* CELO_ALFAJORES */]: {
    bridge: "https://www.portalbridge.com/#/transfer",
    faucet: "https://faucet.celo.org/alfajores",
    docs: "https://docs.celo.org/",
    label: "Celo Alfajores",
    name: "Celo Native Token",
    symbol: "tCELO",
    decimals: 18
  },
  [10 /* OPTIMISM */]: {
    bridge: "https://app.optimism.io/bridge/deposit",
    faucet: "",
    docs: "",
    label: "Optimism",
    name: "Optimism Native Token",
    symbol: "ETH",
    decimals: 18
  },
  [420 /* OPTIMISM_GOERLI */]: {
    bridge: "https://app.optimism.io/bridge/deposit",
    faucet: "https://faucet.quicknode.com/optimism/goerli",
    docs: "https://community.optimism.io/",
    label: "Optimism Goerli",
    name: "Optimism Native Token",
    symbol: "tETH",
    decimals: 18
  },
  [109 /* SHIBARIUM */]: {
    bridge: "https://shibarium.shib.io/bridge",
    faucet: "",
    docs: "",
    label: "Shibarium",
    name: "Shibarium Native Token",
    symbol: "BONE",
    decimals: 18
  },
  [719 /* SHIBARIUM_TESTNET */]: {
    bridge: "https://shibarium.shib.io/bridge",
    faucet: "https://beta.shibariumtech.com/faucet",
    docs: "",
    label: "Shibarium Testnet",
    name: "Shibarium Native Token",
    symbol: "tBONE",
    decimals: 18
  },
  [369 /* PULSECHAIN */]: {
    bridge: "https://pulseramp.com/#/",
    faucet: "",
    docs: "",
    label: "Pulsechain",
    name: "Pulsechain Native Token",
    symbol: "PLS",
    decimals: 18
  },
  [943 /* PULSECHAIN_TESTNET */]: {
    bridge: "https://pulseramp.com/#/",
    faucet: "https://faucet.v4.testnet.pulsechain.com/",
    docs: "",
    label: "Pulsechain Testnet",
    name: "Pulsechain Native Token",
    symbol: "tPLS",
    decimals: 18
  },
  [202212e5 /* XEN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Xen",
    name: "Xen Native Token",
    symbol: "XN",
    decimals: 18
  },
  [202212 /* XEN_DEVNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Xen Devnet",
    name: "Xen Native Token",
    symbol: "tXN",
    decimals: 18
  },
  [16666e5 /* HARMONY */]: {
    bridge: "https://harmony-app.pro",
    faucet: "",
    docs: "",
    label: "Harmony",
    name: "Harmony Native Token",
    symbol: "ONE",
    decimals: 18
  },
  [16667e5 /* HARMONY_TESTNET */]: {
    bridge: "https://harmony-app.pro",
    faucet: "https://faucet.pops.one/",
    docs: "",
    label: "Harmony Testnet",
    name: "Harmony Native Token",
    symbol: "tONE",
    decimals: 18
  },
  [52 /* COINEX */]: {
    bridge: "https://bridge.coinex.net/",
    faucet: "https://testnet.coinex.net/faucet",
    docs: "",
    label: "CoineEx",
    name: "CoinEx Native Token",
    symbol: "CET",
    decimals: 18
  },
  [53 /* COINEX_TESTNET */]: {
    bridge: "https://bridge.coinex.net/",
    faucet: "",
    docs: "",
    label: "CoinEx Testnet",
    name: "CoinEx Native Token",
    symbol: "tCET",
    decimals: 18
  },
  [2e3 /* DOGECHAIN */]: {
    bridge: "https://bridge.dogechain.dog/bridge",
    faucet: "",
    docs: "",
    label: "Dogechain",
    name: "Dogechain Native Token",
    symbol: "DOGE",
    decimals: 18
  },
  [568 /* DOGECHAIN_TESTNET */]: {
    bridge: "https://bridge.dogechain.dog/bridge",
    faucet: "https://faucet.dogechain.dog/",
    docs: "",
    label: "Dogechain Testnet",
    name: "Dogechain Native Token",
    symbol: "tDOGE",
    decimals: 18
  },
  [131 /* ENGRAM_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Engram Testnet",
    name: "Engram Native Token",
    symbol: "tGRAM",
    decimals: 18
  },
  [61 /* ETHEREUM_CLASSIC */]: {
    bridge: "",
    faucet: "https://easy.hebeswap.com/#/faucet",
    docs: "",
    label: "Eth Classic",
    name: "Ethereum Classic Native Token",
    symbol: "ETC",
    decimals: 18
  },
  [63 /* ETHEREUM_CLASSIC_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Eth Classic Mordor",
    name: "Ethereum Classic Native Token",
    symbol: "tETC",
    decimals: 18
  },
  [32659 /* FUSION */]: {
    bridge: "https://dapp.chainge.finance/?fromChain=FSN",
    faucet: "",
    docs: "",
    label: "Fusion",
    name: "Fusion Native Token",
    symbol: "FSN",
    decimals: 18
  },
  [46688 /* FUSION_TESTNET */]: {
    bridge: "https://dapp.chainge.finance/?fromChain=FSN",
    faucet: "",
    docs: "https://fusiondev.gitbook.io/fusion/build/quick-links",
    label: "Fusion Testnet",
    name: "Fusion Native Token",
    symbol: "tFSN",
    decimals: 18
  },
  [128 /* HECO */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Heco",
    name: "Heco Native Token",
    symbol: "HT",
    decimals: 18
  },
  [256 /* HECO_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Heco Testnet",
    name: "Heco Native Token",
    symbol: "tHT",
    decimals: 18
  },
  [321 /* KCC */]: {
    bridge: "https://bridge.kccpad.io/",
    faucet: "",
    docs: "",
    label: "Kcc",
    name: "KCC Native Token",
    symbol: "KCS",
    decimals: 18
  },
  [322 /* KCC_TESTNET */]: {
    bridge: "https://bridge.kccpad.io/",
    faucet: "",
    docs: "",
    label: "Kcc Testnet",
    name: "KCC Native Token",
    symbol: "tKCS",
    decimals: 18
  },
  [8217 /* KLAYTN */]: {
    bridge: "https://scope.klaytn.com/bridge",
    faucet: "",
    docs: "",
    label: "Klaytn",
    name: "Klaytn Native Token",
    symbol: "KLAY",
    decimals: 18
  },
  [1001 /* KLAYTN_BAOBAB */]: {
    bridge: "https://scope.klaytn.com/bridge",
    faucet: "",
    docs: "",
    label: "Klaytn Baobab",
    name: "Klaytn Native Token",
    symbol: "tKLAY",
    decimals: 18
  },
  [66 /* OKXCHAIN */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Okxchain",
    name: "OkxChain Native Token",
    symbol: "OKT",
    decimals: 18
  },
  [65 /* OKXCHAIN_TESTNET */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "okxchain Testnet",
    name: "OkxChain Native Token",
    symbol: "tOKT",
    decimals: 18
  },
  [361 /* THETHA */]: {
    bridge: "https://thetabridge.io/bridge",
    faucet: "",
    docs: "",
    label: "Theta",
    name: "Theta Native Token",
    symbol: "TFEUL",
    decimals: 18
  },
  [365 /* THETHA_TESTNET */]: {
    bridge: "https://thetabridge.io/bridge",
    faucet: "",
    docs: "",
    label: "Theta Testnet",
    name: "Theta Native Token",
    symbol: "tTFEUL",
    decimals: 18
  },
  [1231 /* ULTRON */]: {
    bridge: "https://ultronswap.com/bridge",
    faucet: "",
    docs: "",
    label: "Ultron",
    name: "Ultron Native Token",
    symbol: "ULX",
    decimals: 18
  },
  [1230 /* ULTRON_TESTNET */]: {
    bridge: "https://ultronswap.com/bridge",
    faucet: "",
    docs: "",
    label: "Ultron Testnet",
    name: "Ultron Native Token",
    symbol: "tULX",
    decimals: 18
  },
  [1284 /* MOONBEAM */]: {
    bridge: "https://apps.moonbeam.network/moonbeam/xcm",
    faucet: "",
    docs: "",
    label: "Moonbeam",
    name: "Moonbeam Native Token",
    symbol: "ULX",
    decimals: 18
  },
  [1285 /* MOONRIVER */]: {
    bridge: "https://apps.moonbeam.network/moonbeam/xcm",
    faucet: "",
    docs: "",
    label: "Moonriver",
    name: "Moonriver Native Token",
    symbol: "MOVR",
    decimals: 18
  },
  [1287 /* MOONBASE_ALPHA */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Moonbase Alpha",
    name: " Moonbase Native Token",
    symbol: "DEV",
    decimals: 18
  },
  [1313161554 /* AURORA */]: {
    bridge: "https://rainbowbridge.app/transfer",
    faucet: "",
    docs: "",
    label: "Aurora",
    name: "Aurora Native Token",
    symbol: "ETH",
    decimals: 18
  },
  [1313161555 /* AURORA_TESTNET */]: {
    bridge: "https://rainbowbridge.app/transfer",
    faucet: "https://aurora.dev/faucet",
    docs: "https://doc.aurora.dev/",
    label: "Aurora Testnet",
    name: " Aurora Native Token",
    symbol: "tETH",
    decimals: 18
  },
  [288 /* BOBA */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Boba",
    name: "Boba Native Token",
    symbol: "ULX",
    decimals: 18
  },
  [2888 /* BOBA_GOERLI */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Boba Goerli",
    name: " Boba Native Token",
    symbol: "tETH",
    decimals: 18
  },
  [100 /* GNOSIS */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Gnosis",
    name: "Gnosis Native Token",
    symbol: "xDAI",
    decimals: 18
  },
  [10200 /* GNOSIS_CHIADO */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "http://www.gnosisfaucet.com/#need-more",
    docs: "",
    label: "Gnosis Chiado",
    name: "Gnosis Native Token",
    symbol: "txDAI",
    decimals: 18
  },
  [1088 /* METIS */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Metis",
    name: "Metis Native Token",
    symbol: "METIS",
    decimals: 18
  },
  [599 /* METIS_GOERLI */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "https://goerli.faucet.metisdevops.link/",
    docs: "",
    label: "Metis Goerli",
    name: "Metis Native Token",
    symbol: "tMETIS",
    decimals: 18
  },
  [40 /* TELOS */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Telos",
    name: "Telos Native Token",
    symbol: "TLOS",
    decimals: 18
  },
  [41 /* TELOS_TESTNET */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "https://app.telos.net/testnet/developers",
    docs: "",
    label: "Telos Testnet",
    name: " Telos Native Token",
    symbol: "tTLOS",
    decimals: 18
  },
  [57 /* SYSCOIN */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Syscoin",
    name: "Syscoin Native Token",
    symbol: "SYS",
    decimals: 18
  },
  [5700 /* SYSCOIN_TANENBAUM */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Syscoin Tanenbaum",
    name: "Syscoin Native Token",
    symbol: "tSYS",
    decimals: 18
  },
  [4689 /* IOTEX */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "IoTex",
    name: "Iotex Native Token",
    symbol: "IOTX",
    decimals: 18
  },
  [4690 /* IOTEX_TESTNET */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "https://developers.iotex.io/dev-tools?tool=public-faucet",
    docs: "",
    label: "IoTex Testnet",
    name: "Iotex Native Token",
    symbol: "tIOTX",
    decimals: 18
  },
  [336 /* SHIDEN */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Shiden",
    name: "Shiden Native Token",
    symbol: "SDN",
    decimals: 18
  },
  [592 /* ASTAR */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Astar",
    name: "Astar Native Token",
    symbol: "ASTR",
    decimals: 18
  },
  [81 /* SHIBUNYA */]: {
    bridge: "",
    faucet: "https://portal.astar.network/shibuya-testnet/dashboard",
    docs: "https://docs.astar.network/",
    label: "Shibunya",
    name: "Shibunya Native Token",
    symbol: "SBY",
    decimals: 18
  },
  [122 /* FUSE */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Fuse",
    name: "Fuse Native Token",
    symbol: "FUSE",
    decimals: 18
  },
  [123 /* FUSE_SPARKNET */]: {
    bridge: "",
    faucet: "https://chaindrop.org/?chainid=123&token=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    docs: "",
    label: "Fuse Sparknet",
    name: "Fuse Native Token",
    symbol: "tFUSE",
    decimals: 18
  },
  [106 /* VELAS */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Velas",
    name: "Velas Native Token",
    symbol: "VLX",
    decimals: 18
  },
  [111 /* VELAS_TESTNET */]: {
    bridge: "",
    faucet: "https://docs.velas.com/evm/airdrop/",
    docs: "",
    label: "Velas Testnet",
    name: "Velas Native Token",
    symbol: "tVLX",
    decimals: 18
  },
  [55555 /* REI */]: {
    bridge: "https://anyswap.exchange/#/bridge",
    faucet: "",
    docs: "",
    label: "Rei Chain",
    name: "Rei Native Token",
    symbol: "REI",
    decimals: 18
  },
  [55556 /* REI_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet.rei.network/#/",
    docs: "",
    label: "Rei Chain Testnet",
    name: "Rei Native Token",
    symbol: "tREI",
    decimals: 18
  },
  [420420 /* KEKCHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Kekchain",
    name: "Kekchain Native Token",
    symbol: "KEK",
    decimals: 18
  },
  [420666 /* KEKCHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Kekchain Testnet",
    name: "Kekchain Native Token",
    symbol: "tKEK",
    decimals: 18
  },
  [88 /* TOMOCHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Tomochain",
    name: "Tomochain Native Token",
    symbol: "TOMO",
    decimals: 18
  },
  [89 /* TOMOCHAIN_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet.testnet.tomochain.com/",
    docs: "",
    label: "Tomochain Testnet",
    name: "Tomochain Native Token",
    symbol: "tTOMO",
    decimals: 18
  },
  [108 /* THUNDERCORE */]: {
    bridge: "https://bridge.thundercore.com/",
    faucet: "",
    docs: "",
    label: "Thundercore",
    name: "Thundercore Native Token",
    symbol: "TT",
    decimals: 18
  },
  [18 /* THUNDERCORE_TESTNET */]: {
    bridge: "https://bridge.thundercore.com/",
    faucet: "https://faucet-testnet.thundercore.com/",
    docs: "https://docs.developers.thundercore.com/",
    label: "Thundercore Testnet",
    name: "Thundercore Native Token",
    symbol: "TST",
    decimals: 18
  },
  [888 /* WANCHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Wanchain",
    name: "Wanchain Native Token",
    symbol: "WAN",
    decimals: 18
  },
  [999 /* WANCHAIN_TESTNET */]: {
    bridge: "",
    faucet: "https://wanchain-faucet.vercel.app/",
    docs: "",
    label: "Wanchain Testnet",
    name: "Wanchain Native Token",
    symbol: "tWAN",
    decimals: 18
  },
  [30 /* RSK */]: {
    bridge: "https://tokenbridge.rsk.co/",
    faucet: "",
    docs: "",
    label: "Rsk",
    name: "RSK Native Token",
    symbol: "RBTC",
    decimals: 18
  },
  [31 /* RSK_TESTNET */]: {
    bridge: "https://tokenbridge.rsk.co/",
    faucet: "https://faucet.rsk.co/",
    docs: "",
    label: "Rsk Testnet",
    name: "RSK Native Token",
    symbol: "tRBTC",
    decimals: 18
  },
  [20 /* ELASTOS */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Elastos",
    name: "Elastos Native Token",
    symbol: "ELA",
    decimals: 18
  },
  [21 /* ELASTOS_TESTNET */]: {
    bridge: "",
    faucet: "https://esc-faucet.elastos.io/",
    docs: "",
    label: "Elastos Testnet",
    name: "Elastos Native Token",
    symbol: "tELA",
    decimals: 18
  },
  [1030 /* CONFLUX */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Conflux",
    name: "Conflux Native Token",
    symbol: "CFX",
    decimals: 18
  },
  [71 /* CONFLUX_TESTNET */]: {
    bridge: "",
    faucet: "https://efaucet.confluxnetwork.org/",
    docs: "",
    label: "Conflux Testnet",
    name: "Conflux Native Token",
    symbol: "tCFX",
    decimals: 18
  },
  [32520 /* BRISECHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Brisechain",
    name: "Brisechain Native Token",
    symbol: "BRISE",
    decimals: 18
  },
  [64668 /* BRISECHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Brisechain Testnet",
    name: "Brisechain Native Token",
    symbol: "tBRISE",
    decimals: 18
  },
  [20402 /* MUUCHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Muuchain",
    name: "Muuchain Native Token",
    symbol: "MUU",
    decimals: 18
  },
  [42110 /* MUUCHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Muuchain Testnet",
    name: "Muuchain Native Token",
    symbol: "tMUU",
    decimals: 18
  },
  [7700 /* CANTO */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Canto",
    name: "Canto Native Token",
    symbol: "CANTO",
    decimals: 18
  },
  [7701 /* CANTO_TESTNET */]: {
    bridge: "",
    faucet: "https://www.cantofaucet.com/",
    docs: "",
    label: "Canto Testnet",
    name: "Canto Native Token",
    symbol: "tCANTO",
    decimals: 18
  },
  [42262 /* OASIS_EMERALD */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Oasis Emerald",
    name: "Oasis Native Token",
    symbol: "ROSE",
    decimals: 18
  },
  [42261 /* OASIS_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet.testnet.oasis.dev/?",
    docs: "",
    label: "Oasis Testnet",
    name: "Oasis Native Token",
    symbol: "tROSE",
    decimals: 18
  },
  [888888 /* VISION */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Vision",
    name: "Vision Native Token",
    symbol: "VS",
    decimals: 18
  },
  [666666 /* VISION_TESTNET */]: {
    bridge: "",
    faucet: "https://vpioneerfaucet.visionscan.org/#/",
    docs: "",
    label: "Vision Testnet",
    name: "Vision Native Token",
    symbol: "tVS",
    decimals: 18
  },
  [1234 /* STEP */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Step",
    name: "Step Native Token",
    symbol: "FITFI",
    decimals: 18
  },
  [12345 /* STEP_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet.step.network/",
    docs: "",
    label: "Step Testnet",
    name: "Step Native Token",
    symbol: "tFITFI",
    decimals: 18
  },
  [82 /* METER */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Meter",
    name: "Meter Native Token",
    symbol: "MTR",
    decimals: 18
  },
  [83 /* METER_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet-warringstakes.meter.io/",
    docs: "",
    label: "Meter Testnet",
    name: "Meter Native Token",
    symbol: "tMTR",
    decimals: 18
  },
  [71402 /* GODWOKEN */]: {
    bridge: "",
    faucet: "https://faucet-testnet.godwoken.io/",
    docs: "",
    label: "Godwoken",
    name: "Godwoken Native Token",
    symbol: "pCKB",
    decimals: 18
  },
  [71401 /* GODWOKEN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Godwoken Testnet",
    name: "Godwoken Native Token",
    symbol: "tpCKB",
    decimals: 18
  },
  [820 /* CALLISTO */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Callisto",
    name: "Callisto Native Token",
    symbol: "CLO",
    decimals: 18
  },
  [20729 /* CALLISTO_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet.callisto.network/",
    docs: "",
    label: "Callisto Testnet",
    name: "Callisto Native Token",
    symbol: "tCLO",
    decimals: 18
  },
  [9001 /* EVMOS */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Evmos",
    name: "Evmos Native Token",
    symbol: "EVMOS",
    decimals: 18
  },
  [9e3 /* EVMOS_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Evmos Testnet",
    name: "Evmos Native Token",
    symbol: "tEVMOS",
    decimals: 18
  },
  [246 /* ENERGY_WEB_CHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Energy Webchain",
    name: "Energy Native Token",
    symbol: "EWT",
    decimals: 18
  },
  [73799 /* ENERGY_VOLTA */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Energy Volta",
    name: "Energy Native Token",
    symbol: "VT",
    decimals: 18
  },
  [8453 /* BASE */]: {
    bridge: "https://bridge.base.org/deposit",
    faucet: "",
    docs: "",
    label: "Base Mainnet",
    name: "Ethereum Native Token",
    symbol: "ETH",
    decimals: 18
  },
  [84531 /* BASE_GOERLI */]: {
    bridge: "https://bridge.base.org/deposit",
    faucet: "https://faucet.quicknode.com/base/goerli",
    docs: "https://docs.base.org/",
    label: "Base Goerli",
    name: "Ethereum Native Token",
    symbol: "tETH",
    decimals: 18
  },
  [2222 /* KAVA */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Kava Mainnet",
    name: "Kava Native Token",
    symbol: "KAVA",
    decimals: 18
  },
  [2221 /* KAVA_TESTNET */]: {
    bridge: "",
    faucet: "https://faucet.kava.io/",
    docs: "https://docs.kava.io/docs/intro/",
    label: "Kava Testnet",
    name: "Kava Native Token",
    symbol: "tKAVA",
    decimals: 18
  },
  [1024 /* CLOVER */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Clover Mainnet",
    name: "Clover Native Token",
    symbol: "CLV",
    decimals: 18
  },
  [1023 /* CLOVER_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Clover Testnet",
    name: "Clover Native Token",
    symbol: "tCLV",
    decimals: 18
  },
  [1131 /* DEFICHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Defichain Mainnet",
    name: "Defichain Native Token",
    symbol: "DFI",
    decimals: 18
  },
  [1132 /* DEFICHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Defichain Testnet",
    name: "Defichain Native Token",
    symbol: "tDFI",
    decimals: 18
  },
  [1039 /* BRONOS */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Bro Mainnet",
    name: "Bro Native Token",
    symbol: "BRO",
    decimals: 18
  },
  [1038 /* BRONOS_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Bro Testnet",
    name: "Bro Native Token",
    symbol: "tBRO",
    decimals: 18
  },
  [314 /* FILECOIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Filecoin Mainnet",
    name: "Filecoin Native Token",
    symbol: "FIL",
    decimals: 18
  },
  [314159 /* FILECOIN_CALIBRATION */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Filecoin Testnet",
    name: "Filecoin Native Token",
    symbol: "tFIL",
    decimals: 18
  },
  [14 /* FLARE */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Flare Mainnet",
    name: "Flare Native Token",
    symbol: "FLR",
    decimals: 18
  },
  [114 /* FLARE_TESTNET */]: {
    bridge: "",
    faucet: "https://coston2-faucet.towolabs.com/",
    docs: "",
    label: "Flare Testnet",
    name: "Flare Native Token",
    symbol: "tFLR",
    decimals: 18
  },
  [841 /* TARAXA */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Tara Mainnet",
    name: "Tara Native Token",
    symbol: "TARA",
    decimals: 18
  },
  [842 /* TARAXA_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Tara Testnet",
    name: "Tara Native Token",
    symbol: "tTARA",
    decimals: 18
  },
  [42161 /* ARBITRUM_ONE */]: ETHER,
  [421613 /* ARBITRUM_GOERLI */]: {
    bridge: "https://bridge.arbitrum.io/",
    faucet: "https://faucet.quicknode.com/arbitrum/goerli",
    docs: "https://docs.arbitrum.io/",
    label: "Arbitrum Goerli",
    name: "Arbitrum Goerli Ether",
    symbol: "tETH",
    decimals: 18
  },
  [1101 /* POLYGON_ZKEVM */]: {
    bridge: "https://zksync.io/explore#bridges",
    faucet: "",
    docs: "",
    label: "Polygon ZkEvm",
    name: "ZkEvm Ether",
    symbol: "ETH",
    decimals: 18
  },
  [1442 /* POLYGON_ZKEVM_TESTNET */]: {
    bridge: "https://wallet.polygon.technology/",
    faucet: "https://faucet.quicknode.com/polygon/zkevm-goerli",
    docs: "https://wiki.polygon.technology/docs/zkevm/",
    label: "Polygon ZkEvm Testnet",
    name: "ZkEvm Ether",
    symbol: "tETH",
    decimals: 18
  },
  [324 /* ZKSYNC */]: {
    bridge: "https://zksync.io/explore#bridges",
    faucet: "",
    docs: "",
    label: "ZkSync",
    name: "ZkSync Ether",
    symbol: "ETH",
    decimals: 18
  },
  [280 /* ZKSYNC_TESTNET */]: {
    bridge: "https://zksync.io/explore#bridges",
    faucet: "https://faucet.triangleplatform.com/zksync/testnet",
    docs: "",
    label: "ZkSync Testnet",
    name: "ZkSync Ether",
    symbol: "tETH",
    decimals: 18
  },
  [59144 /* LINEA */]: {
    bridge: "https://bridge.linea.build/",
    faucet: "",
    docs: "",
    label: "Linea",
    name: "Linea Native Token",
    symbol: "ETH",
    decimals: 18
  },
  [59140 /* LINEA_TESTNET */]: {
    bridge: "https://bridge.linea.build/",
    faucet: "https://linea.faucetme.pro/",
    docs: "https://docs.linea.build/",
    label: "Linea Goerli",
    name: "Linea Native Token",
    symbol: "tETH",
    decimals: 18
  },
  [199 /* BTTCHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Btt Mainnet",
    name: "Btt Native Token",
    symbol: "BTT",
    decimals: 18
  },
  [1029 /* BTTCHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Btt Testnet",
    name: "Btt Native Token",
    symbol: "tBTT",
    decimals: 18
  },
  [43288 /* BOBA_AVAX */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Boba Avax Mainnet",
    name: "Boba Native Token",
    symbol: "BOBA",
    decimals: 18
  },
  [56288 /* BOBA_BNB */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Boba Bnb Mainnet",
    name: "Boba Native Token",
    symbol: "BOBA",
    decimals: 18
  },
  [256256 /* CMP */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Cmp Mainnet",
    name: "Cmp Native Token",
    symbol: "CMP",
    decimals: 18
  },
  [512512 /* CMP_TESTNET */]: {
    bridge: "",
    faucet: "https://dev.caduceus.foundation/testNetwork/",
    docs: "",
    label: "Cmp Testnet",
    name: "Cmp Native Token",
    symbol: "tCMP",
    decimals: 18
  },
  [1116 /* CORE */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Core Mainnet",
    name: "Core Native Token",
    symbol: "CORE",
    decimals: 18
  },
  [1115 /* CORE_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Core Testnet",
    name: "Core Native Token",
    symbol: "tCORE",
    decimals: 18
  },
  [1818 /* CUBECHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Cube Mainnet",
    name: "Cube Native Token",
    symbol: "CUBE",
    decimals: 18
  },
  [1819 /* CUBECHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Cube Testnet",
    name: "Cube Native Token",
    symbol: "tCUBE",
    decimals: 18
  },
  [44 /* DARWINIA_CRAB */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Darwinia Crab",
    name: "Crab Native Token",
    symbol: "CRAB",
    decimals: 18
  },
  [43 /* DARWINIA_PANGOLIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Darwinia Pangolin",
    name: "Pangolin Native Token",
    symbol: "tPRING",
    decimals: 18
  },
  [15 /* DIODE_PRETNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Diode Pretnet",
    name: "Diode Native Token",
    symbol: "DIODE",
    decimals: 18
  },
  [13 /* DIODE_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Diode Testnet",
    name: "Diode Native Token",
    symbol: "tDIODE",
    decimals: 18
  },
  [60 /* GOCHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Gochain Mainnet",
    name: "Go Native Token",
    symbol: "GO",
    decimals: 18
  },
  [31337 /* GOCHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Go Testnet",
    name: "Go Native Token",
    symbol: "tGO",
    decimals: 18
  },
  [11235 /* HAQQ */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Haqq Mainnet",
    name: "Haqq Native Token",
    symbol: "ISLM",
    decimals: 18
  },
  [54211 /* HAQQ_TESTEDGE2 */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Haqq Testnet",
    name: "Haqq Native Token",
    symbol: "tISLM",
    decimals: 18
  },
  [24 /* KARDIACHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Kardia Mainnet",
    name: "Kardia Native Token",
    symbol: "KAI",
    decimals: 18
  },
  [242 /* KARDIACHAIN_TESTNET */]: {
    bridge: "",
    faucet: "https://explorer-dev.kardiachain.io/faucet",
    docs: "https://docs.kardiachain.io/",
    label: "Kardia Testnet",
    name: "Kardia Native Token",
    symbol: "tKAI",
    decimals: 18
  },
  [11 /* METADIUM */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Metadium Mainnet",
    name: "Metadium Native Token",
    symbol: "META",
    decimals: 18
  },
  [12 /* METADIUM_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Metadium Testnet",
    name: "Metadium Native Token",
    symbol: "KAL",
    decimals: 18
  },
  [204 /* OP_BNB */]: {
    bridge: "https://opbnb-bridge.bnbchain.org/deposit",
    faucet: "",
    docs: "",
    label: "OpBnb",
    name: "Bnb Native Token",
    symbol: "BNB",
    decimals: 18
  },
  [5611 /* OP_BNB_TESTNET */]: {
    bridge: "https://opbnb-testnet-bridge.bnbchain.org/deposit",
    faucet: "https://testnet.bnbchain.org/faucet-smart",
    docs: "",
    label: "OpBnb Testnet",
    name: "Bnb Native Token",
    symbol: "tBNB",
    decimals: 18
  },
  [11297108109 /* PALM */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Palm Mainnet",
    name: "Palm Native Token",
    symbol: "PALM",
    decimals: 18
  },
  [11297108099 /* PALM_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Palm Testnet",
    name: "Palm Native Token",
    symbol: "tPALM",
    decimals: 18
  },
  [333999 /* POLIS */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Polis Mainnet",
    name: "Polis Native Token",
    symbol: "POLIS",
    decimals: 18
  },
  [333888 /* POLIS_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Polis Testnet",
    name: "Polis Native Token",
    symbol: "tPOLIS",
    decimals: 18
  },
  [27 /* SHIBACHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Shibachain Mainnet",
    name: "Shib Native Token",
    symbol: "SHIB",
    decimals: 18
  },
  [1e4 /* SMART_BCH */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Smart Bch Mainnet",
    name: "Smart Bch Native Token",
    symbol: "BCH",
    decimals: 18
  },
  [10001 /* SMART_BCH_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Smart Bch Testnet",
    name: "Smart Bch Native Token",
    symbol: "tBCH",
    decimals: 18
  },
  [19 /* SONGBIRD_CANARY */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Songbird Mainnet",
    name: "Songbird Native Token",
    symbol: "SGB",
    decimals: 18
  },
  [7 /* THAICHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Thaichain Mainnet",
    name: "Thaichain Native Token",
    symbol: "TCH",
    decimals: 18
  },
  [8 /* UBIQ */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Ubiq Mainnet",
    name: "Ubiq Native Token",
    symbol: "UBQ",
    decimals: 18
  },
  [9 /* UBIQ_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Ubiq Testnet",
    name: "Ubiq Native Token",
    symbol: "tUBQ",
    decimals: 18
  },
  [100009 /* VECHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Vechain Mainnet",
    name: "Vechain Native Token",
    symbol: "VET",
    decimals: 18
  },
  [100010 /* VECHAIN_TESTNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Vechain Testnet",
    name: "Vechain Native Token",
    symbol: "tVET",
    decimals: 18
  },
  [50 /* XINFIN */]: {
    bridge: "",
    faucet: "https://faucet.apothem.network/",
    docs: "",
    label: "Xinfin Mainnet",
    name: "Xinfin Native Token",
    symbol: "XDC",
    decimals: 18
  },
  [51 /* XINFIN_APOTHEM */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Xinfin Apothem",
    name: "Xinfin Native Token",
    symbol: "tXDC",
    decimals: 18
  },
  [534352 /* SCROLL */]: {
    bridge: "https://scroll.io/bridge",
    faucet: "",
    docs: "",
    label: "Scroll Mainnet",
    name: "Ether Token",
    symbol: "ETH",
    decimals: 18
  },
  [534351 /* SCROLL_SEPOLIA */]: {
    bridge: "https://scroll.io/bridge",
    faucet: "https://faucet.quicknode.com/scroll/sepolia",
    docs: "https://docs.scroll.io/en/home/",
    label: "Scroll Sepolia",
    name: "Ether Token",
    symbol: "tETH",
    decimals: 18
  },
  [1440002 /* XRPL_DEVNET */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "XRPL Devnet",
    name: "Xrp Token",
    symbol: "tXRP",
    decimals: 18
  },
  [7e3 /* ZETACHAIN */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "ZetaChain",
    name: "ZetaChain Token",
    symbol: "ZETA",
    decimals: 18
  },
  [7001 /* ZETACHAIN_ATHENS */]: {
    bridge: "",
    faucet: "",
    docs: "https://www.zetachain.com/docs",
    label: "ZetaChain Athens",
    name: "ZetaChain Token",
    symbol: "tZETA",
    decimals: 18
  },
  [55 /* ZYX */]: {
    bridge: "",
    faucet: "",
    docs: "",
    label: "Zyx Mainnet",
    name: "Zyx Native Token",
    symbol: "ZYX",
    decimals: 18
  }
};
var PAIR_ADDRESS_CACHE = {};
var composeKey = (token0, token1) => `${token0.chainId}-${token0.address}-${token1.address}`;
function getCreate2Address(from_, salt_, initCodeHash) {
  const from = toBytes(getAddress(from_));
  const salt = pad(isBytes(salt_) ? salt_ : toBytes(salt_), {
    size: 32
  });
  return getAddress(slice(keccak256(concat([toBytes("0xff"), from, salt, toBytes(initCodeHash)])), 12));
}
var EMPTY_INPU_HASH = "0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470";
var ZKSYNC_PREFIX = "0x2020dba91b30cc0006188af794c2fb30dd8520db7e2c088b7fc7c103c00ca494";
function getCreate2AddressZkSync(from, salt, initCodeHash) {
  return getAddress(
    keccak256(concat([ZKSYNC_PREFIX, pad(from, { size: 32 }), salt, initCodeHash, EMPTY_INPU_HASH])).slice(26)
  );
}
var computePairAddress = ({
  factoryAddress,
  tokenA,
  tokenB
}) => {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
  const key = composeKey(token0, token1);
  if (PAIR_ADDRESS_CACHE?.[key] === void 0) {
    const getCreate2Address_ = token0.chainId === 280 /* ZKSYNC_TESTNET */ || token1.chainId === 324 /* ZKSYNC */ ? getCreate2AddressZkSync : getCreate2Address;
    PAIR_ADDRESS_CACHE = {
      ...PAIR_ADDRESS_CACHE,
      [key]: getCreate2Address_(
        factoryAddress,
        keccak256(encodePacked(["address", "address"], [token0.address, token1.address])),
        V2_INIT_CODE_HASH_MAP[token0.chainId]
      )
    };
  }
  return PAIR_ADDRESS_CACHE[key];
};
var Pair = class {
  static getAddress(tokenA, tokenB) {
    return computePairAddress({
      factoryAddress: V2_FACTORY_ADDRESS_MAP[tokenA.chainId],
      tokenA,
      tokenB
    });
  }
  constructor(currencyAmountA, tokenAmountB) {
    const tokenAmounts = currencyAmountA.currency.sortsBefore(tokenAmountB.currency) ? [currencyAmountA, tokenAmountB] : [tokenAmountB, currencyAmountA];
    this.liquidityToken = new ERC20Token(
      tokenAmounts[0].currency.chainId,
      Pair.getAddress(tokenAmounts[0].currency, tokenAmounts[1].currency),
      18,
      "DSLP",
      "DONASWAP LP Token"
    );
    this.tokenAmounts = tokenAmounts;
  }
  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  involvesToken(token) {
    return token.equals(this.token0) || token.equals(this.token1);
  }
  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  get token0Price() {
    const result = this.tokenAmounts[1].divide(this.tokenAmounts[0]);
    return new Price(this.token0, this.token1, result.denominator, result.numerator);
  }
  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  get token1Price() {
    const result = this.tokenAmounts[0].divide(this.tokenAmounts[1]);
    return new Price(this.token1, this.token0, result.denominator, result.numerator);
  }
  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  priceOf(token) {
    invariant5(this.involvesToken(token), "TOKEN");
    return token.equals(this.token0) ? this.token0Price : this.token1Price;
  }
  /**
   * Returns the chain ID of the tokens in the pair.
   */
  get chainId() {
    return this.token0.chainId;
  }
  get token0() {
    return this.tokenAmounts[0].currency;
  }
  get token1() {
    return this.tokenAmounts[1].currency;
  }
  get reserve0() {
    return this.tokenAmounts[0];
  }
  get reserve1() {
    return this.tokenAmounts[1];
  }
  reserveOf(token) {
    invariant5(this.involvesToken(token), "TOKEN");
    return token.equals(this.token0) ? this.reserve0 : this.reserve1;
  }
  getOutputAmount(inputAmount) {
    invariant5(this.involvesToken(inputAmount.currency), "TOKEN");
    if (this.reserve0.quotient === ZERO || this.reserve1.quotient === ZERO) {
      throw new InsufficientReservesError();
    }
    const inputReserve = this.reserveOf(inputAmount.currency);
    const outputReserve = this.reserveOf(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    const inputAmountWithFee = inputAmount.quotient * _9975;
    const numerator = inputAmountWithFee * outputReserve.quotient;
    const denominator = inputReserve.quotient * _10000 + inputAmountWithFee;
    const outputAmount = CurrencyAmount.fromRawAmount(
      inputAmount.currency.equals(this.token0) ? this.token1 : this.token0,
      numerator / denominator
    );
    if (outputAmount.quotient === ZERO) {
      throw new InsufficientInputAmountError();
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
  }
  getInputAmount(outputAmount) {
    invariant5(this.involvesToken(outputAmount.currency), "TOKEN");
    if (this.reserve0.quotient === ZERO || this.reserve1.quotient === ZERO || outputAmount.quotient >= this.reserveOf(outputAmount.currency).quotient) {
      throw new InsufficientReservesError();
    }
    const outputReserve = this.reserveOf(outputAmount.currency);
    const inputReserve = this.reserveOf(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    const numerator = inputReserve.quotient * outputAmount.quotient * _10000;
    const denominator = (outputReserve.quotient - outputAmount.quotient) * _9975;
    const inputAmount = CurrencyAmount.fromRawAmount(
      outputAmount.currency.equals(this.token0) ? this.token1 : this.token0,
      numerator / denominator + ONE
    );
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
  }
  getLiquidityMinted(totalSupply, tokenAmountA, tokenAmountB) {
    invariant5(totalSupply.currency.equals(this.liquidityToken), "LIQUIDITY");
    const tokenAmounts = tokenAmountA.currency.sortsBefore(tokenAmountB.currency) ? [tokenAmountA, tokenAmountB] : [tokenAmountB, tokenAmountA];
    invariant5(tokenAmounts[0].currency.equals(this.token0) && tokenAmounts[1].currency.equals(this.token1), "TOKEN");
    let liquidity;
    if (totalSupply.quotient === ZERO) {
      liquidity = sqrt(tokenAmounts[0].quotient * tokenAmounts[1].quotient) - MINIMUM_LIQUIDITY;
    } else {
      const amount0 = tokenAmounts[0].quotient * totalSupply.quotient / this.reserve0.quotient;
      const amount1 = tokenAmounts[1].quotient * totalSupply.quotient / this.reserve1.quotient;
      liquidity = amount0 <= amount1 ? amount0 : amount1;
    }
    if (!(liquidity > ZERO)) {
      throw new InsufficientInputAmountError();
    }
    return CurrencyAmount.fromRawAmount(this.liquidityToken, liquidity);
  }
  getLiquidityValue(token, totalSupply, liquidity, feeOn = false, kLast) {
    invariant5(this.involvesToken(token), "TOKEN");
    invariant5(totalSupply.currency.equals(this.liquidityToken), "TOTAL_SUPPLY");
    invariant5(liquidity.currency.equals(this.liquidityToken), "LIQUIDITY");
    invariant5(liquidity.quotient <= totalSupply.quotient, "LIQUIDITY");
    let totalSupplyAdjusted;
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply;
    } else {
      invariant5(!!kLast, "K_LAST");
      const kLastParsed = BigInt(kLast);
      if (!(kLastParsed === ZERO)) {
        const rootK = sqrt(this.reserve0.quotient * this.reserve1.quotient);
        const rootKLast = sqrt(kLastParsed);
        if (rootK > rootKLast) {
          const numerator = totalSupply.quotient * (rootK - rootKLast);
          const denominator = rootK * FIVE + rootKLast;
          const feeLiquidity = numerator / denominator;
          totalSupplyAdjusted = totalSupply.add(CurrencyAmount.fromRawAmount(this.liquidityToken, feeLiquidity));
        } else {
          totalSupplyAdjusted = totalSupply;
        }
      } else {
        totalSupplyAdjusted = totalSupply;
      }
    }
    return CurrencyAmount.fromRawAmount(
      token,
      liquidity.quotient * this.reserveOf(token).quotient / totalSupplyAdjusted.quotient
    );
  }
};
var Route = class {
  constructor(pairs, input, output) {
    this._midPrice = null;
    invariant5(pairs.length > 0, "PAIRS");
    const { chainId } = pairs[0];
    invariant5(
      pairs.every((pair) => pair.chainId === chainId),
      "CHAIN_IDS"
    );
    const wrappedInput = input.wrapped;
    invariant5(pairs[0].involvesToken(wrappedInput), "INPUT");
    invariant5(typeof output === "undefined" || pairs[pairs.length - 1].involvesToken(output.wrapped), "OUTPUT");
    const path = [wrappedInput];
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i];
      invariant5(currentInput.equals(pair.token0) || currentInput.equals(pair.token1), "PATH");
      const output2 = currentInput.equals(pair.token0) ? pair.token1 : pair.token0;
      path.push(output2);
    }
    this.pairs = pairs;
    this.path = path;
    this.input = input;
    this.output = output;
  }
  get midPrice() {
    if (this._midPrice !== null)
      return this._midPrice;
    const prices = [];
    for (const [i, pair] of this.pairs.entries()) {
      prices.push(
        this.path[i].equals(pair.token0) ? new Price(pair.reserve0.currency, pair.reserve1.currency, pair.reserve0.quotient, pair.reserve1.quotient) : new Price(pair.reserve1.currency, pair.reserve0.currency, pair.reserve1.quotient, pair.reserve0.quotient)
      );
    }
    const reduced = prices.slice(1).reduce((accumulator, currentValue) => accumulator.multiply(currentValue), prices[0]);
    return this._midPrice = new Price(this.input, this.output, reduced.denominator, reduced.numerator);
  }
  get chainId() {
    return this.pairs[0].chainId;
  }
};
function inputOutputComparator(a, b) {
  invariant5(a.inputAmount.currency.equals(b.inputAmount.currency), "INPUT_CURRENCY");
  invariant5(a.outputAmount.currency.equals(b.outputAmount.currency), "OUTPUT_CURRENCY");
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0;
    }
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1;
    }
    return 1;
  }
  if (a.outputAmount.lessThan(b.outputAmount)) {
    return 1;
  }
  return -1;
}
function tradeComparator(a, b) {
  const ioComp = inputOutputComparator(a, b);
  if (ioComp !== 0) {
    return ioComp;
  }
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1;
  }
  if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1;
  }
  return a.route.path.length - b.route.path.length;
}
var Trade = class {
  /**
   * Constructs an exact in trade with the given amount in and route
   * @param route route of the exact in trade
   * @param amountIn the amount being passed in
   */
  static exactIn(route, amountIn) {
    return new Trade(route, amountIn, TradeType.EXACT_INPUT);
  }
  /**
   * Constructs an exact out trade with the given amount out and route
   * @param route route of the exact out trade
   * @param amountOut the amount returned by the trade
   */
  static exactOut(route, amountOut) {
    return new Trade(route, amountOut, TradeType.EXACT_OUTPUT);
  }
  constructor(route, amount, tradeType) {
    this.route = route;
    this.tradeType = tradeType;
    const tokenAmounts = new Array(route.path.length);
    if (tradeType === TradeType.EXACT_INPUT) {
      invariant5(amount.currency.equals(route.input), "INPUT");
      tokenAmounts[0] = amount.wrapped;
      for (let i = 0; i < route.path.length - 1; i++) {
        const pair = route.pairs[i];
        const [outputAmount] = pair.getOutputAmount(tokenAmounts[i]);
        tokenAmounts[i + 1] = outputAmount;
      }
      this.inputAmount = CurrencyAmount.fromFractionalAmount(route.input, amount.numerator, amount.denominator);
      this.outputAmount = CurrencyAmount.fromFractionalAmount(
        route.output,
        tokenAmounts[tokenAmounts.length - 1].numerator,
        tokenAmounts[tokenAmounts.length - 1].denominator
      );
    } else {
      invariant5(amount.currency.equals(route.output), "OUTPUT");
      tokenAmounts[tokenAmounts.length - 1] = amount.wrapped;
      for (let i = route.path.length - 1; i > 0; i--) {
        const pair = route.pairs[i - 1];
        const [inputAmount] = pair.getInputAmount(tokenAmounts[i]);
        tokenAmounts[i - 1] = inputAmount;
      }
      this.inputAmount = CurrencyAmount.fromFractionalAmount(
        route.input,
        tokenAmounts[0].numerator,
        tokenAmounts[0].denominator
      );
      this.outputAmount = CurrencyAmount.fromFractionalAmount(route.output, amount.numerator, amount.denominator);
    }
    this.executionPrice = new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.inputAmount.quotient,
      this.outputAmount.quotient
    );
    this.priceImpact = computePriceImpact(route.midPrice, this.inputAmount, this.outputAmount);
  }
  /**
   * Get the minimum amount that must be received from this trade for the given Slippage
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  minimumAmountOut(slippageTolerance) {
    invariant5(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount;
    }
    const slippageAdjustedAmountOut = new Fraction(ONE).add(slippageTolerance).invert().multiply(this.outputAmount.quotient).quotient;
    return CurrencyAmount.fromRawAmount(this.outputAmount.currency, slippageAdjustedAmountOut);
  }
  /**
   * Get the maximum amount in that can be spent via this trade for the given Slippage
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  maximumAmountIn(slippageTolerance) {
    invariant5(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount;
    }
    const slippageAdjustedAmountIn = new Fraction(ONE).add(slippageTolerance).multiply(this.inputAmount.quotient).quotient;
    return CurrencyAmount.fromRawAmount(this.inputAmount.currency, slippageAdjustedAmountIn);
  }
  /**
   * Given a list of pairs, and a fixed amount in, returns the top `maxNumResults` trades that go from an input token
   * amount to an output token, making at most `maxHops` hops.
   * Note this does not consider aggregation, as routes are linear. It's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pairs the pairs to consider in finding the best trade
   * @param nextAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pair
   * @param currentPairs used in recursion; the current list of pairs
   * @param currencyAmountIn used in recursion; the original value of the currencyAmountIn parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  static bestTradeExactIn(pairs, currencyAmountIn, currencyOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountIn = currencyAmountIn, bestTrades = []) {
    invariant5(pairs.length > 0, "PAIRS");
    invariant5(maxHops > 0, "MAX_HOPS");
    invariant5(currencyAmountIn === nextAmountIn || currentPairs.length > 0, "INVALID_RECURSION");
    const amountIn = nextAmountIn.wrapped;
    const tokenOut = currencyOut.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountIn.currency) && !pair.token1.equals(amountIn.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO) || pair.reserve1.equalTo(ZERO))
        continue;
      let amountOut;
      try {
        ;
        [amountOut] = pair.getOutputAmount(amountIn);
      } catch (error) {
        if (error.isInsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      if (amountOut.currency.equals(tokenOut)) {
        sortedInsert(
          bestTrades,
          new Trade(
            new Route([...currentPairs, pair], currencyAmountIn.currency, currencyOut),
            currencyAmountIn,
            TradeType.EXACT_INPUT
          ),
          maxNumResults,
          tradeComparator
        );
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        Trade.bestTradeExactIn(
          pairsExcludingThisPair,
          currencyAmountIn,
          currencyOut,
          {
            maxNumResults,
            maxHops: maxHops - 1
          },
          [...currentPairs, pair],
          amountOut,
          bestTrades
        );
      }
    }
    return bestTrades;
  }
  /**
   * Return the execution price after accounting for Slippage
   * @param slippageTolerance the allowed tolerated slippage
   */
  worstExecutionPrice(slippageTolerance) {
    return new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.maximumAmountIn(slippageTolerance).quotient,
      this.minimumAmountOut(slippageTolerance).quotient
    );
  }
  /**
   * similar to the above method but instead targets a fixed output amount
   * given a list of pairs, and a fixed amount out, returns the top `maxNumResults` trades that go from an input token
   * to an output token amount, making at most `maxHops` hops
   * note this does not consider aggregation, as routes are linear. it's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pairs the pairs to consider in finding the best trade
   * @param currencyIn the currency to spend
   * @param nextAmountOut the exact amount of currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pair
   * @param currentPairs used in recursion; the current list of pairs
   * @param currencyAmountOut used in recursion; the original value of the currencyAmountOut parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  static bestTradeExactOut(pairs, currencyIn, currencyAmountOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountOut = currencyAmountOut, bestTrades = []) {
    invariant5(pairs.length > 0, "PAIRS");
    invariant5(maxHops > 0, "MAX_HOPS");
    invariant5(currencyAmountOut === nextAmountOut || currentPairs.length > 0, "INVALID_RECURSION");
    const amountOut = nextAmountOut.wrapped;
    const tokenIn = currencyIn.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountOut.currency) && !pair.token1.equals(amountOut.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO) || pair.reserve1.equalTo(ZERO))
        continue;
      let amountIn;
      try {
        ;
        [amountIn] = pair.getInputAmount(amountOut);
      } catch (error) {
        if (error.isInsufficientReservesError) {
          continue;
        }
        throw error;
      }
      if (amountIn.currency.equals(tokenIn)) {
        sortedInsert(
          bestTrades,
          new Trade(
            new Route([pair, ...currentPairs], currencyIn, currencyAmountOut.currency),
            currencyAmountOut,
            TradeType.EXACT_OUTPUT
          ),
          maxNumResults,
          tradeComparator
        );
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        Trade.bestTradeExactOut(
          pairsExcludingThisPair,
          currencyIn,
          currencyAmountOut,
          {
            maxNumResults,
            maxHops: maxHops - 1
          },
          [pair, ...currentPairs],
          amountIn,
          bestTrades
        );
      }
    }
    return bestTrades;
  }
};
var _Native = class extends NativeCurrency {
  constructor({
    chainId,
    decimals,
    name,
    symbol
  }) {
    super(chainId, decimals, symbol, name);
  }
  get wrapped() {
    const wnative = WNATIVE[this.chainId];
    invariant5(!!wnative, "WRAPPED");
    return wnative;
  }
  static onChain(chainId) {
    if (chainId in this.cache) {
      return this.cache[chainId];
    }
    invariant5(!!NATIVE[chainId], "NATIVE_CURRENCY");
    const { decimals, name, symbol } = NATIVE[chainId];
    return this.cache[chainId] = new _Native({ chainId, decimals, symbol, name });
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
};
var Native = _Native;
Native.cache = {};
function toHex(currencyAmount) {
  return `0x${currencyAmount.quotient.toString(16)}`;
}
var ZERO_HEX = "0x0";
var Router = class {
  /**
   * Produces the on-chain method name to call and the hex encoded parameters to pass as arguments for a given trade.
   * @param trade to produce call parameters for
   * @param options options for the call parameters
   */
  static swapCallParameters(trade, options) {
    const etherIn = trade.inputAmount.currency.isNative;
    const etherOut = trade.outputAmount.currency.isNative;
    invariant5(!(etherIn && etherOut), "ETHER_IN_OUT");
    invariant5(!("ttl" in options) || options.ttl > 0, "TTL");
    const to = validateAndParseAddress(options.recipient);
    const amountIn = toHex(trade.maximumAmountIn(options.allowedSlippage));
    const amountOut = toHex(trade.minimumAmountOut(options.allowedSlippage));
    const path = trade.route.path.map((token) => token.address);
    const deadline = "ttl" in options ? `0x${(Math.floor(( new Date()).getTime() / 1e3) + options.ttl).toString(16)}` : `0x${options.deadline.toString(16)}`;
    const useFeeOnTransfer = Boolean(options.feeOnTransfer);
    let methodName;
    let args;
    let value;
    switch (trade.tradeType) {
      case TradeType.EXACT_INPUT:
        if (etherIn) {
          methodName = useFeeOnTransfer ? "swapExactETHForTokensSupportingFeeOnTransferTokens" : "swapExactETHForTokens";
          args = [amountOut, path, to, deadline];
          value = amountIn;
        } else if (etherOut) {
          methodName = useFeeOnTransfer ? "swapExactTokensForETHSupportingFeeOnTransferTokens" : "swapExactTokensForETH";
          args = [amountIn, amountOut, path, to, deadline];
          value = ZERO_HEX;
        } else {
          methodName = useFeeOnTransfer ? "swapExactTokensForTokensSupportingFeeOnTransferTokens" : "swapExactTokensForTokens";
          args = [amountIn, amountOut, path, to, deadline];
          value = ZERO_HEX;
        }
        break;
      case TradeType.EXACT_OUTPUT:
        invariant5(!useFeeOnTransfer, "EXACT_OUT_FOT");
        if (etherIn) {
          methodName = "swapETHForExactTokens";
          args = [amountOut, path, to, deadline];
          value = amountIn;
        } else if (etherOut) {
          methodName = "swapTokensForExactETH";
          args = [amountOut, amountIn, path, to, deadline];
          value = ZERO_HEX;
        } else {
          methodName = "swapTokensForExactTokens";
          args = [amountOut, amountIn, path, to, deadline];
          value = ZERO_HEX;
        }
        break;
    }
    return {
      methodName,
      args,
      value
    };
  }
};
var _Ether = class extends NativeCurrency {
  constructor(chainId) {
    super(chainId, 18, "ETH", "Ether");
  }
  get wrapped() {
    const weth9 = WETH9[this.chainId];
    invariant5(!!weth9, "WRAPPED");
    return weth9;
  }
  static onChain(chainId) {
    if (!this._etherCache[chainId]) {
      this._etherCache[chainId] = new _Ether(chainId);
    }
    return this._etherCache[chainId];
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
};
var Ether = _Ether;
Ether._etherCache = {};

// src/abis/ERC20.ts
var erc20ABI = [
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function"
  }
];

// src/abis/IDonaswapPair.ts
var donaswapPairV2ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "Burn",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    name: "Mint",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0In",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1In",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "Swap",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve0",
        type: "uint112"
      },
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve1",
        type: "uint112"
      }
    ],
    name: "Sync",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    constant: true,
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "MINIMUM_LIQUIDITY",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "spender",
        type: "address"
      }
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "burn",
    outputs: [
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "getReserves",
    outputs: [
      {
        internalType: "uint112",
        name: "reserve0",
        type: "uint112"
      },
      {
        internalType: "uint112",
        name: "reserve1",
        type: "uint112"
      },
      {
        internalType: "uint32",
        name: "blockTimestampLast",
        type: "uint32"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      },
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "initialize",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "kLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "mint",
    outputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "permit",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "price0CumulativeLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "price1CumulativeLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "skim",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "swap",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "sync",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "token0",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "token1",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  }
];

// src/fetcher.ts
var TOKEN_DECIMALS_CACHE = {
  [529 /* FIRECHAIN */]: {},
  [917 /* RINIA */]: {},
  [814 /* FIRECHAIN_ZKEVM */]: {},
  [3885 /* FIRECHAIN_ZKEVM_GHOSTRIDER */]: {},
  [1 /* ETHEREUM */]: {},
  [5 /* GOERLI */]: {},
  [11155111 /* SEPOLIA */]: {},
  [17e3 /* HOLESKY */]: {},
  [56 /* BSC */]: {},
  [97 /* BSC_TESTNET */]: {},
  [137 /* POLYGON */]: {},
  [80002 /* POLYGON_AMOY */]: {},
  [1101 /* POLYGON_ZKEVM */]: {},
  [1442 /* POLYGON_ZKEVM_TESTNET */]: {},
  [25 /* CRO */]: {},
  [338 /* CRO_TESTNET */]: {},
  [43114 /* AVALANCHE */]: {},
  [43113 /* AVALANCHE_FUJI */]: {},
  [250 /* FANTOM_OPERA */]: {},
  [4002 /* FANTOM_TESTNET */]: {},
  [42220 /* CELO */]: {},
  [44787 /* CELO_ALFAJORES */]: {},
  [10 /* OPTIMISM */]: {},
  [420 /* OPTIMISM_GOERLI */]: {},
  [42161 /* ARBITRUM_ONE */]: {},
  [421613 /* ARBITRUM_GOERLI */]: {},
  [109 /* SHIBARIUM */]: {},
  [719 /* SHIBARIUM_TESTNET */]: {},
  [369 /* PULSECHAIN */]: {},
  [943 /* PULSECHAIN_TESTNET */]: {},
  [202212e5 /* XEN */]: {},
  [202212 /* XEN_DEVNET */]: {},
  [16666e5 /* HARMONY */]: {},
  [16667e5 /* HARMONY_TESTNET */]: {},
  [53 /* COINEX_TESTNET */]: {},
  [52 /* COINEX */]: {},
  [2e3 /* DOGECHAIN */]: {},
  [568 /* DOGECHAIN_TESTNET */]: {},
  [131 /* ENGRAM_TESTNET */]: {},
  [61 /* ETHEREUM_CLASSIC */]: {},
  [63 /* ETHEREUM_CLASSIC_TESTNET */]: {},
  [32659 /* FUSION */]: {},
  [46688 /* FUSION_TESTNET */]: {},
  [128 /* HECO */]: {},
  [256 /* HECO_TESTNET */]: {},
  [321 /* KCC */]: {},
  [322 /* KCC_TESTNET */]: {},
  [8217 /* KLAYTN */]: {},
  [1001 /* KLAYTN_BAOBAB */]: {},
  [66 /* OKXCHAIN */]: {},
  [65 /* OKXCHAIN_TESTNET */]: {},
  [361 /* THETHA */]: {},
  [365 /* THETHA_TESTNET */]: {},
  [1231 /* ULTRON */]: {},
  [1230 /* ULTRON_TESTNET */]: {},
  [1284 /* MOONBEAM */]: {},
  [1285 /* MOONRIVER */]: {},
  [1287 /* MOONBASE_ALPHA */]: {},
  [1313161554 /* AURORA */]: {},
  [1313161555 /* AURORA_TESTNET */]: {},
  [288 /* BOBA */]: {},
  [2888 /* BOBA_GOERLI */]: {},
  [100 /* GNOSIS */]: {},
  [10200 /* GNOSIS_CHIADO */]: {},
  [1088 /* METIS */]: {},
  [599 /* METIS_GOERLI */]: {},
  [40 /* TELOS */]: {},
  [41 /* TELOS_TESTNET */]: {},
  [57 /* SYSCOIN */]: {},
  [5700 /* SYSCOIN_TANENBAUM */]: {},
  [4689 /* IOTEX */]: {},
  [4690 /* IOTEX_TESTNET */]: {},
  [336 /* SHIDEN */]: {},
  [592 /* ASTAR */]: {},
  [81 /* SHIBUNYA */]: {},
  [122 /* FUSE */]: {},
  [123 /* FUSE_SPARKNET */]: {},
  [106 /* VELAS */]: {},
  [111 /* VELAS_TESTNET */]: {},
  [55555 /* REI */]: {},
  [55556 /* REI_TESTNET */]: {},
  [420420 /* KEKCHAIN */]: {},
  [420666 /* KEKCHAIN_TESTNET */]: {},
  [88 /* TOMOCHAIN */]: {},
  [89 /* TOMOCHAIN_TESTNET */]: {},
  [108 /* THUNDERCORE */]: {},
  [18 /* THUNDERCORE_TESTNET */]: {},
  [888 /* WANCHAIN */]: {},
  [999 /* WANCHAIN_TESTNET */]: {},
  [30 /* RSK */]: {},
  [31 /* RSK_TESTNET */]: {},
  [20 /* ELASTOS */]: {},
  [21 /* ELASTOS_TESTNET */]: {},
  [1030 /* CONFLUX */]: {},
  [71 /* CONFLUX_TESTNET */]: {},
  [32520 /* BRISECHAIN */]: {},
  [64668 /* BRISECHAIN_TESTNET */]: {},
  [20402 /* MUUCHAIN */]: {},
  [42110 /* MUUCHAIN_TESTNET */]: {},
  [7700 /* CANTO */]: {},
  [7701 /* CANTO_TESTNET */]: {},
  [42262 /* OASIS_EMERALD */]: {},
  [42261 /* OASIS_TESTNET */]: {},
  [888888 /* VISION */]: {},
  [666666 /* VISION_TESTNET */]: {},
  [1234 /* STEP */]: {},
  [12345 /* STEP_TESTNET */]: {},
  [82 /* METER */]: {},
  [83 /* METER_TESTNET */]: {},
  [71402 /* GODWOKEN */]: {},
  [71401 /* GODWOKEN_TESTNET */]: {},
  [820 /* CALLISTO */]: {},
  [20729 /* CALLISTO_TESTNET */]: {},
  [9001 /* EVMOS */]: {},
  [9e3 /* EVMOS_TESTNET */]: {},
  [246 /* ENERGY_WEB_CHAIN */]: {},
  [73799 /* ENERGY_VOLTA */]: {},
  [8453 /* BASE */]: {},
  [84531 /* BASE_GOERLI */]: {},
  [2222 /* KAVA */]: {},
  [2221 /* KAVA_TESTNET */]: {},
  [1024 /* CLOVER */]: {},
  [1023 /* CLOVER_TESTNET */]: {},
  [1131 /* DEFICHAIN */]: {},
  [1132 /* DEFICHAIN_TESTNET */]: {},
  [1039 /* BRONOS */]: {},
  [1038 /* BRONOS_TESTNET */]: {},
  [314 /* FILECOIN */]: {},
  [314159 /* FILECOIN_CALIBRATION */]: {},
  [14 /* FLARE */]: {},
  [114 /* FLARE_TESTNET */]: {},
  [841 /* TARAXA */]: {},
  [842 /* TARAXA_TESTNET */]: {},
  [324 /* ZKSYNC */]: {},
  [280 /* ZKSYNC_TESTNET */]: {},
  [59144 /* LINEA */]: {},
  [59140 /* LINEA_TESTNET */]: {},
  [199 /* BTTCHAIN */]: {},
  [1029 /* BTTCHAIN_TESTNET */]: {},
  [43288 /* BOBA_AVAX */]: {},
  [56288 /* BOBA_BNB */]: {},
  [256256 /* CMP */]: {},
  [512512 /* CMP_TESTNET */]: {},
  [1116 /* CORE */]: {},
  [1115 /* CORE_TESTNET */]: {},
  [1818 /* CUBECHAIN */]: {},
  [1819 /* CUBECHAIN_TESTNET */]: {},
  [44 /* DARWINIA_CRAB */]: {},
  [43 /* DARWINIA_PANGOLIN */]: {},
  [15 /* DIODE_PRETNET */]: {},
  [13 /* DIODE_TESTNET */]: {},
  [60 /* GOCHAIN */]: {},
  [31337 /* GOCHAIN_TESTNET */]: {},
  [11235 /* HAQQ */]: {},
  [54211 /* HAQQ_TESTEDGE2 */]: {},
  [24 /* KARDIACHAIN */]: {},
  [242 /* KARDIACHAIN_TESTNET */]: {},
  [11 /* METADIUM */]: {},
  [12 /* METADIUM_TESTNET */]: {},
  [204 /* OP_BNB */]: {},
  [5611 /* OP_BNB_TESTNET */]: {},
  [11297108109 /* PALM */]: {},
  [11297108099 /* PALM_TESTNET */]: {},
  [333999 /* POLIS */]: {},
  [333888 /* POLIS_TESTNET */]: {},
  [27 /* SHIBACHAIN */]: {},
  [1e4 /* SMART_BCH */]: {},
  [10001 /* SMART_BCH_TESTNET */]: {},
  [19 /* SONGBIRD_CANARY */]: {},
  [7 /* THAICHAIN */]: {},
  [8 /* UBIQ */]: {},
  [9 /* UBIQ_TESTNET */]: {},
  [100009 /* VECHAIN */]: {},
  [100010 /* VECHAIN_TESTNET */]: {},
  [50 /* XINFIN */]: {},
  [51 /* XINFIN_APOTHEM */]: {},
  [534352 /* SCROLL */]: {},
  [534351 /* SCROLL_SEPOLIA */]: {},
  [1440002 /* XRPL_DEVNET */]: {},
  [7e3 /* ZETACHAIN */]: {},
  [7001 /* ZETACHAIN_ATHENS */]: {},
  [55 /* ZYX */]: {}
};
var ethClient = createPublicClient({ chain: mainnet, transport: http() });
var bscClient = createPublicClient({ chain: bsc, transport: http() });
var bscTestnetClient = createPublicClient({ chain: bscTestnet, transport: http() });
var goerliClient = createPublicClient({ chain: goerli, transport: http() });
var getDefaultClient = (chainId) => {
  switch (chainId) {
    case 1 /* ETHEREUM */:
      return ethClient;
    case 56 /* BSC */:
      return bscClient;
    case 97 /* BSC_TESTNET */:
      return bscTestnetClient;
    case 5 /* GOERLI */:
      return goerliClient;
    default:
      return bscClient;
  }
};
var Fetcher = class {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line no-useless-constructor,@typescript-eslint/no-empty-function
  constructor() {
  }
  /**
   * Fetch information for a given token on the given chain, using the given viem provider.
   * @param chainId chain of the token
   * @param address address of the token on the chain
   * @param provider provider used to fetch the token
   * @param symbol symbol of the token
   * @param name optional name of the token
   */
  static async fetchTokenData(chainId, address, publicClient = getDefaultClient(chainId), symbol, name) {
    const erc20 = getContract({
      abi: erc20ABI,
      address,
      publicClient
    });
    const parsedDecimals = typeof TOKEN_DECIMALS_CACHE?.[chainId]?.[address] === "number" ? TOKEN_DECIMALS_CACHE[chainId][address] : await erc20.read.decimals().then((decimals) => {
      TOKEN_DECIMALS_CACHE = {
        ...TOKEN_DECIMALS_CACHE,
        [chainId]: {
          ...TOKEN_DECIMALS_CACHE?.[chainId],
          [address]: decimals
        }
      };
      return decimals;
    });
    return new Token(chainId, address, parsedDecimals, symbol, name);
  }
  /**
   * Fetches information about a pair and constructs a pair from the given two tokens.
   * @param tokenA first token
   * @param tokenB second token
   * @param provider the provider to use to fetch the data
   */
  static async fetchPairData(tokenA, tokenB, publicClient = getDefaultClient(tokenA.chainId)) {
    invariant5(tokenA.chainId === tokenB.chainId, "CHAIN_ID");
    const address = Pair.getAddress(tokenA, tokenB);
    const pairContract = getContract({
      abi: donaswapPairV2ABI,
      address,
      publicClient
    });
    const [reserves0, reserves1] = await pairContract.read.getReserves();
    const balances = tokenA.sortsBefore(tokenB) ? [reserves0, reserves1] : [reserves1, reserves0];
    return new Pair(
      CurrencyAmount.fromRawAmount(tokenA, balances[0]),
      CurrencyAmount.fromRawAmount(tokenB, balances[1])
    );
  }
};

export { ChainId, ERC20Token, Ether, Fetcher, NATIVE, Native, ONE_HUNDRED_PERCENT, OnRampCurrency, Pair, Route, Router, Trade, V2_FACTORY_ADDRESS, V2_FACTORY_ADDRESS_MAP, V2_INIT_CODE_HASH, V2_INIT_CODE_HASH_MAP, WASTR, WAVAX, WBCH, WBNB, WBOBA, WBONE, WBRISE, WBRO, WBTT, WCANTO, WCELO, WCET, WCFX, WCLO, WCLV, WCMP, WCORE, WCRAB, WCRO, WCUBE, WDEV, WDFI, WDIODE, WDOGE, WELA, WETC, WETH, WETH9, WEVMOS, WEWT, WFIL, WFIRE, WFITFI, WFLR, WFSN, WFTM, WFUSE, WGLMR, WGO, WGRAM, WHT, WIOTX, WISLM, WKAI, WKAL, WKAVA, WKCS, WKEK, WKLAY, WMATIC, WMETA, WMETIS, WMOVR, WMTR, WMUU, WNATIVE, WOKT, WONE, WPALM, WPCKB, WPLS, WPOLIS, WPRING, WRBTC, WREI, WROSE, WSBY, WSDN, WSGB, WSHIB, WSYS, WTARA, WTCH, WTFUEL, WTLOS, WTOMO, WTT, WUBQ, WULX, WVET, WVLX, WVS, WWAN, WXDAI, WXDC, WXN, WXRP, WZETA, WZYX, ZERO_PERCENT, computePairAddress, donaswapPairV2ABI, inputOutputComparator, isTradeBetter, tradeComparator, validateAndParseAddress };
