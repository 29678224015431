import { ChainId } from '@donaswap/sdk'
import {
  INFO_CLIENT,
  INFO_CLIENT_ETH,
  INFO_CLIENT_FIRE,
  INFO_CLIENT_MATIC,
  INFO_CLIENT_ZKEVM_ETH,
  INFO_CLIENT_CRO,
  INFO_CLIENT_AVAX,
  INFO_CLIENT_FTM,
  INFO_CLIENT_CELO,
  INFO_CLIENT_OP_ETH,
  INFO_CLIENT_ARBI_ETH,
  INFO_CLIENT_BONE,
  INFO_CLIENT_PLS,
  INFO_CLIENT_XN,
  INFO_CLIENT_ONE,
  INFO_CLIENT_CET,
  INFO_CLIENT_DOGE,
  INFO_CLIENT_ETC,
  INFO_CLIENT_FSN,
  INFO_CLIENT_HECO,
  INFO_CLIENT_KCS,
  INFO_CLIENT_KLAY,
  INFO_CLIENT_OKT,
  INFO_CLIENT_TFUEL,
  INFO_CLIENT_ULX,
  INFO_CLIENT_GLMR,
  INFO_CLIENT_MOVR,
  INFO_CLIENT_AURORA_ETH,
  INFO_CLIENT_BOBA_ETH,
  INFO_CLIENT_XDAI,
  INFO_CLIENT_METIS,
  INFO_CLIENT_TLOS,
  INFO_CLIENT_SYS,
  INFO_CLIENT_IOTX,
  INFO_CLIENT_SDN,
  INFO_CLIENT_ASTR,
  INFO_CLIENT_SBY,
  INFO_CLIENT_FUSE,
  INFO_CLIENT_VLX,
  INFO_CLIENT_REI,
  INFO_CLIENT_KEK,
  INFO_CLIENT_TOMO,
  INFO_CLIENT_TT,
  INFO_CLIENT_WAN,
  INFO_CLIENT_RBTC,
  INFO_CLIENT_ELA,
  INFO_CLIENT_CFX,
  INFO_CLIENT_BRISE,
  INFO_CLIENT_MUU,
  INFO_CLIENT_CANTO,
  INFO_CLIENT_ROSE,
  INFO_CLIENT_VS,
  INFO_CLIENT_STEP,
  INFO_CLIENT_MTR,
  INFO_CLIENT_PCKB,
  INFO_CLIENT_CLO,
  INFO_CLIENT_EVMOS,
  INFO_CLIENT_EWT,
  INFO_CLIENT_BASE_ETH,
  INFO_CLIENT_KAVA,
  INFO_CLIENT_CLOVER,
  INFO_CLIENT_DEFICHAIN,
  INFO_CLIENT_BRONOS,
  INFO_CLIENT_FILECOIN,
  INFO_CLIENT_FLARE,
  INFO_CLIENT_TARAXA,
  INFO_CLIENT_ZKSYNC,
  INFO_CLIENT_LINEA,
  INFO_CLIENT_BTTCHAIN,
  INFO_CLIENT_BOBA_AVAX,
  INFO_CLIENT_BOBA_BNB,
  INFO_CLIENT_CMP,
  INFO_CLIENT_CORE,
  INFO_CLIENT_CUBECHAIN,
  INFO_CLIENT_DARWINIA_CRAB,
  INFO_CLIENT_DIODE_PRETNET,
  INFO_CLIENT_GOCHAIN,
  INFO_CLIENT_HAQQ,
  INFO_CLIENT_KARDIACHAIN,
  INFO_CLIENT_METADIUM,
  INFO_CLIENT_PALM,
  INFO_CLIENT_POLIS,
  INFO_CLIENT_SHIBACHAIN,
  INFO_CLIENT_SMART_BCH,
  INFO_CLIENT_SONGBIRD_CANARY,
  INFO_CLIENT_THAICHAIN,
  INFO_CLIENT_UBIQ,
  INFO_CLIENT_VECHAIN,
  INFO_CLIENT_XINFIN,
  INFO_CLIENT_SCROLL,
  INFO_CLIENT_XRPL_DEVNET,
  INFO_CLIENT_ZETACHAIN,
  INFO_CLIENT_ZYX,
  INFO_CLIENT_WITH_CHAIN
} from 'config/constants/infoClientEndpoints'
import {
  BIT_QUERY,
  STABLESWAP_SUBGRAPH_CLIENT,
  V3_BSC_INFO_CLIENT,
  V3_ETHEREUM_INFO_CLIENT,
  V3_POLYGON_INFO_CLIENT,
  V3_POLYGON_ZKEVM_INFO_CLIENT,
  V3_AVALANCHE_INFO_CLIENT,
  V3_OPTIMISM_INFO_CLIENT
} from 'config/constants/endpoints'
import { V3_SUBGRAPH_URLS } from 'config/constants/v3-subgraphs'
import { GraphQLClient } from 'graphql-request'

// Extra headers
// Mostly for dev environment
// No production env check since production preview might also need them
export const getGQLHeaders = (endpoint: string) => {
  if (endpoint === INFO_CLIENT && process.env.NEXT_PUBLIC_NODE_REAL_HEADER) {
    return {
      origin: process.env.NEXT_PUBLIC_NODE_REAL_HEADER,
    }
  }
  return undefined
}

export const infoClient = new GraphQLClient(INFO_CLIENT)

export const infoClientWithChain = (chainId: number) => {
  if (INFO_CLIENT_WITH_CHAIN[chainId]) {
    return new GraphQLClient(INFO_CLIENT_WITH_CHAIN[chainId], {
      headers: getGQLHeaders(INFO_CLIENT_WITH_CHAIN[chainId]),
    })
  }
  return undefined
}

export const v3Clients = {
  [ChainId.FIRECHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FIRECHAIN]),
  [ChainId.RINIA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.RINIA]),
  [ChainId.FIRECHAIN_ZKEVM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FIRECHAIN_ZKEVM]),
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]),
  [ChainId.ETHEREUM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ETHEREUM]),
  [ChainId.GOERLI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GOERLI]),
  [ChainId.SEPOLIA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SEPOLIA]),
  [ChainId.HOLESKY]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HOLESKY]),
  [ChainId.BSC]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BSC]),
  [ChainId.BSC_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BSC_TESTNET]),
  [ChainId.POLYGON]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.POLYGON]),
  [ChainId.POLYGON_AMOY]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.POLYGON_AMOY]),
  [ChainId.POLYGON_ZKEVM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.POLYGON_ZKEVM]),
  [ChainId.POLYGON_ZKEVM_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.POLYGON_ZKEVM_TESTNET]),
  [ChainId.CRO]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CRO]),
  [ChainId.CRO_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CRO_TESTNET]),
  [ChainId.AVALANCHE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.AVALANCHE]),
  [ChainId.AVALANCHE_FUJI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.AVALANCHE_FUJI]),
  [ChainId.FANTOM_OPERA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FANTOM_OPERA]),
  [ChainId.FANTOM_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FANTOM_TESTNET]),
  [ChainId.CELO]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CELO]),
  [ChainId.CELO_ALFAJORES]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CELO_ALFAJORES]),
  [ChainId.OPTIMISM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OPTIMISM]),
  [ChainId.OPTIMISM_GOERLI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OPTIMISM_GOERLI]),
  [ChainId.SHIBARIUM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SHIBARIUM]),
  [ChainId.SHIBARIUM_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SHIBARIUM_TESTNET]),
  [ChainId.PULSECHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.PULSECHAIN]),
  [ChainId.PULSECHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.PULSECHAIN_TESTNET]),
  [ChainId.XEN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.XEN]),
  [ChainId.XEN_DEVNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.XEN_DEVNET]),
  [ChainId.HARMONY]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HARMONY]),
  [ChainId.HARMONY_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HARMONY_TESTNET]),
  [ChainId.COINEX]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.COINEX]),
  [ChainId.COINEX_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.COINEX_TESTNET]),
  [ChainId.DOGECHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DOGECHAIN]),
  [ChainId.DOGECHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DOGECHAIN_TESTNET]),
  [ChainId.ENGRAM_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ENGRAM_TESTNET]),
  [ChainId.ETHEREUM_CLASSIC]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ETHEREUM_CLASSIC]),
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ETHEREUM_CLASSIC_TESTNET]),
  [ChainId.FUSION]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FUSION]),
  [ChainId.FUSION_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FUSION_TESTNET]),
  [ChainId.HECO]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HECO]),
  [ChainId.HECO_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HECO_TESTNET]),
  [ChainId.KCC]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KCC]),
  [ChainId.KCC_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KCC_TESTNET]),
  [ChainId.KLAYTN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KLAYTN]),
  [ChainId.KLAYTN_BAOBAB]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KLAYTN_BAOBAB]),
  [ChainId.OKXCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OKXCHAIN]),
  [ChainId.OKXCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OKXCHAIN_TESTNET]),
  [ChainId.THETHA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.THETHA]),
  [ChainId.THETHA_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.THETHA_TESTNET]),
  [ChainId.ULTRON]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ULTRON]),
  [ChainId.ULTRON_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ULTRON_TESTNET]),
  [ChainId.MOONBEAM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.MOONBEAM]),
  [ChainId.MOONRIVER]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.MOONRIVER]),
  [ChainId.MOONBASE_ALPHA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.MOONBASE_ALPHA]),
  [ChainId.AURORA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.AURORA]),
  [ChainId.AURORA_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.AURORA_TESTNET]),
  [ChainId.BOBA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BOBA]),
  [ChainId.BOBA_GOERLI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BOBA_GOERLI]),
  [ChainId.GNOSIS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GNOSIS]),
  [ChainId.GNOSIS_CHIADO]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GNOSIS_CHIADO]),
  [ChainId.METIS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.METIS]),
  [ChainId.METIS_GOERLI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.METIS_GOERLI]),
  [ChainId.TELOS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.TELOS]),
  [ChainId.TELOS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ELASTOS_TESTNET]),
  [ChainId.SYSCOIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SYSCOIN]),
  [ChainId.SYSCOIN_TANENBAUM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SYSCOIN_TANENBAUM]),
  [ChainId.IOTEX]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.IOTEX]),
  [ChainId.IOTEX_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.IOTEX_TESTNET]),
  [ChainId.SHIDEN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SHIDEN]),
  [ChainId.ASTAR]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ASTAR]),
  [ChainId.SHIBUNYA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SHIBUNYA]),
  [ChainId.FUSE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FUSE]),
  [ChainId.FUSE_SPARKNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FUSE_SPARKNET]),
  [ChainId.VELAS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.VELAS]),
  [ChainId.VELAS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.VELAS_TESTNET]),
  [ChainId.REI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.REI]),
  [ChainId.REI_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.REI_TESTNET]),
  [ChainId.KEKCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KEKCHAIN]),
  [ChainId.KEKCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KEKCHAIN_TESTNET]),
  [ChainId.TOMOCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.TOMOCHAIN]),
  [ChainId.TOMOCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.TOMOCHAIN_TESTNET]),
  [ChainId.THUNDERCORE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.THUNDERCORE]),
  [ChainId.THUNDERCORE_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.THUNDERCORE_TESTNET]),
  [ChainId.WANCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.WANCHAIN_TESTNET]),
  [ChainId.WANCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.WANCHAIN_TESTNET]),
  [ChainId.RSK]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.RSK]),
  [ChainId.RSK_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.RSK_TESTNET]),
  [ChainId.ELASTOS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ELASTOS]),
  [ChainId.ELASTOS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ELASTOS_TESTNET]),
  [ChainId.CONFLUX]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CONFLUX]),
  [ChainId.CONFLUX_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CONFLUX_TESTNET]),
  [ChainId.BRISECHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BRISECHAIN]),
  [ChainId.BRISECHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BRISECHAIN_TESTNET]),
  [ChainId.MUUCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.MUUCHAIN]),
  [ChainId.MUUCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.MUUCHAIN_TESTNET]),
  [ChainId.CANTO]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CANTO]),
  [ChainId.CANTO_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CANTO_TESTNET]),
  [ChainId.OASIS_EMERALD]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OASIS_EMERALD]),
  [ChainId.OASIS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OASIS_TESTNET]),
  [ChainId.VISION]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.VISION]),
  [ChainId.VISION_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.VISION_TESTNET]),
  [ChainId.STEP]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.STEP]),
  [ChainId.STEP_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.STEP_TESTNET]),
  [ChainId.METER]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.METER]),
  [ChainId.METER_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.METER_TESTNET]),
  [ChainId.GODWOKEN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GODWOKEN]),
  [ChainId.GODWOKEN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GODWOKEN_TESTNET]),
  [ChainId.CALLISTO]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CALLISTO]),
  [ChainId.CALLISTO_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CALLISTO_TESTNET]),
  [ChainId.EVMOS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.EVMOS]),
  [ChainId.EVMOS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.EVMOS_TESTNET]),
  [ChainId.ENERGY_WEB_CHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ENERGY_WEB_CHAIN]),
  [ChainId.ENERGY_VOLTA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ENERGY_VOLTA]),
  [ChainId.BASE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BASE]),
  [ChainId.BASE_GOERLI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BASE_GOERLI]),
  [ChainId.KAVA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KAVA]),
  [ChainId.KAVA_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KAVA_TESTNET]),
  [ChainId.CLOVER]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CLOVER]),
  [ChainId.CLOVER_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CLOVER_TESTNET]),
  [ChainId.DEFICHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DEFICHAIN]),
  [ChainId.DEFICHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DEFICHAIN_TESTNET]),
  [ChainId.BRONOS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BRONOS]),
  [ChainId.BRONOS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BRONOS_TESTNET]),
  [ChainId.FILECOIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FILECOIN]),
  [ChainId.FILECOIN_CALIBRATION]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FILECOIN_CALIBRATION]),
  [ChainId.FLARE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FLARE]),
  [ChainId.FLARE_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.FLARE_TESTNET]),
  [ChainId.TARAXA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.TARAXA]),
  [ChainId.TARAXA_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.TARAXA_TESTNET]),
  [ChainId.ZKSYNC]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ZKSYNC]),
  [ChainId.ZKSYNC_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ZKSYNC_TESTNET]),
  [ChainId.ARBITRUM_ONE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ARBITRUM_ONE]),
  [ChainId.ARBITRUM_GOERLI]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ARBITRUM_GOERLI]),
  [ChainId.LINEA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.LINEA]),
  [ChainId.LINEA_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.LINEA_TESTNET]),
  [ChainId.BTTCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BTTCHAIN]),
  [ChainId.BTTCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BTTCHAIN_TESTNET]),
  [ChainId.BOBA_AVAX]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BOBA_AVAX]),
  [ChainId.BOBA_BNB]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.BOBA_BNB]),
  [ChainId.CMP]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CMP]),
  [ChainId.CMP_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CMP_TESTNET]),
  [ChainId.CORE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CORE]),
  [ChainId.CORE_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CORE_TESTNET]),
  [ChainId.CUBECHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CUBECHAIN]),
  [ChainId.CUBECHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.CUBECHAIN_TESTNET]),
  [ChainId.DARWINIA_CRAB]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DARWINIA_CRAB]),
  [ChainId.DARWINIA_PANGOLIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DARWINIA_PANGOLIN]),
  [ChainId.DIODE_PRETNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DIODE_PRETNET]),
  [ChainId.DIODE_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.DIODE_TESTNET]),
  [ChainId.GOCHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GOCHAIN]),
  [ChainId.GOCHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.GOCHAIN_TESTNET]),
  [ChainId.HAQQ]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HAQQ]),
  [ChainId.HAQQ_TESTEDGE2]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.HAQQ_TESTEDGE2]),
  [ChainId.KARDIACHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KARDIACHAIN]),
  [ChainId.KARDIACHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.KARDIACHAIN_TESTNET]),
  [ChainId.METADIUM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.METADIUM]),
  [ChainId.METADIUM_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.METADIUM_TESTNET]),
  [ChainId.OP_BNB]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OP_BNB]),
  [ChainId.OP_BNB_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.OP_BNB_TESTNET]),
  [ChainId.PALM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.PALM]),
  [ChainId.PALM_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.PALM_TESTNET]),
  [ChainId.POLIS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.POLIS]),
  [ChainId.POLIS_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.POLIS_TESTNET]),
  [ChainId.SHIBACHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SHIBACHAIN]),
  [ChainId.SMART_BCH]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SMART_BCH]),
  [ChainId.SMART_BCH_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SMART_BCH_TESTNET]),
  [ChainId.SONGBIRD_CANARY]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SONGBIRD_CANARY]),
  [ChainId.THAICHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.THAICHAIN]),
  [ChainId.UBIQ]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.UBIQ]),
  [ChainId.UBIQ_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.UBIQ_TESTNET]),
  [ChainId.VECHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.VECHAIN]),
  [ChainId.VECHAIN_TESTNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.VECHAIN_TESTNET]),
  [ChainId.XINFIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.XINFIN]),
  [ChainId.XINFIN_APOTHEM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.XINFIN_APOTHEM]),
  [ChainId.SCROLL]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SCROLL]),
  [ChainId.SCROLL_SEPOLIA]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.SCROLL_SEPOLIA]),
  [ChainId.XRPL_DEVNET]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.XRPL_DEVNET]),
  [ChainId.ZETACHAIN]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ZETACHAIN]),
  [ChainId.ZETACHAIN_ATHENS]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ZETACHAIN_ATHENS]),
  [ChainId.ZYX]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ZYX]),
}

export const v3InfoClients = { 
  ...v3Clients, [ChainId.BSC]: new GraphQLClient(V3_BSC_INFO_CLIENT),
  ...v3Clients, [ChainId.ETHEREUM]: new GraphQLClient(V3_ETHEREUM_INFO_CLIENT),
  ...v3Clients, [ChainId.POLYGON]: new GraphQLClient(V3_POLYGON_INFO_CLIENT),
  ...v3Clients, [ChainId.POLYGON_ZKEVM]: new GraphQLClient(V3_POLYGON_ZKEVM_INFO_CLIENT),
  ...v3Clients, [ChainId.AVALANCHE]: new GraphQLClient(V3_AVALANCHE_INFO_CLIENT),
  ...v3Clients, [ChainId.OPTIMISM]: new GraphQLClient(V3_OPTIMISM_INFO_CLIENT)
}


export const infoClientFIRE = new GraphQLClient(INFO_CLIENT_FIRE)
export const infoClientETH = new GraphQLClient(INFO_CLIENT_ETH)
// export const infoClient = new GraphQLClient(INFO_CLIENT) // BSC CLIENT
export const infoClientMATIC = new GraphQLClient(INFO_CLIENT_MATIC)
export const infoClientZKEVM_ETH = new GraphQLClient(INFO_CLIENT_ZKEVM_ETH)
export const infoClientCRO = new GraphQLClient(INFO_CLIENT_CRO)
export const infoClientAVAX = new GraphQLClient(INFO_CLIENT_AVAX)
export const infoClientFTM = new GraphQLClient(INFO_CLIENT_FTM)
export const infoClientCELO = new GraphQLClient(INFO_CLIENT_CELO)
export const infoClientOP = new GraphQLClient(INFO_CLIENT_OP_ETH)
export const infoClientARBI = new GraphQLClient(INFO_CLIENT_ARBI_ETH)
export const infoClientBONE = new GraphQLClient(INFO_CLIENT_BONE)
export const infoClientPLS = new GraphQLClient(INFO_CLIENT_PLS)
export const infoClientXN = new GraphQLClient(INFO_CLIENT_XN)
export const infoClientONE = new GraphQLClient(INFO_CLIENT_ONE)
export const infoClientCET = new GraphQLClient(INFO_CLIENT_CET)
export const infoClientDOGE = new GraphQLClient(INFO_CLIENT_DOGE)
export const infoClientETC = new GraphQLClient(INFO_CLIENT_ETC)
export const infoClientFSN = new GraphQLClient(INFO_CLIENT_FSN)
export const infoClientHT = new GraphQLClient(INFO_CLIENT_HECO)
export const infoClientKCS = new GraphQLClient(INFO_CLIENT_KCS)
export const infoClientKLAY = new GraphQLClient(INFO_CLIENT_KLAY)
export const infoClientOKT = new GraphQLClient(INFO_CLIENT_OKT)
export const infoClientTFUEL = new GraphQLClient(INFO_CLIENT_TFUEL)
export const infoClientULX = new GraphQLClient(INFO_CLIENT_ULX)
export const infoClientGLMR = new GraphQLClient(INFO_CLIENT_GLMR)
export const infoClientMOVR = new GraphQLClient(INFO_CLIENT_MOVR)
export const infoClientAURORA = new GraphQLClient(INFO_CLIENT_AURORA_ETH)
export const infoClientBOBA = new GraphQLClient(INFO_CLIENT_BOBA_ETH)
export const infoClientXDAI = new GraphQLClient(INFO_CLIENT_XDAI)
export const infoClientMETIS = new GraphQLClient(INFO_CLIENT_METIS)
export const infoClientTLOS = new GraphQLClient(INFO_CLIENT_TLOS)
export const infoClientSYS = new GraphQLClient(INFO_CLIENT_SYS)
export const infoClientIOTX = new GraphQLClient(INFO_CLIENT_IOTX)
export const infoClientSDN = new GraphQLClient(INFO_CLIENT_SDN)
export const infoClientASTR = new GraphQLClient(INFO_CLIENT_ASTR)
export const infoClientSBY = new GraphQLClient(INFO_CLIENT_SBY)
export const infoClientFUSE = new GraphQLClient(INFO_CLIENT_FUSE)
export const infoClientVLX = new GraphQLClient(INFO_CLIENT_VLX)
export const infoClientREI = new GraphQLClient(INFO_CLIENT_REI)
export const infoClientKEK = new GraphQLClient(INFO_CLIENT_KEK)
export const infoClientTOMO = new GraphQLClient(INFO_CLIENT_TOMO)
export const infoClientTT = new GraphQLClient(INFO_CLIENT_TT)
export const infoClientWAN = new GraphQLClient(INFO_CLIENT_WAN)
export const infoClientRBTC = new GraphQLClient(INFO_CLIENT_RBTC)
export const infoClientELA = new GraphQLClient(INFO_CLIENT_ELA)
export const infoClientCFX = new GraphQLClient(INFO_CLIENT_CFX)
export const infoClientBRISE = new GraphQLClient(INFO_CLIENT_BRISE)
export const infoClientMUU = new GraphQLClient(INFO_CLIENT_MUU)
export const infoClientCANTO = new GraphQLClient(INFO_CLIENT_CANTO)
export const infoClientROSE = new GraphQLClient(INFO_CLIENT_ROSE)
export const infoClientVS = new GraphQLClient(INFO_CLIENT_VS)
export const infoClientSTEP = new GraphQLClient(INFO_CLIENT_STEP)
export const infoClientMTR = new GraphQLClient(INFO_CLIENT_MTR)
export const infoClientPCKB = new GraphQLClient(INFO_CLIENT_PCKB)
export const infoClientCLO = new GraphQLClient(INFO_CLIENT_CLO)
export const infoClientEVMOS = new GraphQLClient(INFO_CLIENT_EVMOS)
export const infoClientEWT = new GraphQLClient(INFO_CLIENT_EWT)
export const infoClientBASE = new GraphQLClient(INFO_CLIENT_BASE_ETH)
export const infoClientKAVA = new GraphQLClient(INFO_CLIENT_KAVA)
export const infoClientCLOVER = new  GraphQLClient(INFO_CLIENT_CLOVER)
export const infoClientDEFICHAIN = new GraphQLClient(INFO_CLIENT_DEFICHAIN)
export const infoClientBRONOS = new GraphQLClient(INFO_CLIENT_BRONOS)
export const infoClientFILECOIN = new GraphQLClient(INFO_CLIENT_FILECOIN)
export const infoClientFLARE = new GraphQLClient(INFO_CLIENT_FLARE)
export const infoClientTARAXA = new GraphQLClient(INFO_CLIENT_TARAXA)
export const infoClientZKSYNC = new GraphQLClient(INFO_CLIENT_ZKSYNC)
export const infoClientLINEA = new GraphQLClient(INFO_CLIENT_LINEA)
export const infoClientBTTCHAIN = new GraphQLClient(INFO_CLIENT_BTTCHAIN)
export const infoClientBOBA_AVAX = new GraphQLClient(INFO_CLIENT_BOBA_AVAX)
export const infoClientBOBA_BNB = new GraphQLClient(INFO_CLIENT_BOBA_BNB)
export const infoClientCMP = new GraphQLClient(INFO_CLIENT_CMP)
export const infoClientCORE = new GraphQLClient(INFO_CLIENT_CORE)
export const infoClientCUBECHAIN = new GraphQLClient(INFO_CLIENT_CUBECHAIN)
export const infoClientDARWINIA_CRAB = new GraphQLClient(INFO_CLIENT_DARWINIA_CRAB)
export const infoClientDIODE_PRETNET = new GraphQLClient(INFO_CLIENT_DIODE_PRETNET)
export const infoClientGOCHAIN = new GraphQLClient(INFO_CLIENT_GOCHAIN)
export const infoClientHAQQ = new GraphQLClient(INFO_CLIENT_HAQQ)
export const infoClientKARDIACHAIN = new GraphQLClient(INFO_CLIENT_KARDIACHAIN)
export const infoClientMETADIUM = new GraphQLClient(INFO_CLIENT_METADIUM)
export const infoClientPALM = new GraphQLClient(INFO_CLIENT_PALM)
export const infoClientPOLIS = new GraphQLClient(INFO_CLIENT_POLIS)
export const infoClientSHIBACHAIN = new GraphQLClient(INFO_CLIENT_SHIBACHAIN)
export const infoClientSMART_BCH = new GraphQLClient(INFO_CLIENT_SMART_BCH)
export const infoClientSONGBIRD = new GraphQLClient(INFO_CLIENT_SONGBIRD_CANARY)
export const infoClientTHAICHAIN = new GraphQLClient(INFO_CLIENT_THAICHAIN)
export const infoClientUBIQ = new GraphQLClient(INFO_CLIENT_UBIQ)
export const infoClientVECHAIN = new GraphQLClient(INFO_CLIENT_VECHAIN)
export const infoClientXINFIN = new GraphQLClient(INFO_CLIENT_XINFIN)
export const infoClientSCROLL = new GraphQLClient(INFO_CLIENT_SCROLL)
export const infoClientXRPL_DEVNET = new GraphQLClient(INFO_CLIENT_XRPL_DEVNET)
export const infoClientZETACHAIN = new GraphQLClient(INFO_CLIENT_ZETACHAIN)
export const infoClientZYX = new GraphQLClient(INFO_CLIENT_ZYX)

export const infoStableSwapClient = new GraphQLClient(STABLESWAP_SUBGRAPH_CLIENT)

export const infoServerClient = new GraphQLClient(INFO_CLIENT, {
  timeout: 5000,
  headers: {
    origin: 'https://donaswap.io',
  },
})

export const stableSwapClient = new GraphQLClient(STABLESWAP_SUBGRAPH_CLIENT)

export const bitQueryServerClient = new GraphQLClient(BIT_QUERY, {
  headers: {
    // only server, no `NEXT_PUBLIC` not going to expose in client
    'X-API-KEY': process.env.BIT_QUERY_HEADER,
  },
  timeout: 5000,
})
