export enum ChainId {
  FIRECHAIN = 529,
  RINIA = 917,
  FIRECHAIN_ZKEVM = 814,
  FIRECHAIN_ZKEVM_GHOSTRIDER = 3885,
  ARBITRUM_ONE = 42161,
  ARBITRUM_GOERLI = 421613,
  ASTAR = 592,
  AURORA = 1313161554,
  AURORA_TESTNET = 1313161555,
  AVALANCHE = 43114,
  AVALANCHE_FUJI = 43113,
  BASE = 8453,
  BASE_GOERLI = 84531,
  BTTCHAIN = 199,
  BTTCHAIN_TESTNET = 1029,
  BOBA = 288,
  BOBA_AVAX = 43288,
  BOBA_BNB = 56288,
  BOBA_GOERLI = 2888,
  BRISECHAIN = 32520,
  BRISECHAIN_TESTNET = 64668,
  BRONOS = 1039,
  BRONOS_TESTNET = 1038,
  BSC = 56,
  BSC_TESTNET = 97,
  CALLISTO = 820,
  CALLISTO_TESTNET = 20729,
  CANTO = 7700,
  CANTO_TESTNET = 7701,
  CELO = 42220,
  CELO_ALFAJORES = 44787,
  CLOVER = 1024,
  CLOVER_TESTNET = 1023,
  CMP = 256256,
  CMP_TESTNET = 512512,
  COINEX = 52,
  COINEX_TESTNET = 53,
  CONFLUX = 1030,
  CONFLUX_TESTNET = 71,
  CORE = 1116,
  CORE_TESTNET = 1115,
  CRO = 25,
  CRO_TESTNET = 338,
  CUBECHAIN = 1818,
  CUBECHAIN_TESTNET = 1819,
  DARWINIA_CRAB = 44,
  DARWINIA_PANGOLIN = 43,
  DEFICHAIN = 1131,
  DEFICHAIN_TESTNET = 1132,
  DIODE_PRETNET = 15,
  DIODE_TESTNET = 13,
  DOGECHAIN = 2000,
  DOGECHAIN_TESTNET = 568,
  ENGRAM_TESTNET = 131,
  ELASTOS = 20,
  ELASTOS_TESTNET = 21,
  ETHEREUM = 1,
  ETHEREUM_CLASSIC = 61,
  ETHEREUM_CLASSIC_TESTNET = 63,
  EVMOS = 9001,
  EVMOS_TESTNET = 9000,
  ENERGY_WEB_CHAIN = 246,
  ENERGY_VOLTA = 73799,
  FANTOM_OPERA = 250,
  FANTOM_TESTNET = 4002,
  FILECOIN = 314,
  FILECOIN_CALIBRATION = 314159,
  FLARE = 14,
  FLARE_TESTNET = 114,
  FUSE = 122,
  FUSE_SPARKNET = 123,
  FUSION = 32659,
  FUSION_TESTNET = 46688,
  GNOSIS = 100,
  GNOSIS_CHIADO = 10200,
  GOCHAIN = 60,
  GOCHAIN_TESTNET = 31337,
  GODWOKEN = 71402,
  GODWOKEN_TESTNET = 71401,
  GOERLI = 5,
  HAQQ = 11235,
  HAQQ_TESTEDGE2 = 54211,
  HARMONY = 1666600000,
  HARMONY_TESTNET = 1666700000,
  HECO = 128,
  HECO_TESTNET = 256,
  HOLESKY = 17000,
  IOTEX = 4689,
  IOTEX_TESTNET = 4690,
  KARDIACHAIN = 24,
  KARDIACHAIN_TESTNET = 242,
  KAVA = 2222,
  KAVA_TESTNET = 2221,
  KCC = 321,
  KCC_TESTNET = 322,
  KEKCHAIN = 420420,
  KEKCHAIN_TESTNET = 420666,
  KLAYTN = 8217,
  KLAYTN_BAOBAB = 1001,
  LINEA = 59144,
  LINEA_TESTNET = 59140,
  METADIUM = 11,
  METADIUM_TESTNET = 12,
  METER = 82,
  METER_TESTNET = 83,
  METIS = 1088,
  METIS_GOERLI = 599,
  MOONBEAM = 1284,
  MOONRIVER = 1285,
  MOONBASE_ALPHA = 1287,
  MUUCHAIN = 20402,
  MUUCHAIN_TESTNET = 42110,
  OASIS_EMERALD = 42262,
  OASIS_TESTNET = 42261,
  OKXCHAIN = 66,
  OKXCHAIN_TESTNET = 65,
  OP_BNB = 204,
  OP_BNB_TESTNET = 5611,
  OPTIMISM = 10,
  OPTIMISM_GOERLI = 420,
  PALM = 11297108109,
  PALM_TESTNET = 11297108099,
  POLIS = 333999,
  POLIS_TESTNET = 333888,
  POLYGON = 137,
  POLYGON_AMOY = 80002,
  POLYGON_ZKEVM = 1101,
  POLYGON_ZKEVM_TESTNET = 1442,
  PULSECHAIN = 369,
  PULSECHAIN_TESTNET = 943,
  REI = 55555,
  REI_TESTNET = 55556,
  RSK = 30,
  RSK_TESTNET = 31,
  SCROLL = 534352,
  SCROLL_SEPOLIA = 534351,
  SEPOLIA = 11155111,
  SHIBACHAIN = 27,
  // SHIBACHAIN_TESTSNET = ,
  SHIBARIUM = 109,
  SHIBARIUM_TESTNET = 719,
  SHIBUNYA = 81,
  SHIDEN = 336,
  SMART_BCH = 10000,
  SMART_BCH_TESTNET = 10001,
  SONGBIRD_CANARY = 19,
  // SONGBIRD_TESTNET = 19,
  STEP = 1234,
  STEP_TESTNET = 12345,
  SYSCOIN = 57,
  SYSCOIN_TANENBAUM = 5700,
  TARAXA = 841,
  TARAXA_TESTNET = 842,
  TELOS = 40,
  TELOS_TESTNET = 41,
  THAICHAIN = 7,
  // THAICHAIN_2 = 7,
  // THAICHAIN_TESTNET = ,
  THETHA = 361,
  THETHA_TESTNET = 365,
  THUNDERCORE = 108,
  THUNDERCORE_TESTNET = 18,
  TOMOCHAIN = 88,
  TOMOCHAIN_TESTNET = 89,
  UBIQ = 8,
  UBIQ_TESTNET = 9,
  ULTRON = 1231,
  ULTRON_TESTNET = 1230,
  VELAS = 106,
  VELAS_TESTNET = 111,
  VISION = 888888,
  VISION_TESTNET = 666666,
  VECHAIN = 100009,
  VECHAIN_TESTNET = 100010,
  WANCHAIN = 888,
  WANCHAIN_TESTNET = 999,
  XINFIN = 50,
  XINFIN_APOTHEM = 51,
  XEN = 20221200000,
  XEN_DEVNET = 202212,
  XRPL_DEVNET = 1440002,
  ZETACHAIN = 7000,
  ZETACHAIN_ATHENS = 7001,
  ZKSYNC = 324,
  ZKSYNC_TESTNET = 280,
  ZYX = 55,
  // ZYX_TESTNET = 55
}
